import "./App.scss";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  apexRoutes,
  celeriRoutes,
  chmRoutes,
  demoRoutes,
  grateRoutes,
  hmdaRoutes,
  landingPageRoutes,
  mainRoutes,
  miscRoutes,
  pmgRoutes,
  reliantRoutes,
  revolutionRoutes,
  rocketRoutes,
  celinkRoutes,
  agilityRoutes,
  fcmRoutes,
  radiusRoutes,
  prmgUATModeRoutes,
  flexpointRoutes,
  PRMGProdRoutes,
  kindLendingRoutes,
  advantageRoutes,
} from "./Routes";

const router = createBrowserRouter([
  mainRoutes,
  miscRoutes,
  landingPageRoutes,
  hmdaRoutes,
  rocketRoutes,
  celeriRoutes,
  pmgRoutes,
  chmRoutes,
  demoRoutes,
  apexRoutes,
  reliantRoutes,
  grateRoutes,
  revolutionRoutes,
  celinkRoutes,
  agilityRoutes,
  fcmRoutes,
  radiusRoutes,
  prmgUATModeRoutes,
  flexpointRoutes,
  PRMGProdRoutes,
  kindLendingRoutes,
  advantageRoutes,
]);

export default function App() {
  return <RouterProvider router={router} />;
}
