import Select, { SingleValue } from "react-select";
import { ReactSelectOption } from "./Interfaces";
import React from "react";

interface PeriodSelectorProps {
  handleClickHighlight: (id: string) => void;
  setMonth: (arg0: string) => void;
  setYear: (arg0: string) => void;
  onClickFunctionality: () => void;
  disableCondition?: () => boolean;
  clickedButtonID: string;
  upToCurrentMonth: boolean;
  startingYear: number;
  buttonText: string;
  hideYear: boolean;
  blockYear: boolean;
  setAsDefault: boolean;
}

const makeIndex = () => {
  return "PS";
};

export const PeriodSelectors = (props: PeriodSelectorProps) => {
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = props.blockYear
    ? props.startingYear
    : new Date().getFullYear();
  const monthsFullList: ReactSelectOption[] = [
    { value: "1", label: "January" },
    { value: "2", label: "February" },
    { value: "3", label: "March" },
    { value: "4", label: "April" },
    { value: "5", label: "May" },
    { value: "6", label: "June" },
    { value: "7", label: "July" },
    { value: "8", label: "August" },
    { value: "9", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];
  const [selectedYear, setSelectedYear] = React.useState<ReactSelectOption>({
    label: new Date().getFullYear().toString(),
    value: new Date().getFullYear().toString(),
  }); //only used for internal checks
  const [selectedMonth, setSelectedMonth] = React.useState<ReactSelectOption>({
    label: (new Date().getMonth() + 1).toString(),
    value: (new Date().getMonth() + 1).toString(),
  }); //only used for internal checks

  React.useEffect(() => {
    if (props.setAsDefault) {
      props.handleClickHighlight(makeIndex());
    }
  }, []);

  React.useEffect(() => {
    setSelectedMonth(monthsFullList[currentMonth - 1]);
    props.setMonth(monthsFullList[currentMonth - 1].value);
  }, [selectedYear]);

  const generateMonths = (upToCurrentMonth: boolean) => {
    var returnMonths: ReactSelectOption[] = [];
    if (
      upToCurrentMonth &&
      new Date().getFullYear().toString() === selectedYear.value
    ) {
      for (var i = 0; i < currentMonth; i++) {
        returnMonths.push(monthsFullList[i]);
      }
    } else {
      returnMonths = monthsFullList;
    }

    return returnMonths;
  };

  const generateYears = (startingYear: number) => {
    const Years: { value: string; label: string }[] = [];
    if (props.blockYear) {
      Years.push({
        value: props.startingYear.toString(),
        label: props.startingYear.toString(),
      });
    } else {
      if (startingYear > currentYear) {
        for (let i = 0; i < new Date().getFullYear() + 1 - 2019; i++) {
          Years.push({
            value: (2019 + i).toString(),
            label: (2019 + i).toString(),
          });
        }
      } else {
        for (let i = 0; i < new Date().getFullYear() + 1 - startingYear; i++) {
          Years.push({
            value: (startingYear + i).toString(),
            label: (startingYear + i).toString(),
          });
        }
      }
    }

    return Years;
  };

  /**
   * Returns a month selector with the current month selected as default.
   * @param setMonth Function that sets the state of the parent's month state.
   * @param upToCurrentMonth If true, the list will be populated with all months, up-to and including the current. If false, all months will be displayed.
   * @returns Selector object.
   */

  const selectMonth = (
    setMonth: (arg0: string) => void,
    upToCurrentMonth: boolean,
  ) => {
    return (
      <Select
        placeholder="Select Month"
        onChange={(selectedMonth: SingleValue<ReactSelectOption>) => {
          setMonth(selectedMonth!.value);
          setSelectedMonth(selectedMonth!);
        }}
        options={generateMonths(upToCurrentMonth)}
        defaultValue={generateMonths(upToCurrentMonth)[currentMonth - 1]}
        className="basic-multi-select width-min-130"
        classNamePrefix="select"
        value={selectedMonth}
      />
    );
  };

  /**
   * Returns a year selector with the current year selected as default.
   * @param setYear Function that sets the state of the parent's year state.
   * @param startingYear The list of years will start with this value. If greater than the current year, the default value of 2019 will be used.
   * @returns Selector object.
   */

  const selectYear = (
    setYear: (arg0: string) => void,
    startingYear: number,
  ) => {
    return (
      <Select
        placeholder="Select Year"
        onChange={(selectedYear: SingleValue<ReactSelectOption>) => {
          setYear(selectedYear!.value);
          setSelectedYear(selectedYear!);
        }}
        options={generateYears(startingYear)}
        defaultValue={{
          value: currentYear.toString(),
          label: currentYear.toString(),
        }}
        className="basic-multi-select width-min-130"
        classNamePrefix="select"
      />
    );
  };

  return (
    <div className="option-in-card">
      <div style={{ paddingRight: "5px" }}>Filter by Period:</div>
      {!props.hideYear && selectYear(props.setYear, props.startingYear)}
      {selectMonth(props.setMonth, props.upToCurrentMonth)}
      <div style={{ paddingLeft: "5px" }}>
        <button
          className={
            makeIndex() === props.clickedButtonID ? "btn clicked-button" : "btn"
          }
          key={makeIndex()}
          disabled={
            typeof props.disableCondition === "function"
              ? props.disableCondition()
              : false
          }
          onClick={() => {
            props.onClickFunctionality();
            props.handleClickHighlight(makeIndex());
          }}
        >
          {props.buttonText}
        </button>
      </div>
    </div>
  );
};
