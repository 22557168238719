import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  GroupIDandName,
  PermissionArray,
  Principal,
} from "./types/thoughtSpot";
import { environmentConfig } from "../environment";

const tsV2ApiBaseURL = {
  baseUrl: `${environmentConfig.thoughtspotHost}/api/rest/2.0`,
};

export const thoughtspotV2Api = createApi({
  reducerPath: "thoughtspotV2Api",
  baseQuery: fetchBaseQuery(tsV2ApiBaseURL),
  endpoints: (builder) => ({
    getUserGroups: builder.mutation<
      GroupIDandName[],
      { userId: string; thoughtSpotAccessToken: string }
    >({
      query: (arg) => {
        return {
          url: `/users/search`,
          method: "post",
          body: new URLSearchParams({
            user_identifier: `${arg.userId}`,
          }),
          headers: {
            Authorization: `Bearer ${arg.thoughtSpotAccessToken}`,
            "content-type": "application/x-www-form-urlencoded",
            Accept: "application/json",
          },
        };
      },
      transformResponse(response: any): GroupIDandName[] {
        return response.length !== 0 ? response[0].user_groups : [];
      },
    }),
    getLiveboardsAccessableByGroup: builder.mutation<
      PermissionArray[] | null,
      { IdArray: Principal[]; thoughtSpotAccessToken: string }
    >({
      query: (arg) => {
        return {
          url: `/security/principals/fetch-permissions`,
          method: "post",
          body: {
            principals: arg.IdArray,
          },
          headers: {
            Authorization: `Bearer ${arg.thoughtSpotAccessToken}`,
            "content-type": "application/json",
            Accept: "application/json",
          },
        };
      },
      transformResponse(response: any): PermissionArray[] | null {
        return response.principal_permission_details;
      },
    }),
    getWorksheetIDsInLiveboard: builder.mutation<
      string[],
      { liveboardId: string; thoughtSpotAccessToken: string }
    >({
      query: (arg) => {
        return {
          url: `/metadata/search`,
          method: "post",
          body: {
            include_details: true,
            include_headers: false,
            include_dependent_objects: true,
            incluide_hidden_objects: true,
            metadata: [
              {
                type: "LIVEBOARD",
                identifier: arg.liveboardId,
              },
            ],
          },
          headers: {
            Authorization: `Bearer ${arg.thoughtSpotAccessToken}`,
            "content-type": "application/json",
            Accept: "application/json",
          },
        };
      },
      transformResponse(response: any): string[] {
        var results: string[] = [];
        response[0].metadata_detail.reportContent.sheets.forEach(
          (element: any) => {
            if (element.sheetContent.hasOwnProperty("pinboardFilterDetails")) {
              results = results.concat(
                element.sheetContent.pinboardFilterDetails.filterLogicalTableIds.filter(
                  (item: any) => results.indexOf(item) < 0,
                ),
              );
            }
          },
        );

        return results;
      },
    }),
    isLiveboardInOrg: builder.mutation<
      boolean,
      { liveboardId: string; thoughtSpotAccessToken: string }
    >({
      query: (arg) => {
        return {
          url: `/metadata/search`,
          method: "post",
          body: {
            include_details: false,
            include_headers: true,
            include_dependent_objects: false,
            include_hidden_objects: false,
            metadata: [
              {
                type: "LIVEBOARD",
                identifier: arg.liveboardId,
              },
            ],
          },
          headers: {
            Authorization: `Bearer ${arg.thoughtSpotAccessToken}`,
            "content-type": "application/json",
            Accept: "application/json",
          },
        };
      },
      transformResponse(response: any): boolean {
        return response.length !== 0;
      },
    }),
    shareTSObject: builder.mutation<
      string[],
      {
        objectID: string | string[];
        typeOfObject: "LIVEBOARD" | "LOGICAL_TABLE";
        groupId: string;
        typeOfAccess: "READ_ONLY" | "MODIFY" | "NO_ACCESS";
        thoughtSpotAccessToken: string;
      }
    >({
      query: (arg) => {
        var identifiers =
          typeof arg.objectID === "string" ? [arg.objectID] : arg.objectID;
        return {
          url: `/security/metadata/share`,
          method: "post",
          body: {
            metadata_type: arg.typeOfObject,
            metadata_identifiers: identifiers,
            permissions: [
              {
                principal: {
                  identifier: arg.groupId,
                  type: "USER_GROUP",
                },
                share_mode: arg.typeOfAccess,
              },
            ],
            emails: [],
            message: "",
          },
          headers: {
            Authorization: `Bearer ${arg.thoughtSpotAccessToken}`,
            "content-type": "application/json",
            Accept: "application/json",
          },
        };
      },
    }),
  }),
});

export const {
  useGetUserGroupsMutation,
  useGetLiveboardsAccessableByGroupMutation,
  useGetWorksheetIDsInLiveboardMutation,
  useShareTSObjectMutation,
  useIsLiveboardInOrgMutation,
} = thoughtspotV2Api;
