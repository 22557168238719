import React, { useEffect, useState } from "react";
import { useLazyGA_User_CheckinQuery } from "../../../services/gallus";
import { useAppSelector } from "../../../app/hooks";
import { selectUserAuthContext } from "../../../features/console/consoleSlice";
import { getUserSessionItem } from "../../..";

interface GallusAgilityRedirectProps {}

interface GallusAgilityRedirectState {}

export const GallusAgilityRedirect = (props: GallusAgilityRedirectProps) => {
  const userAuthContext = useAppSelector(selectUserAuthContext);
  const [state, setState] = useState<GallusAgilityRedirectState>();
  const [LazyGA_User_CheckinQuery, LazyGA_User_CheckinQueryResult] =
    useLazyGA_User_CheckinQuery();

  useEffect(() => {
    if (userAuthContext) {
      LazyGA_User_CheckinQuery({ userPayload: getUserSessionItem("user") });
    }
  }, []);

  useEffect(() => {
    if (
      LazyGA_User_CheckinQueryResult.isSuccess &&
      LazyGA_User_CheckinQueryResult.status == "fulfilled"
    ) {
      window.location.href = "https://www.gallusinsights.co/pcredirect";
    }
  }, [LazyGA_User_CheckinQueryResult]);

  return (
    <div>
      {LazyGA_User_CheckinQueryResult.isLoading && "Loading, please wait."}
      {LazyGA_User_CheckinQueryResult.isError && "GA_User_Checkin Error"}
    </div>
  );
};
