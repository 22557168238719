import { DataEncoding, tsDataObject } from "../../../services/types/gallus";

const dataArrayFormatted = [
  ["Size", "Title", "", "", "", "", "", "", "", "", "", "", "", ""],
  [
    "Originations",
    "text-underlined",
    { value: 17260889000, overrideEncoding: "$skb1d" },
    { value: 22191440000, overrideEncoding: "$skb1d" },
    -0.222182562285278,
    { value: 3319289000, overrideEncoding: "$skb1d" },
    { value: 4347306000, overrideEncoding: "$skb1d" },
    -0.236472196804181,
    { value: 24372436000, overrideEncoding: "$skb1d" },
    { value: 29721633000, overrideEncoding: "$skb1d" },
    -0.179976551086544,
    { value: 5370708000, overrideEncoding: "$skb1d" },
    { value: 6083143000, overrideEncoding: "$skb1d" },
    -0.117116267034985,
  ],
  [
    "Servicing",
    "CSSCLASSGOESHERE",
    { value: 509105421000, overrideEncoding: "$-skb1d" },
    { value: 506083328000, overrideEncoding: "$-skb1d" },
    0.00597153241926196,
    { value: 85033899000, overrideEncoding: "$-skb1d" },
    { value: 83705731000, overrideEncoding: "$-skb1d" },
    0.0158671095053218,
    { value: 299456189000, overrideEncoding: "$-skb1d" },
    { value: 281373662000, overrideEncoding: "$-skb1d" },
    0.0642651727651751,
    { value: 145090199000, overrideEncoding: "$-skb1d" },
    { value: 143959705000, overrideEncoding: "$-skb1d" },
    0.00785285021249527,
  ],
  [
    null,
    "Spacing",
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ],
  ["Balance Sheet", "Title", "", "", "", "", "", "", "", "", "", "", "", ""],
  [
    "Tangible Assets",
    "CSSCLASSGOESHERE",
    17994975000,
    19406521000,
    -0.0727356541649068,
    3490539000,
    3310450000,
    0.054400157078343,
    11871854000,
    12204137000,
    -0.0272270788176174,
    6151048000,
    6078529000,
    0.0119303535444182,
  ],
  [
    "Tangible Equity",
    "text-underlined",
    { value: 7064945000, overrideEncoding: "$-sk0d" },
    { value: 7264155000, overrideEncoding: "$-sk0d" },
    -0.0274236989711811,
    { value: 997312000, overrideEncoding: "$-sk0d" },
    { value: 1090794000, overrideEncoding: "$-sk0d" },
    -0.0857008747756223,
    { value: 2474671000, overrideEncoding: "$-sk0d" },
    { value: 3092111000, overrideEncoding: "$-sk0d" },
    -0.199682352929762,
    { value: 707484000, overrideEncoding: "$-sk0d" },
    { value: 768935000, overrideEncoding: "$-sk0d" },
    -0.0799170280973033,
  ],
  [
    "TCE / TA",
    "CSSCLASSGOESHERE",
    { value: 0.392606547105511, overrideEncoding: "$%1d" },
    { value: 0.374315159321962, overrideEncoding: "$%1d" },
    { value: 0.0182913877835495, overrideEncoding: "$%1d" },
    { value: 0.285718623971828, overrideEncoding: "$%1d" },
    { value: 0.329500219003459, overrideEncoding: "$%1d" },
    { value: -0.0437815950316309, overrideEncoding: "$%1d" },
    { value: 0.208448570880336, overrideEncoding: "$%1d" },
    { value: 0.253365805382224, overrideEncoding: "$%1d" },
    { value: -0.0449172345018881, overrideEncoding: "$%1d" },
    { value: 0.11501844888871, overrideEncoding: "$%1d" },
    { value: 0.126500177921336, overrideEncoding: "$%1d" },
    { value: -0.0114817290326262, overrideEncoding: "$%1d" },
  ],
  [
    null,
    "Spacing",
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ],
  ["Income Statement", "Title", "", "", "", "", "", "", "", "", "", "", "", ""],
  [
    "Revenue",
    "CSSCLASSGOESHERE",
    693806000,
    1203168000,
    -0.423350687518285,
    57234000,
    257257000,
    -0.777522088806135,
    -114583000,
    677116000,
    -1.169222112607,
    228626000,
    265661000,
    -0.13940698860578,
  ],
  [
    "Expense",
    "CSSCLASSGOESHERE",
    { value: 936711000, overrideEncoding: "$-sk0d" },
    { value: 1085543000, overrideEncoding: "$-sk0d" },
    -0.137103735181379,
    { value: 176533000, overrideEncoding: "$-sk0d" },
    { value: 183730000, overrideEncoding: "$-sk0d" },
    -0.0391716105154304,
    { value: 353825000, overrideEncoding: "$-sk0d" },
    { value: 375389000, overrideEncoding: "$-sk0d" },
    -0.0574444109976586,
    { value: 302571000, overrideEncoding: "$-sk0d" },
    { value: 305128000, overrideEncoding: "$-sk0d" },
    -0.00838008966728709,
  ],
  [
    "Net Income",
    "SubTitleOrange",
    -232694000,
    114945000,
    -3.02439427552308,
    -93121000,
    54243000,
    -2.71673764356691,
    -460956000,
    300993000,
    -2.53145089752918,
    -59771000,
    -34262000,
    0.744527464829841,
  ],
  [
    null,
    "Spacing",
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ],
  ["Profitability", "Title", "", "", "", "", "", "", "", "", "", "", "", ""],
  [
    "GoS Margins",
    "CSSCLASSGOESHERE",
    { value: 0.0268, overrideEncoding: "$%2d" },
    { value: 0.0276, overrideEncoding: "$%2d" },
    { value: -0.000799999999999999, overrideEncoding: "$%2d" },
    { value: 0.033, overrideEncoding: "$%2d" },
    { value: 0.0377, overrideEncoding: "$%2d" },
    { value: -0.0047, overrideEncoding: "$%2d" },
    { value: 0.0092, overrideEncoding: "$%2d" },
    { value: 0.0097, overrideEncoding: "$%2d" },
    { value: -0.0005, overrideEncoding: "$%2d" },
    { value: 0.0243, overrideEncoding: "$%2d" },
    { value: 0.0274, overrideEncoding: "$%2d" },
    { value: -0.0031, overrideEncoding: "$%2d" },
  ],
  [
    "Efficiency Ratio",
    "CSSCLASSGOESHERE",
    { value: 1.35010507259955, overrideEncoding: "$%1d" },
    { value: 0.902237260299476, overrideEncoding: "$%1d" },
    { value: 0.447867812300069, overrideEncoding: "$%1d" },
    { value: 3.08440786944823, overrideEncoding: "$%1d" },
    { value: 0.714188535200208, overrideEncoding: "$%1d" },
    { value: 2.37021933424802, overrideEncoding: "$%1d" },
    { value: -3.08793625581456, overrideEncoding: "$%1d" },
    { value: 0.554393929548261, overrideEncoding: "$%1d" },
    { value: -3.64233018536282, overrideEncoding: "$%1d" },
    { value: 1.32343215557286, overrideEncoding: "$%1d" },
    { value: 1.14856151260441, overrideEncoding: "$%1d" },
    { value: 0.174870642968448, overrideEncoding: "$%1d" },
  ],
  [
    "Net Margin",
    "CSSCLASSGOESHERE",
    { value: -0.335387702037745, overrideEncoding: "$%1d" },
    { value: 0.0955352868427352, overrideEncoding: "$%1d" },
    { value: -0.430922988880481, overrideEncoding: "$%1d" },
    { value: -1.62702239927316, overrideEncoding: "$%1d" },
    { value: 0.210851405403935, overrideEncoding: "$%1d" },
    { value: -1.83787380467709, overrideEncoding: "$%1d" },
    { value: 4.0229004302558, overrideEncoding: "$%1d" },
    { value: 0.444522061212554, overrideEncoding: "$%1d" },
    { value: 3.57837836904324, overrideEncoding: "$%1d" },
    { value: -0.2614357072249, overrideEncoding: "$%1d" },
    { value: -0.128968873865566, overrideEncoding: "$%1d" },
    { value: -0.132466833359335, overrideEncoding: "$%1d" },
  ],
  [
    "ROTCE",
    "SubTitleOrange",
    { value: -0.131745682379693, overrideEncoding: "$%1d" },
    { value: 0.0632943542641918, overrideEncoding: "$%1d" },
    { value: -0.195040036643885, overrideEncoding: "$%1d" },
    { value: -0.373487935570814, overrideEncoding: "$%1d" },
    { value: 0.19891198521444, overrideEncoding: "$%1d" },
    { value: -0.572399920785254, overrideEncoding: "$%1d" },
    { value: -0.74507843668916, overrideEncoding: "$%1d" },
    { value: 0.389368945681445, overrideEncoding: "$%1d" },
    { value: -1.13444738237061, overrideEncoding: "$%1d" },
    { value: -0.337935557553245, overrideEncoding: "$%1d" },
    { value: -0.178230929792505, overrideEncoding: "$%1d" },
    { value: -0.15970462776074, overrideEncoding: "$%1d" },
  ],
];

const columnFormattingFormatted: DataEncoding[] = [
  "string",
  "string",
  "$sk0d",
  "$sk0d",
  "$%1d",
  "$sk0d",
  "$sk0d",
  "$%1d",
  "$sk0d",
  "$sk0d",
  "$%1d",
  "$sk0d",
  "$sk0d",
  "$%1d",
];

export const GrateBenchmarkQ423: tsDataObject = {
  data: dataArrayFormatted,
  columnNames: [
    "RowName",
    "CSSClass",
    "Q4 2023 Rocket",
    "Q3 2023 Rocket",
    "QoQ Rocket",
    "Q4 2023 Guild",
    "Q3 2023 Guild",
    "QoQ Guild",
    "Q4 2023 UWM",
    "Q3 2023 UWM",
    "QoQ UWM",
    "Q4 2023 Loan Depot",
    "Q3 2023 Loan Depot",
    "QoQ Loan Depot",
  ],
  columnDisplayName: [
    "RowName",
    "CSSClass",
    "Q4 2023",
    "Q3 2023",
    "QoQ",
    "Q4 2023",
    "Q3 2023",
    "QoQ",
    "Q4 2023",
    "Q3 2023",
    "QoQ",
    "Q4 2023",
    "Q3 2023",
    "QoQ",
  ],
  columnShow: [
    true,
    false,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
  ],
  columnGroupName: [
    {
      nameOfGroup: "Rocket",
      headerClass: "grate-group",
      columnsToGroup: ["Q4 2023 Rocket", "Q3 2023 Rocket", "QoQ Rocket"],
    },
    {
      nameOfGroup: "Guild",
      headerClass: "grate-group",
      columnsToGroup: ["Q4 2023 Guild", "Q3 2023 Guild", "QoQ Guild"],
    },
    {
      nameOfGroup: "UWM",
      headerClass: "grate-group",
      columnsToGroup: ["Q4 2023 UWM", "Q3 2023 UWM", "QoQ UWM"],
    },
    {
      nameOfGroup: "Loan Depot",
      headerClass: "grate-group",
      columnsToGroup: [
        "Q4 2023 Loan Depot",
        "Q3 2023 Loan Depot",
        "QoQ Loan Depot",
      ],
    },
  ],
  columnFormatting: columnFormattingFormatted,
  columnHeaderClasses: [
    "grid-bold",
    "",
    "grate-column",
    "grate-column",
    "grate-column",
    "grate-column",
    "grate-column",
    "grate-column",
    "grate-column",
    "grate-column",
    "grate-column",
    "grate-column",
    "grate-column",
    "grate-column",
  ],
  columnDataClasses: [
    "text-left",
    "",
    "text-right",
    "text-right",
    "text-right",
    "text-right",
    "text-right",
    "text-right",
    "text-right",
    "text-right",
    "text-right",
    "text-right",
    "text-right",
    "text-right",
  ],
  tableTitle: "",
};
