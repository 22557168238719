import { RefObject, createRef, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { exportPlotlyToPdf } from "../../../features/SaveAsPdf";
import { ResultFromMultipleInputSliders } from "../../../components/generic-financial-model/MultipleInputSliders";
import Plot from "react-plotly.js";
import "../Module.scss";
import {
  useXlsxProcessorQuery,
  useLazyXlsxProcessorQuery,
} from "../../../services/serverless";
import { Error, tryAgainLaterMessage } from "../../../components/Error";

const totalGenerator = (total: number) => {
  return total > 0
    ? "<b>$" +
        (Math.round(total * 10) / 10)
          .toFixed(1)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
        "M</b>"
    : "<b>-$" +
        (Math.round(total * -10) / 10)
          .toFixed(1)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
        "M</b>";
};

const unitsGenerator = (total: number) => {
  return total > 0
    ? "<b>" +
        Math.round(total)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
        "</b>"
    : "<b>-" +
        Math.round(total)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
        "</b>";
};

const bpsGenerator = (total: number) => {
  return (
    "<b>" +
    Math.round(total)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
    "bps</b>"
  );
};

const percentageFormatter = (total: number) => {
  return total > 0
    ? "<b>" +
        (Math.round(total * 10) / 10)
          .toFixed(1)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
        "%</b>"
    : "<b>-" +
        (Math.round(total * -10) / 10)
          .toFixed(1)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
        "%</b>";
};

interface FinancialModelingPandaProps {}

interface FinancialModelingPandaState {
  inputValues: number[];
  initialInputValues: number[];
  firstCallFlag: boolean;
  secondCallFlag: boolean;
  initialDataLoadedFlag: boolean;
  chartsLoadedFlag: boolean;
  fundedVolumeChartOptions: any[];
  numberUnitsChartOptions: any[];
  revenueChartOptions: any[];
  totalExpensesChartOptions: any[];
  earningsBeforeTaxesChartOptions: any[];
  ebtDollarChartOptions: any[];
  ebtBPSChartOptions: any[];
  equityChartOptions: any[];
  isChartLoading: boolean;
  pdfValues: number[];
  errorFlag: boolean;
  errorCode: string;
  errorMessage: string;
  errorType: string;
}

const PlotlyDefaultMargin = {
  margin: {
    l: 10,
    r: 10,
    b: 30,
  },
};

const DataSourceURL: string =
  "https://docs.google.com/spreadsheets/d/1iCPPpRUnw4u26mPGn2Rlp_hgnbRON706/";

export const FinancialModelingPanda = (props: FinancialModelingPandaProps) => {
  const [state, setState] = useState<FinancialModelingPandaState>({
    inputValues: [],
    initialInputValues: [],
    firstCallFlag: false,
    secondCallFlag: false,
    initialDataLoadedFlag: false,
    chartsLoadedFlag: false,
    fundedVolumeChartOptions: [],
    numberUnitsChartOptions: [],
    revenueChartOptions: [],
    totalExpensesChartOptions: [],
    earningsBeforeTaxesChartOptions: [],
    ebtDollarChartOptions: [],
    ebtBPSChartOptions: [],
    equityChartOptions: [],
    isChartLoading: false,
    pdfValues: [],
    errorFlag: false,
    errorCode: "",
    errorMessage: "",
    errorType: "",
  });

  const [chartDataQuery, chartDataResult] = useLazyXlsxProcessorQuery();

  const InitialChartData = useXlsxProcessorQuery({
    DataSource: DataSourceURL,
    InputSheetName: null,
    Inputs: [],
    OutputSheetName: "Inputs",
    Outputs: [
      {
        RowIndex: 4,
        CellIndex: 2,
        Value: null,
      },
      {
        RowIndex: 4,
        CellIndex: 3,
        Value: null,
      },
      {
        RowIndex: 7,
        CellIndex: 2,
        Value: null,
      },
      {
        RowIndex: 7,
        CellIndex: 3,
        Value: null,
      },
      {
        RowIndex: 8,
        CellIndex: 2,
        Value: null,
      },
      {
        RowIndex: 8,
        CellIndex: 3,
        Value: null,
      },
      {
        RowIndex: 9,
        CellIndex: 2,
        Value: null,
      },
      {
        RowIndex: 9,
        CellIndex: 3,
        Value: null,
      },
    ],
  });

  useEffect(() => {
    if (InitialChartData.isSuccess && InitialChartData.status === "fulfilled") {
      setState((prevState) => ({
        ...prevState,
        initialInputValues: [
          Math.round(InitialChartData.data.Outputs[0].Value),
          Math.round(InitialChartData.data.Outputs[1].Value),
          Math.round(InitialChartData.data.Outputs[2].Value),
          Math.round(InitialChartData.data.Outputs[3].Value),
          Math.round(InitialChartData.data.Outputs[4].Value),
          Math.round(InitialChartData.data.Outputs[5].Value),
          Math.round(InitialChartData.data.Outputs[6].Value),
          Math.round(InitialChartData.data.Outputs[7].Value),
        ],
        inputValues: [
          Math.round(InitialChartData.data.Outputs[0].Value),
          Math.round(InitialChartData.data.Outputs[1].Value),
          Math.round(InitialChartData.data.Outputs[2].Value),
          Math.round(InitialChartData.data.Outputs[3].Value),
          Math.round(InitialChartData.data.Outputs[4].Value),
          Math.round(InitialChartData.data.Outputs[5].Value),
          Math.round(InitialChartData.data.Outputs[6].Value),
          Math.round(InitialChartData.data.Outputs[7].Value),
        ],
        firstCallFlag: true,
        initialDataLoadedFlag: true,
        errorFlag: false,
      }));
    } else if (InitialChartData.isError) {
      setState((prevState) => ({
        ...prevState,
        errorFlag: true,
        errorCode: "500",
        errorMessage: tryAgainLaterMessage,
        errorType: "InternalAPI",
      }));
    }
  }, [InitialChartData]);

  async function sendDataBuildCharts() {
    setState((prevState) => ({
      ...prevState,
      secondCallFlag: true,
      isChartLoading: true,
    }));
    setState((prevState) => ({
      ...prevState,
      pdfValues: [
        state.inputValues[0],
        state.inputValues[1],
        state.inputValues[2],
        state.inputValues[3],
        state.inputValues[4],
        state.inputValues[5],
        state.inputValues[6],
        state.inputValues[7],
      ],
    }));
    chartDataQuery({
      DataSource: DataSourceURL,
      InputSheetName: "Inputs",
      Inputs: [
        {
          RowIndex: 4,
          CellIndex: 2,
          Value: state.inputValues[0] + 0.000001,
        },
        {
          RowIndex: 4,
          CellIndex: 3,
          Value: state.inputValues[1] + 0.000001,
        },
        {
          RowIndex: 7,
          CellIndex: 2,
          Value: state.inputValues[2] + 0.000001,
        },
        {
          RowIndex: 7,
          CellIndex: 3,
          Value: state.inputValues[3] + 0.000001,
        },
        {
          RowIndex: 8,
          CellIndex: 2,
          Value: state.inputValues[4] + 0.000001,
        },
        {
          RowIndex: 8,
          CellIndex: 3,
          Value: state.inputValues[5] + 0.000001,
        },
        {
          RowIndex: 9,
          CellIndex: 2,
          Value: state.inputValues[6] + 0.000001,
        },
        {
          RowIndex: 9,
          CellIndex: 3,
          Value: state.inputValues[7] + 0.000001,
        },
      ],
      OutputSheetName: "Web_Model",
      Outputs: [
        // funded Volume
        {
          RowIndex: 7,
          CellIndex: 2,
          Value: null,
        },
        {
          RowIndex: 7,
          CellIndex: 3,
          Value: null,
        },
        {
          RowIndex: 7,
          CellIndex: 4,
          Value: null,
        },
        {
          RowIndex: 7,
          CellIndex: 5,
          Value: null,
        }, // # units
        {
          RowIndex: 7,
          CellIndex: 7,
          Value: null,
        },
        {
          RowIndex: 7,
          CellIndex: 8,
          Value: null,
        },
        {
          RowIndex: 7,
          CellIndex: 9,
          Value: null,
        },
        {
          RowIndex: 7,
          CellIndex: 10,
          Value: null,
        }, // revenue
        {
          RowIndex: 12,
          CellIndex: 2,
          Value: null,
        },
        {
          RowIndex: 12,
          CellIndex: 3,
          Value: null,
        },
        {
          RowIndex: 12,
          CellIndex: 4,
          Value: null,
        },
        {
          RowIndex: 12,
          CellIndex: 5,
          Value: null,
        }, // total expenses
        {
          RowIndex: 12,
          CellIndex: 7,
          Value: null,
        },
        {
          RowIndex: 12,
          CellIndex: 8,
          Value: null,
        },
        {
          RowIndex: 12,
          CellIndex: 9,
          Value: null,
        },
        {
          RowIndex: 12,
          CellIndex: 10,
          Value: null,
        }, // earnings before taxes
        {
          RowIndex: 17,
          CellIndex: 2,
          Value: null,
        },
        {
          RowIndex: 17,
          CellIndex: 3,
          Value: null,
        },
        {
          RowIndex: 17,
          CellIndex: 4,
          Value: null,
        },
        {
          RowIndex: 17,
          CellIndex: 5,
          Value: null,
        }, // ebt ($ per loan)
        {
          RowIndex: 17,
          CellIndex: 7,
          Value: null,
        },
        {
          RowIndex: 17,
          CellIndex: 8,
          Value: null,
        },
        {
          RowIndex: 17,
          CellIndex: 9,
          Value: null,
        },
        {
          RowIndex: 17,
          CellIndex: 10,
          Value: null,
        }, // EBT (bps)
        {
          RowIndex: 22,
          CellIndex: 2,
          Value: null,
        },
        {
          RowIndex: 22,
          CellIndex: 3,
          Value: null,
        },
        {
          RowIndex: 22,
          CellIndex: 4,
          Value: null,
        },
        {
          RowIndex: 22,
          CellIndex: 5,
          Value: null,
        }, // Equity
        {
          RowIndex: 22,
          CellIndex: 7,
          Value: null,
        },
        {
          RowIndex: 22,
          CellIndex: 8,
          Value: null,
        },
        {
          RowIndex: 22,
          CellIndex: 9,
          Value: null,
        },
        {
          RowIndex: 22,
          CellIndex: 10,
          Value: null,
        },
      ],
    });
  }

  useEffect(() => {
    if (chartDataResult.isSuccess && chartDataResult.status === "fulfilled") {
      setState((prevState) => ({
        ...prevState,
        fundedVolumeChartOptions: [
          {
            x: ["Q1'23", "Q2'23", "Q3'23", "Q4'23"],
            y: [
              chartDataResult.data.Outputs[0].Value,
              chartDataResult.data.Outputs[1].Value,
              chartDataResult.data.Outputs[2].Value,
              chartDataResult.data.Outputs[3].Value,
            ],
            type: "bar",
            text: [
              chartDataResult.data.Outputs[0].Value,
              chartDataResult.data.Outputs[1].Value,
              chartDataResult.data.Outputs[2].Value,
              chartDataResult.data.Outputs[3].Value,
            ].map((number) =>
              number > 0
                ? "<b>$" + number.toFixed(1) + "M</b>"
                : "<b>-$" + (number * -1).toFixed(1) + "M</b>",
            ),
            textfont: {
              size: 16,
            },
            textposition: "auto",
            hoverinfo: "none",
            marker: {
              color: "rgb(28, 64, 202)",
            },
            name: "Volume",
          },
        ],
      }));

      setState((prevState) => ({
        ...prevState,
        numberUnitsChartOptions: [
          {
            x: ["Q1'23", "Q2'23", "Q3'23", "Q4'23"],
            y: [
              chartDataResult.data.Outputs[4].Value,
              chartDataResult.data.Outputs[5].Value,
              chartDataResult.data.Outputs[6].Value,
              chartDataResult.data.Outputs[7].Value,
            ],
            mode: "lines+markers+text",
            type: "scatter",
          },
        ],
      }));

      setState((prevState) => ({
        ...prevState,
        revenueChartOptions: [
          {
            x: ["Q1'23", "Q2'23", "Q3'23", "Q4'23"],
            y: [
              chartDataResult.data.Outputs[8].Value,
              chartDataResult.data.Outputs[9].Value,
              chartDataResult.data.Outputs[10].Value,
              chartDataResult.data.Outputs[11].Value,
            ],
            mode: "lines+markers+text",
            type: "scatter",
          },
        ],
      }));

      setState((prevState) => ({
        ...prevState,
        totalExpensesChartOptions: [
          {
            x: ["Q1'23", "Q2'23", "Q3'23", "Q4'23"],
            y: [
              chartDataResult.data.Outputs[12].Value,
              chartDataResult.data.Outputs[13].Value,
              chartDataResult.data.Outputs[14].Value,
              chartDataResult.data.Outputs[15].Value,
            ],
            name: "Actual",
            type: "bar",
            text: [
              chartDataResult.data.Outputs[12].Value,
              chartDataResult.data.Outputs[13].Value,
              chartDataResult.data.Outputs[14].Value,
              chartDataResult.data.Outputs[15].Value,
            ].map((number) =>
              number > 0
                ? "<b>$" + (Math.round(number * 10) / 10).toFixed(1) + "M</b>"
                : "<b>-$" +
                  (Math.round(number * -10) / 10).toFixed(1) +
                  "M</b>",
            ),
            textfont: {
              size: 16,
            },
            textposition: "auto",
            hoverinfo: "none",
            marker: {
              color: "rgb(28, 64, 202)",
            },
          },
        ],
      }));

      setState((prevState) => ({
        ...prevState,
        earningsBeforeTaxesChartOptions: [
          {
            x: ["Q1'23", "Q2'23", "Q3'23", "Q4'23"],
            y: [
              chartDataResult.data.Outputs[16].Value,
              chartDataResult.data.Outputs[17].Value,
              chartDataResult.data.Outputs[18].Value,
              chartDataResult.data.Outputs[19].Value,
            ],
            name: "Actual",
            type: "bar",
            text: [
              chartDataResult.data.Outputs[16].Value,
              chartDataResult.data.Outputs[17].Value,
              chartDataResult.data.Outputs[18].Value,
              chartDataResult.data.Outputs[19].Value,
            ].map((number) =>
              number > 0
                ? "<b>$" + (Math.round(number * 10) / 10).toFixed(1) + "M</b>"
                : "<b>-$" +
                  (Math.round(number * -10) / 10).toFixed(1) +
                  "M</b>",
            ),
            textfont: {
              size: 16,
            },
            textposition: "auto",
            hoverinfo: "none",
            marker: {
              color: "rgb(28, 64, 202)",
            },
          },
        ],
      }));
      // Math.round(total).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '</b>') : ('<b>-' +
      setState((prevState) => ({
        ...prevState,
        ebtDollarChartOptions: [
          {
            x: ["Q1'23", "Q2'23", "Q3'23", "Q4'23"],
            y: [
              chartDataResult.data.Outputs[20].Value,
              chartDataResult.data.Outputs[21].Value,
              chartDataResult.data.Outputs[22].Value,
              chartDataResult.data.Outputs[23].Value,
            ],
            name: "Actual",
            type: "bar",
            text: [
              chartDataResult.data.Outputs[20].Value,
              chartDataResult.data.Outputs[21].Value,
              chartDataResult.data.Outputs[22].Value,
              chartDataResult.data.Outputs[23].Value,
            ].map((number) =>
              number > 0
                ? "<b>" +
                  Math.round(number)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                  "</b>"
                : "<b>" +
                  Math.round(number)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                  "</b>",
            ),
            textfont: {
              size: 16,
            },
            textposition: "auto",
            hoverinfo: "none",
            marker: {
              color: "rgb(28, 64, 202)",
            },
          },
        ],
      }));

      setState((prevState) => ({
        ...prevState,
        ebtBPSChartOptions: [
          {
            x: ["Q1'23", "Q2'23", "Q3'23", "Q4'23"],
            y: [
              chartDataResult.data.Outputs[24].Value,
              chartDataResult.data.Outputs[25].Value,
              chartDataResult.data.Outputs[26].Value,
              chartDataResult.data.Outputs[27].Value,
            ],
            mode: "lines+markers+text",
            type: "scatter",
          },
        ],
      }));

      setState((prevState) => ({
        ...prevState,
        equityChartOptions: [
          {
            x: ["Q1'23", "Q2'23", "Q3'23", "Q4'23"],
            y: [
              chartDataResult.data.Outputs[28].Value,
              chartDataResult.data.Outputs[29].Value,
              chartDataResult.data.Outputs[30].Value,
              chartDataResult.data.Outputs[31].Value,
            ],
            type: "bar",
            text: [
              chartDataResult.data.Outputs[28].Value,
              chartDataResult.data.Outputs[29].Value,
              chartDataResult.data.Outputs[30].Value,
              chartDataResult.data.Outputs[31].Value,
            ].map((number) =>
              number > 0
                ? "<b>$" + (Math.round(number * 10) / 10).toFixed(1) + "M</b>"
                : "<b>-$" +
                  (Math.round(number * -10) / 10).toFixed(1) +
                  "M</b>",
            ),
            textfont: {
              size: 16,
            },
            textposition: "auto",
            hoverinfo: "none",
            marker: {
              color: "rgb(28, 64, 202)",
            },
            name: "Equity",
          },
        ],
      }));

      setState((prevState) => ({
        ...prevState,
        chartsLoadedFlag: true,
        isChartLoading: false,
        errorFlag: false,
      }));
    } else if (chartDataResult.isError) {
      setState((prevState) => ({
        ...prevState,
        errorFlag: true,
        errorCode: "500",
        errorMessage: tryAgainLaterMessage,
        errorType: "InternalAPI",
      }));
    }
  }, [chartDataResult]);

  const handleChange = (newValue: number[], box: number) => {
    let newInputValuesTMP: number[] = [];
    newInputValuesTMP = state.inputValues;
    switch (box) {
      case 1:
        newInputValuesTMP[0] = newValue[0];
        break;

      case 2:
        newInputValuesTMP[1] = newValue[0];
        break;

      case 3:
        newInputValuesTMP[2] = newValue[0];
        newInputValuesTMP[4] = newValue[1];
        newInputValuesTMP[6] = newValue[2];
        break;

      case 4:
        newInputValuesTMP[3] = newValue[0];
        newInputValuesTMP[5] = newValue[1];
        newInputValuesTMP[7] = newValue[2];
        break;
    }
    setState({ ...state, inputValues: newInputValuesTMP });
  };

  const plotlyRefChart1 = createRef<Plot>();
  const plotlyRefChart2 = createRef<Plot>();
  const plotlyRefChart3 = createRef<Plot>();
  const plotlyRefChart4 = createRef<Plot>();
  const plotlyRefChart5 = createRef<Plot>();
  const plotlyRefChart6 = createRef<Plot>();
  const plotlyRefChart7 = createRef<Plot>();
  const plotlyRefChart8 = createRef<Plot>();
  const plotlyRefChart9 = createRef<Plot>();

  function exportToPdfWrapper() {
    exportPlotlyToPdf(
      "Rocket Companies Financial Modeling",
      [
        plotlyRefChart1,
        plotlyRefChart2,
        plotlyRefChart3,
        plotlyRefChart4,
        plotlyRefChart5,
        plotlyRefChart6,
        plotlyRefChart7,
        plotlyRefChart8,
      ],
      true,
      plotlyRefChart9,
    );
  }

  function genericPlot(
    ref: RefObject<Plot>,
    data: any[],
    title: string,
    annotations?: any[],
  ) {
    return (
      <Plot
        ref={ref}
        data={data}
        layout={{
          ...PlotlyDefaultMargin,
          barmode: "stack",
          title: {
            text: "<b>" + title + "</b>",
            y: 0.95,
            x: 0.05,
          },
          legend: { orientation: "h" },
          yaxis: {
            visible: false,
            showticklabels: false,
          },
          annotations: annotations,
          autosize: true,
        }}
        useResizeHandler
        config={{ responsive: true, displayModeBar: false, staticPlot: true }}
        className="w-100"
      />
    );
  }

  return (
    <div className="module-master" style={{ paddingRight: 0 }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div className="module-title">Financial Modeling</div>
      </div>
      <div
        className="module-row"
        style={{ display: state.errorFlag ? "block" : "none" }}
      >
        <Error
          code={state.errorCode}
          type={state.errorType}
          message={state.errorMessage}
        />
      </div>
      <div
        className="module-row"
        style={{ display: state.errorFlag ? "none" : "block" }}
      >
        {!state.initialDataLoadedFlag ? (
          <div className="loading-div">
            <FontAwesomeIcon
              icon={faSpinner}
              size="2x"
              spin={true}
              style={{ margin: "0 auto" }}
            />
          </div>
        ) : (
          <div>
            <div
              className="w-100"
              style={{ display: "flex", paddingBottom: "5px" }}
            >
              <button
                className="btn btn-primary"
                style={{ width: "100%" }}
                onClick={sendDataBuildCharts}
              >
                Run Model
              </button>
              <button
                className="btn btn-primary"
                style={{ width: "100%" }}
                disabled={!state.chartsLoadedFlag}
                onClick={exportToPdfWrapper}
              >
                Export to PDF
              </button>
            </div>
            <div>
              <div>
                <div className="module-financial-model-title">Volume</div>
                <div className="module-row">
                  <ResultFromMultipleInputSliders
                    data={[
                      {
                        startingValue: state.initialInputValues[0],
                        firstSymbol: "$",
                        secondSymbol: "M",
                        label: "Vol.",
                      },
                    ]}
                    resultResolver={(values) => 0}
                    showLabels={false}
                    result={{
                      firstSymbol: "$",
                      secondSymbol: "M",
                      label: "Total",
                    }}
                    showResult={false}
                    onNewValuesChange={(values) => handleChange(values, 1)}
                    columnlabel={"Q3'23"}
                    vertical={true}
                  />
                  <ResultFromMultipleInputSliders
                    data={[
                      {
                        startingValue: state.initialInputValues[1],
                        firstSymbol: "$",
                        secondSymbol: "M",
                        label: "Purch.",
                      },
                    ]}
                    resultResolver={(values) => 0}
                    showLabels={false}
                    result={{
                      firstSymbol: "$",
                      secondSymbol: "M",
                      label: "Total",
                    }}
                    showResult={false}
                    onNewValuesChange={(values) => handleChange(values, 2)}
                    columnlabel={"Q4'23"}
                    vertical={true}
                  />
                </div>
              </div>
              <div>
                <div className="module-financial-model-title">
                  Income Statement Economics
                </div>
                <div className="module-row">
                  <ResultFromMultipleInputSliders
                    data={[
                      {
                        startingValue: state.initialInputValues[2],
                        firstSymbol: "",
                        secondSymbol: "bps",
                        label: "REV",
                      },
                      {
                        startingValue: state.initialInputValues[4],
                        firstSymbol: "",
                        secondSymbol: "bps",
                        label: "VE",
                      },
                      {
                        startingValue: state.initialInputValues[6],
                        firstSymbol: "",
                        secondSymbol: "%",
                        label: "FEG",
                      },
                    ]}
                    resultResolver={(values) => 0}
                    showLabels={true}
                    result={{
                      firstSymbol: "",
                      secondSymbol: "%",
                      label: "Total",
                    }}
                    showResult={false}
                    onNewValuesChange={(values) => handleChange(values, 3)}
                    columnlabel={"Q3'23"}
                    vertical={true}
                  />
                  <ResultFromMultipleInputSliders
                    data={[
                      {
                        startingValue: state.initialInputValues[3],
                        firstSymbol: "",
                        secondSymbol: "bps",
                        label: "REV",
                      },
                      {
                        startingValue: state.initialInputValues[5],
                        firstSymbol: "",
                        secondSymbol: "bps",
                        label: "VE",
                      },
                      {
                        startingValue: state.initialInputValues[7],
                        firstSymbol: "",
                        secondSymbol: "%",
                        label: "FEG",
                      },
                    ]}
                    resultResolver={(values) => 0}
                    showLabels={false}
                    result={{
                      firstSymbol: "",
                      secondSymbol: "%",
                      label: "Total",
                    }}
                    showResult={false}
                    onNewValuesChange={(values) => handleChange(values, 4)}
                    columnlabel={"Q4'23"}
                    vertical={true}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        {!state.chartsLoadedFlag || state.isChartLoading ? (
          <div style={{ width: "100%", height: "100%" }}>
            {state.isChartLoading && (
              <div className="loading-div">
                <FontAwesomeIcon
                  icon={faSpinner}
                  size="2x"
                  spin={true}
                  style={{ margin: "0 auto" }}
                />
              </div>
            )}
          </div>
        ) : (
          <div
            style={{ width: "100%", height: "100%" }}
            className="container-fluid"
          >
            <div className="row">
              <div className="col-lg-12 col-xl-6 border-on-top">
                {!state.isChartLoading &&
                  genericPlot(
                    plotlyRefChart1,
                    state.fundedVolumeChartOptions,
                    "Funded Volume",
                  )}
              </div>
              <div className="col-lg-12 col-xl-6 border-on-top">
                {!state.isChartLoading &&
                  genericPlot(
                    plotlyRefChart2,
                    state.numberUnitsChartOptions,
                    "# Units",
                    [
                      {
                        x: "Q1'23",
                        y: state.numberUnitsChartOptions[0].y[0],
                        text: unitsGenerator(
                          state.numberUnitsChartOptions[0].y[0],
                        ),
                        xanchor: "center",
                        yanchor: "bottom",
                        showarrow: false,
                        font: {
                          size: 16,
                        },
                      },
                      {
                        x: "Q2'23",
                        y: state.numberUnitsChartOptions[0].y[1],
                        text: unitsGenerator(
                          state.numberUnitsChartOptions[0].y[1],
                        ),
                        xanchor: "center",
                        yanchor: "bottom",
                        showarrow: false,
                        font: {
                          size: 16,
                        },
                      },
                      {
                        x: "Q3'23",
                        y: state.numberUnitsChartOptions[0].y[2],
                        text: unitsGenerator(
                          state.numberUnitsChartOptions[0].y[2],
                        ),
                        xanchor: "center",
                        yanchor: "bottom",
                        showarrow: false,
                        font: {
                          size: 16,
                        },
                      },
                      {
                        x: "Q4'23",
                        y: state.numberUnitsChartOptions[0].y[3],
                        text: unitsGenerator(
                          state.numberUnitsChartOptions[0].y[3],
                        ),
                        xanchor: "center",
                        yanchor: "bottom",
                        showarrow: false,
                        font: {
                          size: 16,
                        },
                      },
                    ],
                  )}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-xl-6 border-on-top">
                {!state.isChartLoading &&
                  genericPlot(
                    plotlyRefChart3,
                    state.revenueChartOptions,
                    "Revenue",
                    [
                      {
                        x: "Q1'23",
                        y: state.revenueChartOptions[0].y[0],
                        text: totalGenerator(state.revenueChartOptions[0].y[0]),
                        xanchor: "center",
                        yanchor: "bottom",
                        showarrow: false,
                        font: {
                          size: 16,
                        },
                      },
                      {
                        x: "Q2'23",
                        y: state.revenueChartOptions[0].y[1],
                        text: totalGenerator(state.revenueChartOptions[0].y[1]),
                        xanchor: "center",
                        yanchor: "bottom",
                        showarrow: false,
                        font: {
                          size: 16,
                        },
                      },
                      {
                        x: "Q3'23",
                        y: state.revenueChartOptions[0].y[2],
                        text: totalGenerator(state.revenueChartOptions[0].y[2]),
                        xanchor: "center",
                        yanchor: "bottom",
                        showarrow: false,
                        font: {
                          size: 16,
                        },
                      },
                      {
                        x: "Q4'23",
                        y: state.revenueChartOptions[0].y[3],
                        text: totalGenerator(state.revenueChartOptions[0].y[3]),
                        xanchor: "center",
                        yanchor: "bottom",
                        showarrow: false,
                        font: {
                          size: 16,
                        },
                      },
                    ],
                  )}
              </div>
              <div className="col-lg-12 col-xl-6 border-on-top">
                {!state.isChartLoading &&
                  genericPlot(
                    plotlyRefChart4,
                    state.totalExpensesChartOptions,
                    "Total Expenses",
                  )}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-xl-6 border-on-top">
                {!state.isChartLoading &&
                  genericPlot(
                    plotlyRefChart5,
                    state.totalExpensesChartOptions,
                    "Earnings Before Taxes",
                  )}
              </div>
              <div className="col-lg-12 col-xl-6 border-on-top">
                {!state.isChartLoading &&
                  genericPlot(
                    plotlyRefChart6,
                    state.ebtDollarChartOptions,
                    "EBT ($ per Loan)",
                  )}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-xl-6 border-on-top">
                {!state.isChartLoading &&
                  genericPlot(
                    plotlyRefChart7,
                    state.ebtBPSChartOptions,
                    "EBT (bps)",
                    [
                      {
                        x: "Q1'23",
                        y: state.ebtBPSChartOptions[0].y[0],
                        text: bpsGenerator(state.ebtBPSChartOptions[0].y[0]),
                        xanchor: "center",
                        yanchor: "bottom",
                        showarrow: false,
                        font: {
                          size: 16,
                        },
                      },
                      {
                        x: "Q2'23",
                        y: state.ebtBPSChartOptions[0].y[1],
                        text: bpsGenerator(state.ebtBPSChartOptions[0].y[1]),
                        xanchor: "center",
                        yanchor: "bottom",
                        showarrow: false,
                        font: {
                          size: 16,
                        },
                      },
                      {
                        x: "Q3'23",
                        y: state.ebtBPSChartOptions[0].y[2],
                        text: bpsGenerator(state.ebtBPSChartOptions[0].y[2]),
                        xanchor: "center",
                        yanchor: "bottom",
                        showarrow: false,
                        font: {
                          size: 16,
                        },
                      },
                      {
                        x: "Q4'23",
                        y: state.ebtBPSChartOptions[0].y[3],
                        text: bpsGenerator(state.ebtBPSChartOptions[0].y[3]),
                        xanchor: "center",
                        yanchor: "bottom",
                        showarrow: false,
                        font: {
                          size: 16,
                        },
                      },
                    ],
                  )}
              </div>
              <div className="col-lg-12 col-xl-6 border-on-top">
                {!state.isChartLoading &&
                  genericPlot(
                    plotlyRefChart8,
                    state.equityChartOptions,
                    "Equity",
                  )}
              </div>
              <div className="source-text">Source: Gallus Insights</div>
              <div style={{ display: "none" }}>
                <Plot
                  ref={plotlyRefChart9}
                  data={
                    [
                      {
                        type: "table",
                        header: {
                          values: [
                            ["<b>Parameter</b>"],
                            ["<b>Q3'23</b>"],
                            ["<b>Q4'23</b>"],
                          ],
                          align: "center",
                          line: { width: 1, color: "black" },
                          fill: { color: "grey" },
                          font: { family: "Arial", size: 12, color: "white" },
                        },
                        cells: {
                          values: [
                            ["Volume", "REV", "VE", "FEG"],
                            [
                              "$" + state.pdfValues[0] + "M",
                              state.pdfValues[2] + "bps",
                              state.pdfValues[4] + "bps",
                              state.pdfValues[6] + "%",
                            ],
                            [
                              "$" + state.pdfValues[1] + "M",
                              state.pdfValues[3] + "bps",
                              state.pdfValues[5] + "bps",
                              state.pdfValues[7] + "%",
                            ],
                          ],
                          align: "center",
                          line: { color: "black", width: 1 },
                          font: { family: "Arial", size: 11, color: ["black"] },
                        },
                      },
                    ] as any
                  }
                  layout={{
                    ...PlotlyDefaultMargin,
                    barmode: "stack",
                    title: "Parameters",
                    yaxis: {
                      visible: true,
                      showticklabels: false,
                    },
                    autosize: true,
                  }}
                  useResizeHandler
                  config={{ responsive: true, displayModeBar: false }}
                  className="w-100"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
