import { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import QRCodeCanvas from "qrcode.react";
import { getUserSessionItem, refreshToken } from "../..";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { Error } from "../../components/Error";

interface MFASetUpProps {
  onSetupSuccess: () => void;
  alreadyAcceptedMFA: boolean; //used when the user clicks their profile icon
}

export const MFASetUp = (props: MFASetUpProps) => {
  const [acceptedSetUp, setAcceptedSetUp] = useState<boolean>(
    props.alreadyAcceptedMFA,
  );
  const [mfaLoading, setMfaLoading] = useState<boolean>(false);
  const [mfaSuccess, setMfaSuccess] = useState<boolean>();
  const [mfaCode, setMFACode] = useState<string>("");
  const [mfaString, setMFAString] = useState<string>("");
  const [secretCode, setSecretCode] = useState<string>("");
  const [errorFlag, setErrorFlag] = useState<boolean>(false);
  const [errorCode, setErrorCode] = useState<string>("");
  const [errorType, setErrorType] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  async function setupTOTPAuth() {
    try {
      //try if there is an authenticated user.
      setMfaLoading(true);
      await refreshToken();
      const user = await Auth.currentAuthenticatedUser();

      try {
        //try to do the actual MFA process.
        const secretCode = await Auth.setupTOTP(user as any);
        setSecretCode(secretCode);
        setMFAString(
          "otpauth://totp/GallusAnalytics: " +
            getUserSessionItem("userName") +
            "?secret=" +
            secretCode,
        );
        setMfaLoading(false);
      } catch (errorSetupTOTP) {
        setErrorFlag(true);
        setErrorCode("500");
        setErrorMessage(
          ("enableMFA setupTOTP try/catch error:" + errorSetupTOTP) as string,
        );
        setErrorType("AWSAPI");
      }
    } catch (errorNoAuthUser) {
      setErrorFlag(true);
      setErrorCode("500");
      setErrorMessage(
        ("enableMFA currentAuthenticatedUser() try/catch error:" +
          errorNoAuthUser) as string,
      );
      setErrorType("AWSAPI");
      console.log("Auth.currentAuthenticatedUser() error: " + errorNoAuthUser);
    }
  }

  async function submitMFACode() {
    try {
      //try if there is an authenticated user.
      setMfaLoading(true);
      await refreshToken();
      const user = await Auth.currentAuthenticatedUser();

      try {
        const cognitoUserSession: CognitoUserSession =
          await Auth.verifyTotpToken(user, mfaCode);
        await Auth.setPreferredMFA(user, "TOTP");
        setMfaLoading(false);
        setMfaSuccess(true);
      } catch (errorVerifyTotpToken) {
        setMfaLoading(false);
        setMfaSuccess(false);
        setErrorFlag(true);
        setErrorCode("500");
        setErrorMessage(
          ("enableMFA verifyTotpToken try/catch error:" +
            errorVerifyTotpToken) as string,
        );
        setErrorType("AWSAPI");
        /*             Bugsnag.notify(JSON.stringify(error), function (event) {
                    event.severity = 'error';
                    event.context = 'enableMFA try/catch error';
                    event.setUser(getUserSessionItem("userName") ?? "User not in UserSession")
                  }); */
        console.log(errorVerifyTotpToken);
      }
    } catch (errorNoAuthUser) {
      setErrorFlag(true);
      setErrorCode("500");
      setErrorMessage(
        ("enableMFA currentAuthenticatedUser() try/catch error:" +
          errorNoAuthUser) as string,
      );
      setErrorType("AWSAPI");
      console.log("Auth.currentAuthenticatedUser() error: " + errorNoAuthUser);
    }
  }

  useEffect(() => {
    if (mfaSuccess) {
      setTimeout(() => {
        props.onSetupSuccess();
      }, 2000);
    }
  }, [mfaSuccess]);

  useEffect(() => {
    setupTOTPAuth();
  }, []);

  return (
    <div
      className="container-fluid col-md-4 roundedCorners"
      style={{ width: "fit-content" }}
    >
      <div className="mb-2">
        <div className="col-12 signup-text text-center-for-titles">
          Two-factor authentication
        </div>
        {mfaLoading && (
          <div className="loading-div">
            <FontAwesomeIcon
              icon={faSpinner}
              size="2x"
              spin={true}
              style={{ margin: "0 auto" }}
            />
          </div>
        )}
        {mfaSuccess && (
          <div className="mb-2">
            <div style={{ fontWeight: "bold" }}>
              Two-factor authentication has been set-up successfully.
            </div>
          </div>
        )}
        {!mfaLoading && !mfaSuccess && (
          <>
            <div
              className="mb-2"
              style={{ display: acceptedSetUp ? "none" : "block" }}
            >
              <div style={{ paddingBottom: "15px" }}>
                Setting up two-factor authentication can help you secure your
                Gallus account. Would you like to set up now?
              </div>
              <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                <button
                  className="btn-dark-rounded btn btn-primary"
                  type="submit"
                  onClick={() => setAcceptedSetUp(true)}
                >
                  Set Up MFA
                </button>
                <button
                  className="btn-dark-rounded btn btn-primary"
                  type="submit"
                  onClick={() => props.onSetupSuccess()}
                >
                  Skip
                </button>
              </div>
            </div>
            <div
              style={{
                display:
                  acceptedSetUp && mfaString.length !== 0 ? "block" : "none",
              }}
            >
              <h5>
                Please scan the QR code below and enter the code provided to
                enable TOTP MFA.
              </h5>
              <div className="center-aligned">
                <QRCodeCanvas value={mfaString} />
              </div>
              <div className="text-center my-3">
                Or enter the security key below in the authenticator app
                <br />
                <b>{secretCode}</b>
              </div>
              <hr />
              <div className="my-2">
                <h5>Enter the 6 digit code</h5>
                The code is generated by your authenticator app.
              </div>
              <div className="my-2">
                <input
                  type="text"
                  className="form-control formSignUpUsername"
                  maxLength={6}
                  value={mfaCode}
                  onChange={(e) => {
                    setMFACode(e.target.value);
                  }}
                />
              </div>
              <div
                className="signup-error-generic"
                style={{
                  display: mfaSuccess === false ? "block" : "none",
                  margin: 15,
                }}
              >
                <div style={{ padding: 5 }}>
                  Invalid code. Please try again.
                </div>
              </div>
              <div className="signup-end">
                <button
                  className="btn-dark-rounded btn btn-primary"
                  type="submit"
                  onClick={() => submitMFACode()}
                  disabled={mfaCode.length < 6 ? true : false}
                >
                  Submit
                </button>
              </div>
            </div>
          </>
        )}
        {errorFlag && (
          <div>
            <Error code={errorCode} type={errorType} message={errorMessage} />
          </div>
        )}
      </div>
    </div>
  );
};
