import { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { refreshToken } from "../..";
import { Error } from "../../components/Error";

interface DisableMFAProps {}

export const DisableMFA = (props: DisableMFAProps) => {
  const [userHasMFAEnabled, setUserHasMFAEnabled] = useState<boolean>(false);
  const [acceptedDisableMFA, setAcceptedDisableMFA] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [mfaVerificationSuccess, setMfaVerificationSuccess] =
    useState<boolean>();
  const [mfaCode, setMFACode] = useState<string>("");
  const [errorFlag, setErrorFlag] = useState<boolean>(false);
  const [errorCode, setErrorCode] = useState<string>("");
  const [errorType, setErrorType] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const DisableMFA = async () => {
    try {
      //try if there is an authenticated user.
      await refreshToken();
      const user = await Auth.currentAuthenticatedUser();

      try {
        //try to do the actual MFA process.
        setLoading(true);
        await Auth.verifyTotpToken(user, mfaCode);
        await Auth.setPreferredMFA(user, "NOMFA");
        setLoading(false);
        setMfaVerificationSuccess(true);
      } catch (errorMFAFailure) {
        setLoading(false);
        setMfaVerificationSuccess(false);
        setErrorFlag(true);
        setErrorCode("500");
        setErrorMessage(
          ("disableMFA setPreferredMFA try/catch error:" +
            errorMFAFailure) as string,
        );
        setErrorType("AWSAPI");
        /* Bugsnag.notify(JSON.stringify(errorMFAFailure), function (event) {
                    event.severity = 'error'
                    event.context = 'disableMFA setPreferredMFA try/catch error'
                    event.setUser(getUserSessionItem("userName") ?? "User not in UserSession")
                }); */
      }
    } catch (errorNoAuthUser) {
      setErrorFlag(true);
      setErrorCode("500");
      setErrorMessage(
        ("disableMFA currentAuthenticatedUser() try/catch error:" +
          errorNoAuthUser) as string,
      );
      setErrorType("AWSAPI");
      console.log("Auth.currentAuthenticatedUser() error: " + errorNoAuthUser);
    }
  };

  const GetMFAMethod = async () => {
    try {
      //try if there is an authenticated user.
      await refreshToken();
      const user = await Auth.currentAuthenticatedUser();

      try {
        //try to do the actual MFA process.
        setLoading(true);
        const preferredMFA: string = await Auth.getPreferredMFA(user);
        preferredMFA === "NOMFA"
          ? setUserHasMFAEnabled(false)
          : setUserHasMFAEnabled(true);
        setLoading(false);
      } catch (errorMFAFailure) {
        setErrorFlag(true);
        setErrorCode("500");
        setErrorMessage(
          ("disableMFA getPreferredMFA try/catch error:" +
            errorMFAFailure) as string,
        );
        setErrorType("AWSAPI");
        /*                 Bugsnag.notify(JSON.stringify(errorMFAFailure), function (event) {
                    event.severity = 'error'
                    event.context = 'disableMFA getPreferredMFA try/catch error'
                    event.setUser(getUserSessionItem("userName") ?? "User not in UserSession")
                }); */
      }
    } catch (errorNoAuthUser) {
      setErrorFlag(true);
      setErrorCode("500");
      setErrorMessage(
        ("disableMFA currentAuthenticatedUser() try/catch error:" +
          errorNoAuthUser) as string,
      );
      setErrorType("AWSAPI");
      console.log("Auth.currentAuthenticatedUser() error: " + errorNoAuthUser);
    }
  };

  useEffect(() => {
    GetMFAMethod();
  }, []);

  return (
    <div
      className="container-fluid col-md-4 roundedCorners"
      style={{ width: "fit-content" }}
    >
      <div className="mb-2">
        <div className="col-12 signup-text text-center-for-titles">
          Disable two-factor authentication
        </div>
        {loading && (
          <div className="loading-div">
            <FontAwesomeIcon
              icon={faSpinner}
              size="2x"
              spin={true}
              style={{ margin: "0 auto" }}
            />
          </div>
        )}
        {!loading && !userHasMFAEnabled && (
          <div>Current user has not enabled two-factor authentication.</div>
        )}
        {!loading && userHasMFAEnabled && (
          <div>
            <div
              className="mb-2"
              style={{ display: !acceptedDisableMFA ? "block" : "none" }}
            >
              <div style={{ paddingBottom: "15px" }}>
                Disabling MFA will lower your account security. Are you sure?
              </div>
              <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                <button
                  className="btn-dark-rounded btn btn-primary"
                  type="submit"
                  onClick={() => setAcceptedDisableMFA(true)}
                >
                  Confirm
                </button>
              </div>
            </div>
            <div
              style={{
                display:
                  acceptedDisableMFA && !mfaVerificationSuccess
                    ? "block"
                    : "none",
              }}
            >
              <h5>
                To confirm your identity, please enter the 6 digit code
                generated by your authenticator app.
              </h5>
              <div className="my-2">
                <input
                  type="text"
                  className="form-control formSignUpUsername"
                  maxLength={6}
                  value={mfaCode}
                  onChange={(e) => {
                    setMFACode(e.target.value);
                  }}
                />
              </div>
              <div
                className="signup-error-generic"
                style={{
                  display: mfaVerificationSuccess === false ? "block" : "none",
                  margin: 15,
                }}
              >
                <div style={{ padding: 5 }}>
                  Invalid code. Please try again.
                </div>
              </div>
              <div className="signup-end">
                <button
                  className="btn-dark-rounded btn btn-primary"
                  type="submit"
                  onClick={() => DisableMFA()}
                  disabled={mfaCode.length < 6 ? true : false}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        )}
        {mfaVerificationSuccess && <div>Successfully disabled MFA.</div>}
        {errorFlag && (
          <div>
            <Error code={errorCode} type={errorType} message={errorMessage} />
          </div>
        )}
      </div>
    </div>
  );
};
