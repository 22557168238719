import Version from "../Version";

interface FooterProps {
  displayVersion?: boolean;
}

const Footer = (props: FooterProps) => {
  return (
    <div className="footer d-flex justify-content-center align-items-center">
      <span>
        ©{new Date().getFullYear().toString()} by Gallus Insights.
        {props.displayVersion && " | v" + Version.FullVersion}
      </span>
    </div>
  );
};

export default Footer;
