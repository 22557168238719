import React, { useEffect, useState } from "react";
import { Action } from "@thoughtspot/visual-embed-sdk";
import { PinboardEmbedWrapper } from "./PinboardEmbedWrapper";
import { SearchEmbedWrapper } from "./SearchEmbedWrapper";

interface SearchEnable {
  enableSearch: boolean;
  searchDataSource: string[];
}

interface GenericLiveboardEmbedProps {
  description: string;
  orgID: number;
  guid?: string;
  search?: SearchEnable;
  enablePremiumFeatures: boolean;
}

const pinboardEmbedPremiumFeatures = [
  Action.EditDetails,
  Action.AddToFavorites,
  Action.Share,
  Action.Schedule,
  Action.Save,
  Action.MakeACopy,
  Action.Present,
  Action.DownloadAsPdf,
  Action.SchedulesList,
  Action.ExportTML,
  Action.UpdateTML,
  Action.EditTML,
  Action.CopyLink,
  Action.LiveboardInfo,
  Action.Pin,
  Action.SpotIQAnalyze,
  Action.ShareViz,
  Action.Download,
  Action.Edit,
  Action.AnswerDelete,
  Action.RequestAccess,
  Action.Remove,
  Action.Explore,
];

const searchEmbedPremiumFeatures = [
  Action.Pin,
  Action.Save,
  Action.Download,
  Action.SpotIQAnalyze,
  Action.QueryDetailsButtons,
  Action.Share,
  Action.RequestAccess,
];

export const GenericLiveboardEmbed = (props: GenericLiveboardEmbedProps) => {
  const [disabledActionsLiveboard, setDisabledActionsLiveboard] = useState<
    Action[]
  >([]);
  const [disabledActionsSearch, setDisabledActionsSearch] = useState<Action[]>(
    [],
  );
  useEffect(() => {
    if (!props.enablePremiumFeatures) {
      setDisabledActionsLiveboard(pinboardEmbedPremiumFeatures);
      setDisabledActionsSearch(searchEmbedPremiumFeatures);
    }
  }, []);

  return (
    <div className="generic-div">
      <div className="Description">{props.description}</div>
      {!props.search?.enableSearch && (
        <PinboardEmbedWrapper
          pinboardId={props.guid}
          frameParams={{}}
          disabledActions={disabledActionsLiveboard}
          disabledActionReason={"Premium feature"}
          hiddenActions={[]}
          orgId={props.orgID}
        />
      )}
      {props.search?.enableSearch && (
        <SearchEmbedWrapper
          frameParams={{}}
          disabledActions={disabledActionsSearch}
          disabledActionReason="Premium feature"
          hiddenActions={[]}
          dataSources={props.search?.searchDataSource}
          orgId={props.orgID}
        />
      )}
    </div>
  );
};
