import React, { useEffect } from "react";
import Plot from "react-plotly.js";
import {
  currencySymbolFormatterForArray,
  displayArrayAsPercentage,
  removeDecimalFromArray,
  arrayToFixed,
  getAverageOfArray,
  currencySymbolFormatter,
} from "../../../features/PlotlyFormatter";
import {
  useLazyCHMAccountExecutives_ViewOrderedByMetricsMonthAndYearQuery,
  useLazyCHMBranches_ViewOrderedByMetricsMonthAndYearQuery,
  useLazyCHMLoanOfficers_ViewOrderedByMetricsMonthAndYearQuery,
  useLazyDemo_ViewOrderedByMetricsMonthAndYearQuery,
} from "../../../services/gallus";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import Select, { SingleValue } from "react-select";
import { ReactSelectOption } from "../../../features/Interfaces";
import { Error, tryAgainLaterMessage } from "../../../components/Error";
import { normalizeArray, transposeMatrix } from "../../../features/TSToHeatmap";

interface HeatmapPerformanceMetricsProps {
  scope: "Account Executive" | "Branches" | "Loan Officers" | "Wholesale Sales";
  useDemoData?: boolean;
  overrideTitle?: string;
}

interface ValueRow {
  AccountExecutive: string;
  Values: any[];
  NormalizedValues: number[];
  RawValues: number[];
}

const PlotlyMargins = {
  margin: {
    t: 25,
    l: 150,
    r: 100,
    b: 10,
  },
};

export const HeatmapPerformanceMetrics = (
  props: HeatmapPerformanceMetricsProps,
) => {
  const [finalMatrix, setFinalMatrix] = React.useState<ValueRow[]>([]);
  const [metrics, setMetrics] = React.useState<string[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [averages, setAverages] = React.useState<string[]>([]);
  const [sort, setSort] = React.useState<string>("");
  const [openOptionsMenu, setOpenOptionsMenu] = React.useState<boolean>(false);
  const [month, setMonth] = React.useState<string>(
    (new Date().getMonth() + 1).toString(),
  );
  const [year, setYear] = React.useState<string>(
    new Date().getFullYear().toString(),
  );
  const [extraText, setExtraText] = React.useState<string[]>([]);
  const [garbo, setGarbo] = React.useState<number>(0);
  const [errorFlag, setErrorFlag] = React.useState<boolean>(false);
  const [errorCode, setErrorCode] = React.useState<string>("");
  const [errorMessage, setErrorMessage] = React.useState<string>("");
  const [errorType, setErrorType] = React.useState<string>("");

  useEffect(() => {
    const getDataWrapper = async () => {
      getData("2023", (new Date().getMonth() + 1).toString());
    };
    setSort("");
    getDataWrapper();
  }, [props.scope]);

  const generateAnnotations = (
    valueMatrix: ValueRow[],
    categoriesArray: string[],
  ) => {
    let annotations: any[] = [];

    valueMatrix.forEach((matrixRow) => {
      let categorySum = 0;
      categoriesArray.forEach((category) => {
        let categoryIndex = -1;
        switch (category) {
          case "Submissions #":
            categoryIndex = 0;
            break;
          case "Applications #":
            categoryIndex = 0;
            break;
          case "Submissions $":
            categoryIndex = 1;
            break;
          case "Applications $":
            categoryIndex = 1;
            break;
          case "Locks #":
            categoryIndex = 2;
            break;
          case "Locks $":
            categoryIndex = 3;
            break;
          case "Funding #":
            categoryIndex = 4;
            break;
          case "Funding $":
            categoryIndex = 5;
            break;
          case "PT% Lock to Fund":
            categoryIndex = 6;
            break;
          case "Sub to Fund":
            categoryIndex = 7;
            break;
          case "Purchase %":
            categoryIndex = 8;
            break;
          case "Community %":
            categoryIndex = 9;
            break;
          case "Alt Doc %":
            categoryIndex = 10;
            break;
          case "Investor %":
            categoryIndex = 11;
            break;
        }

        let result = {
          xref: "x1",
          yref: "y1",
          x: category,
          y: matrixRow.AccountExecutive,
          text: matrixRow.Values[categoryIndex],
          font: {
            family: "Arial",
            size: 15,
            color: "rgb(0, 0, 0)",
          },
          showarrow: false,
        };
        categorySum += matrixRow.RawValues[categoryIndex];
        annotations.push(result);
      });
    });

    return annotations;
  };

  const sortArrays = (metricSort: string, ascendant: boolean) => {
    let orderedMatrix: ValueRow[] = [];
    if (metricSort === "Name") {
      orderedMatrix = finalMatrix.sort(function (a, b) {
        if (!ascendant) {
          if (a.AccountExecutive < b.AccountExecutive) {
            return -1;
          }
          if (a.AccountExecutive > b.AccountExecutive) {
            return 1;
          }
          return 0;
        } else {
          if (a.AccountExecutive > b.AccountExecutive) {
            return -1;
          }
          if (a.AccountExecutive < b.AccountExecutive) {
            return 1;
          }
          return 0;
        }
      });
    } else {
      let indexOfMetric: number = metrics.indexOf(metricSort);
      orderedMatrix = finalMatrix.sort(function (a, b) {
        if (
          a.NormalizedValues[indexOfMetric] ===
          b.NormalizedValues[indexOfMetric]
        ) {
          return 0;
        }
        if (a.NormalizedValues[indexOfMetric] === null) {
          return 1;
        }
        if (b.NormalizedValues[indexOfMetric] === null) {
          return -1;
        }
        return !ascendant
          ? a.NormalizedValues[indexOfMetric] -
              b.NormalizedValues[indexOfMetric]
          : b.NormalizedValues[indexOfMetric] -
              a.NormalizedValues[indexOfMetric];
      });
    }
    setGarbo(garbo + 1);
    setFinalMatrix(orderedMatrix);
  };

  const [demoQuery, demoQueryResults] =
    useLazyDemo_ViewOrderedByMetricsMonthAndYearQuery();
  const [aeQuery, aeQueryResults] =
    useLazyCHMAccountExecutives_ViewOrderedByMetricsMonthAndYearQuery();
  const [branchesQuery, branchesQueryResults] =
    useLazyCHMBranches_ViewOrderedByMetricsMonthAndYearQuery();
  const [loQuery, loQueryResults] =
    useLazyCHMLoanOfficers_ViewOrderedByMetricsMonthAndYearQuery();

  const getData = async (
    year: string,
    month: string,
    period?: "QTD" | "YTD" | "ALL",
  ) => {
    setLoading(true);

    if (props.useDemoData && props.scope !== "Wholesale Sales") {
      demoQuery({ year: year, month: month, timePeriod: period });
    } else {
      switch (props.scope) {
        case "Account Executive":
          aeQuery({ year: year, month: month, timePeriod: period });
          break;
        case "Branches":
          branchesQuery({ year: year, month: month, timePeriod: period });
          break;
        case "Loan Officers":
          loQuery({ year: year, month: month, timePeriod: period });
          break;
        case "Wholesale Sales":
          aeQuery({ year: year, month: month, timePeriod: period });
          break;
      }
    }
  };

  const heatmapGeneration = (response: any) => {
    let accountExecutivesArray: string[] = [];
    response.FundingsReport.forEach((element: any) => {
      accountExecutivesArray.push(element.FullName);
    });

    if (accountExecutivesArray.length !== 1) {
      accountExecutivesArray.sort(function (a, b) {
        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        return 0;
      });
      accountExecutivesArray = accountExecutivesArray.reverse();

      let metricsArray: string[] = [];

      if (props.scope === "Account Executive") {
        metricsArray = [
          "Submissions #",
          "Submissions $",
          "Locks #",
          "Locks $",
          "Funding #",
          "Funding $",
          "PT% Lock to Fund",
          "Sub to Fund",
        ];
      } else {
        if (props.scope === "Wholesale Sales") {
          metricsArray = [
            "Submissions #",
            "Submissions $",
            "Locks #",
            "Locks $",
            "Funding #",
            "Funding $",
            "PT% Lock to Fund",
            "Sub to Fund",
            "Purchase %",
            "Community %",
            "Alt Doc %",
            "Investor %",
          ];
        } else {
          metricsArray = [
            "Applications #",
            "Applications $",
            "Locks #",
            "Locks $",
            "Funding #",
            "Funding $",
            "PT% Lock to Fund",
            "Sub to Fund",
          ];
        }
      }

      let submissionsNumberArray: any[] = [];
      let submissionsUSDArray: any[] = [];
      let locksNumberArray: any[] = [];
      let locksUSDArray: any[] = [];
      let fundingsNumberArray: any[] = [];
      let fundingsUSDArray: any[] = [];
      let percentageLockArray: any[] = [];
      let subToFoundArray: any[] = [];

      let performanceReportPurchase: any[] = [];
      let performanceReportCHMInvestor: any[] = [];
      let performanceReportALTDoc: any[] = [];
      let performanceReportCommunityMortgage: any[] = [];

      let extraTextSubmissionsNumberArray: number = 0;
      let extraTextSubmissionsUSDArray: number = 0;
      let extraTextLocksNumberArray: number = 0;
      let extraTextLocksUSDArray: number = 0;
      let extraTextFundingsNumberArray: number = 0;
      let extraTextFundingsUSDArray: number = 0;

      let extraTextPerformanceReportPurchase: number = 0;
      let extraTextPerformanceReportCHMInvestor: number = 0;
      let extraTextPerformanceReportALTDoc: number = 0;
      let extraTextPerformanceReportCommunityMortgage: number = 0;

      let tmp: any[] = [];

      ///////
      response.SubmissionsReport.forEach((element: any) => {
        tmp.push(element);
      });

      tmp.sort(function (a, b) {
        if (a.FullName < b.FullName) {
          return -1;
        }
        if (a.FullName > b.FullName) {
          return 1;
        }
        return 0;
      });

      tmp.forEach((element) => {
        submissionsNumberArray.push(element.TotalApplicationsNumber);
        submissionsUSDArray.push(element.TotalApplicationsUSD);
        if (element.FullName !== "Average") {
          extraTextSubmissionsNumberArray += element.TotalApplicationsNumber;
          extraTextSubmissionsUSDArray += element.TotalApplicationsUSD;
        }
      });

      ///////
      tmp = [];
      response.LocksReport.forEach((element: any) => {
        tmp.push(element);
      });

      tmp.sort(function (a, b) {
        if (a.FullName < b.FullName) {
          return -1;
        }
        if (a.FullName > b.FullName) {
          return 1;
        }
        return 0;
      });

      tmp.forEach((element) => {
        locksNumberArray.push(element.TotalLocksNumber);
        locksUSDArray.push(element.TotalLocksUSD);

        if (element.FullName !== "Average") {
          extraTextLocksNumberArray += element.TotalLocksNumber;
          extraTextLocksUSDArray += element.TotalLocksUSD;
        }
      });
      ///////
      tmp = [];
      response.FundingsReport.forEach((element: any) => {
        tmp.push(element);
      });

      tmp.sort(function (a, b) {
        if (a.FullName < b.FullName) {
          return -1;
        }
        if (a.FullName > b.FullName) {
          return 1;
        }
        return 0;
      });

      tmp.forEach((element) => {
        fundingsNumberArray.push(element.TotalFundingsNumber);
        fundingsUSDArray.push(element.TotalFundingsUSD);
        if (element.FullName !== "Average") {
          extraTextFundingsNumberArray += element.TotalFundingsNumber;
          extraTextFundingsUSDArray += element.TotalFundingsUSD;
        }
      });
      ///////
      tmp = [];
      response.LocksToFundsReport.forEach((element: any) => {
        tmp.push(element);
      });

      tmp.sort(function (a, b) {
        if (a.FullName < b.FullName) {
          return -1;
        }
        if (a.FullName > b.FullName) {
          return 1;
        }
        return 0;
      });

      tmp.forEach((element) => {
        percentageLockArray.push(element.TotalLocksToFundsNumber);
        subToFoundArray.push(element.TotalSubToFund);
      });
      ///////

      if (props.scope === "Wholesale Sales") {
        tmp = [];
        response.PerformanceReport.forEach((element: any) => {
          tmp.push(element);
        });
        tmp.sort(function (a, b) {
          if (a.FullName < b.FullName) {
            return -1;
          }
          if (a.FullName > b.FullName) {
            return 1;
          }
          return 0;
        });

        tmp.forEach((element) => {
          performanceReportPurchase.push(element.Purchase);
          performanceReportCHMInvestor.push(element.CHMInvestor);
          performanceReportALTDoc.push(element.ALTDoc);
          performanceReportCommunityMortgage.push(element.CommunityMortgage);

          if (element.FullName !== "Average") {
            extraTextPerformanceReportPurchase += element.Purchase;
            extraTextPerformanceReportCHMInvestor += element.CHMInvestor;
            extraTextPerformanceReportALTDoc += element.ALTDoc;
            extraTextPerformanceReportCommunityMortgage +=
              element.CommunityMortgage;
          }
        });
        ///////
      }

      let rawValuesMatrix = transposeMatrix([
        submissionsNumberArray,
        submissionsUSDArray,
        locksNumberArray,
        locksUSDArray,
        fundingsNumberArray,
        fundingsUSDArray,
        percentageLockArray,
        subToFoundArray,
        performanceReportPurchase,
        performanceReportCommunityMortgage,
        performanceReportALTDoc,
        performanceReportCHMInvestor,
      ]).reverse();
      let valuesMatrix = transposeMatrix([
        removeDecimalFromArray(submissionsNumberArray),
        currencySymbolFormatterForArray(submissionsUSDArray),
        removeDecimalFromArray(locksNumberArray),
        currencySymbolFormatterForArray(locksUSDArray),
        removeDecimalFromArray(fundingsNumberArray),
        currencySymbolFormatterForArray(fundingsUSDArray),
        displayArrayAsPercentage(percentageLockArray),
        arrayToFixed(subToFoundArray),
        displayArrayAsPercentage(performanceReportPurchase),
        displayArrayAsPercentage(performanceReportCommunityMortgage),
        displayArrayAsPercentage(performanceReportALTDoc),
        displayArrayAsPercentage(performanceReportCHMInvestor),
      ]).reverse();
      let normalizedValuesMatrix = transposeMatrix([
        normalizeArray(submissionsNumberArray),
        normalizeArray(submissionsUSDArray),
        normalizeArray(locksNumberArray),
        normalizeArray(locksUSDArray),
        normalizeArray(fundingsNumberArray),
        normalizeArray(fundingsUSDArray),
        normalizeArray(percentageLockArray, 0.2),
        normalizeArray(subToFoundArray, undefined, true),
        normalizeArray(performanceReportPurchase),
        normalizeArray(performanceReportCommunityMortgage),
        normalizeArray(performanceReportALTDoc),
        normalizeArray(performanceReportCHMInvestor),
      ]).reverse();
      let matrix: ValueRow[] = [];

      for (let i = 0; i < valuesMatrix.length; i++) {
        if (accountExecutivesArray[i] !== "Average") {
          matrix.push({
            AccountExecutive: accountExecutivesArray[i],
            Values: valuesMatrix[i],
            NormalizedValues: normalizedValuesMatrix[i],
            RawValues: rawValuesMatrix[i],
          });
        }
      }

      let averagesArray: string[] = [];
      if (props.scope !== "Wholesale Sales") {
        averagesArray = [
          getAverageOfArray(submissionsNumberArray, "amount"),
          getAverageOfArray(submissionsUSDArray, "currency"),
          getAverageOfArray(locksNumberArray, "amount"),
          getAverageOfArray(locksUSDArray, "currency"),
          getAverageOfArray(fundingsNumberArray, "amount"),
          getAverageOfArray(fundingsUSDArray, "currency"),
          getAverageOfArray(percentageLockArray, "percentage", true),
          getAverageOfArray(subToFoundArray, "amount", true),
        ];
      } else {
        averagesArray = [
          getAverageOfArray(submissionsNumberArray, "amount"),
          getAverageOfArray(submissionsUSDArray, "currency"),
          getAverageOfArray(locksNumberArray, "amount"),
          getAverageOfArray(locksUSDArray, "currency"),
          getAverageOfArray(fundingsNumberArray, "amount"),
          getAverageOfArray(fundingsUSDArray, "currency"),
          getAverageOfArray(percentageLockArray, "percentage", true),
          getAverageOfArray(subToFoundArray, "amount", true),
          getAverageOfArray(performanceReportPurchase, "percentage", true),
          getAverageOfArray(
            performanceReportCommunityMortgage,
            "percentage",
            true,
          ),
          getAverageOfArray(performanceReportALTDoc, "percentage", true),
          getAverageOfArray(performanceReportCHMInvestor, "percentage", true),
        ];
      }

      setMetrics(metricsArray);
      setFinalMatrix(matrix);
      setAverages(averagesArray);

      if (props.scope !== "Wholesale Sales") {
        setExtraText([
          extraTextSubmissionsNumberArray.toString(),
          currencySymbolFormatter(extraTextSubmissionsUSDArray),
          extraTextLocksNumberArray.toString(),
          currencySymbolFormatter(extraTextLocksUSDArray),
          extraTextFundingsNumberArray.toString(),
          currencySymbolFormatter(extraTextFundingsUSDArray),
          getAverageOfArray(percentageLockArray, "percentage", true),
          getAverageOfArray(subToFoundArray, "amount", true),
        ]);
      } else {
        setExtraText([
          extraTextSubmissionsNumberArray.toString(),
          currencySymbolFormatter(extraTextSubmissionsUSDArray),
          extraTextLocksNumberArray.toString(),
          currencySymbolFormatter(extraTextLocksUSDArray),
          extraTextFundingsNumberArray.toString(),
          currencySymbolFormatter(extraTextFundingsUSDArray),
          getAverageOfArray(percentageLockArray, "percentage", true),
          getAverageOfArray(subToFoundArray, "amount", true),
          getAverageOfArray(performanceReportPurchase, "percentage", true),
          getAverageOfArray(
            performanceReportCommunityMortgage,
            "percentage",
            true,
          ),
          getAverageOfArray(performanceReportALTDoc, "percentage", true),
          getAverageOfArray(performanceReportCHMInvestor, "percentage", true),
        ]);
      }
    }

    setGarbo(garbo + 1);
    setLoading(false);
  };

  useEffect(() => {
    if (demoQueryResults.isSuccess && demoQueryResults.status === "fulfilled") {
      heatmapGeneration(demoQueryResults.data);
      setErrorFlag(false);
    } else if (demoQueryResults.isError) {
      setErrorFlag(true);
      setLoading(false);
      setErrorCode("500");
      setErrorMessage(tryAgainLaterMessage);
      setErrorType("InternalAPI");
    }
  }, [demoQueryResults]);

  useEffect(() => {
    if (aeQueryResults.isSuccess && aeQueryResults.status === "fulfilled") {
      heatmapGeneration(aeQueryResults.data);
      setErrorFlag(false);
    } else if (aeQueryResults.isError) {
      setErrorFlag(true);
      setLoading(false);
      setErrorCode("500");
      setErrorMessage(tryAgainLaterMessage);
      setErrorType("InternalAPI");
    }
  }, [aeQueryResults]);

  useEffect(() => {
    if (
      branchesQueryResults.isSuccess &&
      branchesQueryResults.status === "fulfilled"
    ) {
      heatmapGeneration(branchesQueryResults.data);
      setErrorFlag(false);
    } else if (branchesQueryResults.isError) {
      setErrorFlag(true);
      setLoading(false);
      setErrorCode("500");
      setErrorMessage(tryAgainLaterMessage);
      setErrorType("InternalAPI");
    }
  }, [branchesQueryResults]);

  useEffect(() => {
    if (loQueryResults.isSuccess && loQueryResults.status === "fulfilled") {
      heatmapGeneration(loQueryResults.data);
      setErrorFlag(false);
    } else if (loQueryResults.isError) {
      setErrorFlag(true);
      setLoading(false);
      setErrorCode("500");
      setErrorMessage(tryAgainLaterMessage);
      setErrorType("InternalAPI");
    }
  }, [loQueryResults]);

  const returnValues = (type: "normalized" | "exec") => {
    let values: any[] = [];
    finalMatrix.forEach((element) => {
      switch (type) {
        case "normalized":
          values.push(element.NormalizedValues);
          break;
        case "exec":
          values.push(element.AccountExecutive);
          break;
      }
    });
    return values;
  };

  const returnMetrics = () => {
    let selectMatrix: ReactSelectOption[] = [];
    selectMatrix.push({ value: "Name", label: "Name" });
    metrics.forEach((element) => {
      selectMatrix.push({ value: element, label: element });
    });
    return selectMatrix;
  };

  const generateMonths = () => {
    const Months = [
      { value: "01", label: "January" },
      { value: "02", label: "February" },
      { value: "03", label: "March" },
      { value: "04", label: "April" },
      { value: "05", label: "May" },
      { value: "06", label: "June" },
      { value: "07", label: "July" },
      { value: "08", label: "August" },
      { value: "09", label: "September" },
      { value: "10", label: "October" },
      { value: "11", label: "November" },
      { value: "12", label: "December" },
    ];
    //var month = (new Date()).getMonth() + 1;
    //return (this.state.year === (new Date()).getFullYear().toString()) ? Months.slice(0, month) : Months
    return Months;
  };

  const generateYears = () => {
    const Years: { value: string; label: string }[] = [];
    for (let i = 0; i < new Date().getFullYear() + 1 - 2019; i++) {
      Years.push({
        value: (2019 + i).toString(),
        label: (2019 + i).toString(),
      });
    }
    return Years;
  };

  return (
    <div className="module-master" style={{ paddingRight: 0 }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div className="module-title">
          {typeof props.overrideTitle !== "undefined"
            ? props.overrideTitle
            : props.scope + " Performance Summary"}
        </div>
      </div>
      <div className={`card my-2`}>
        <div className="card-body">
          <div style={{ display: "flex", alignItems: "center" }}>
            <button
              className="btn me-2"
              onClick={() => setOpenOptionsMenu(!openOptionsMenu)}
            >
              {openOptionsMenu ? "X" : "V"}
            </button>
            <h5
              className="card-title mb-0"
              style={{ cursor: "pointer" }}
              onClick={() => setOpenOptionsMenu(!openOptionsMenu)}
            >
              Options
            </h5>
          </div>
          <div
            style={{
              display: openOptionsMenu ? "flex" : "none",
              width: "100",
              padding: "5px",
            }}
          >
            <div className="option-in-card">
              <div style={{ paddingRight: "5px" }}>Sort:</div>
              <Select
                placeholder="Please select metric to sort by"
                onChange={(selectedMetric: SingleValue<ReactSelectOption>) =>
                  setSort(selectedMetric!.value)
                }
                options={returnMetrics()}
                className="basic-multi-select w-100"
                classNamePrefix="select"
              />
              <div style={{ paddingLeft: "5px" }}>
                <button
                  className="btn"
                  disabled={sort !== "" ? false : true}
                  onClick={() => {
                    sortArrays(sort, true);
                  }}
                >
                  Sort (Asc.)
                </button>
                <button
                  className="btn"
                  disabled={sort !== "" ? false : true}
                  onClick={() => {
                    sortArrays(sort, false);
                  }}
                >
                  Sort (Desc.)
                </button>
              </div>
            </div>
            <div className="option-in-card">
              <div style={{ paddingRight: "5px" }}>Filter:</div>
              <Select
                placeholder="Select Year"
                onChange={(selectedYear: SingleValue<ReactSelectOption>) =>
                  setYear(selectedYear!.value)
                }
                options={generateYears()}
                defaultValue={{ value: year, label: year }}
                className="basic-multi-select width-min-130"
                classNamePrefix="select"
              />
              <Select
                placeholder="Select Month"
                onChange={(selectedMonth: SingleValue<ReactSelectOption>) =>
                  setMonth(selectedMonth!.value)
                }
                options={generateMonths()}
                defaultValue={generateMonths()[parseInt(month) - 1]}
                className="basic-multi-select width-min-130"
                classNamePrefix="select"
              />
              <div style={{ paddingLeft: "5px" }}>
                <button
                  className="btn"
                  disabled={year !== "" && month !== "" ? false : true}
                  onClick={() => {
                    getData(year, month);
                  }}
                >
                  Filter
                </button>
              </div>
            </div>
            <div className="option-in-card">
              <div style={{ paddingRight: "5px" }}>Filter by Period:</div>
              <div style={{ paddingLeft: "5px" }}>
                <button
                  className="btn"
                  onClick={() => {
                    getData(
                      new Date().getFullYear().toString(),
                      (new Date().getMonth() + 1).toString(),
                    );
                  }}
                >
                  Month-to-Date
                </button>
                <button
                  className="btn"
                  onClick={() => {
                    getData(
                      new Date().getFullYear().toString(),
                      (new Date().getMonth() + 1).toString(),
                      "QTD",
                    );
                  }}
                >
                  Quarter-to-Date
                </button>
                <button
                  className="btn"
                  onClick={() => {
                    getData(
                      new Date().getFullYear().toString(),
                      (new Date().getMonth() + 1).toString(),
                      "YTD",
                    );
                  }}
                >
                  Year-to-Date
                </button>
                <button
                  className="btn"
                  onClick={() => {
                    getData(
                      new Date().getFullYear().toString(),
                      (new Date().getMonth() + 1).toString(),
                      "ALL",
                    );
                  }}
                >
                  All-Time
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {loading && (
          <div className="loading-div">
            <FontAwesomeIcon
              icon={faSpinner}
              size="2x"
              spin={true}
              style={{ margin: "0 auto" }}
            />
          </div>
        )}
        {errorFlag && (
          <div>
            <Error code={errorCode} type={errorType} message={errorMessage} />
          </div>
        )}
        {!errorFlag && !loading && finalMatrix.length === 0 && (
          <div>No data found.</div>
        )}
        {!errorFlag && !loading && finalMatrix.length !== 0 && (
          <div>
            <Plot
              data={[
                {
                  type: "heatmap",
                  z: returnValues("normalized"),
                  x: metrics,
                  y: returnValues("exec"),
                  showscale: false,
                  colorscale: [
                    [0.0, "rgb(255, 115, 110)"],
                    [0.5, "rgb(255, 250, 110)"],
                    [1, "rgb(66, 245, 114)"],
                  ],
                },
              ]}
              layout={{
                ...PlotlyMargins,
                xaxis: {
                  side: "top",
                  showgrid: false,
                },
                legend: { orientation: "h" },
                annotations: generateAnnotations(finalMatrix, metrics),
                height: finalMatrix.length * 50,
                datarevision: garbo,
              }}
              useResizeHandler
              config={{
                responsive: true,
                displayModeBar: false,
                staticPlot: true,
              }}
              className="w-100"
            />
            <div style={{ display: "flex" }}>
              <Plot
                data={[
                  {
                    type: "table",
                    header: {
                      line: { width: 0 },
                      fill: { color: ["rgb(224, 227, 27)"] },
                      font: { family: "Arial", size: 15, color: ["black"] },
                      values: averages,
                    },
                    cells: {},
                  } as any,
                ]}
                layout={{
                  ...PlotlyMargins,
                  title: {
                    text: "<b>Averages</b>",
                  },
                  height: 85,
                }}
                useResizeHandler
                config={{
                  responsive: true,
                  displayModeBar: false,
                  staticPlot: true,
                }}
                className="w-100"
              />
            </div>
            <div style={{ display: "flex" }}>
              <Plot
                data={[
                  {
                    type: "table",
                    header: {
                      line: { width: 0 },
                      fill: { color: ["rgb(224, 227, 27)"] },
                      font: { family: "Arial", size: 15, color: ["black"] },
                      values: extraText,
                    },
                    cells: {},
                  } as any,
                ]}
                layout={{
                  ...PlotlyMargins,
                  title: {
                    text: "<b>Totals</b>",
                  },
                  height: 85,
                }}
                useResizeHandler
                config={{
                  responsive: true,
                  displayModeBar: false,
                  staticPlot: true,
                }}
                className="w-100"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
