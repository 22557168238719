import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import "ag-grid-community/styles/ag-grid.css";
import { OptionsMenu } from "../../../features/OptionsMenu";
import { agGridObject } from "../../../features/ExportToCSV";
import { CustomAgGridTable } from "../../../features/CustomAgGridTable";
import {
  DisplayAsType,
  TSToAgGrid,
  agGridColumnDefType,
  convertOldDisplayAs,
} from "../../../features/TSToAgGrid";
import {
  useLazyTS_CHMWholesaleQTDQuery,
  useLazyTS_CHMWholesaleMTDQuery,
  useLazyTS_CHMAEsPerfSummary2YTDQuery,
  useLazyTS_RetailDashboardQuery,
} from "../../../services/gallus";
import { tsDataObject } from "../../../services/types/gallus";
import ReactResizeDetector from "react-resize-detector";
import { OnResizeCallback } from "react-resize-detector/build/types/types";
import { ButtonGroup } from "../../../features/Interfaces";
import { Error, tryAgainLaterMessage } from "../../../components/Error";

interface FullScreenPerformanceMetricsProps {
  scope: "Wholesale" | "Retail";
  overrideTitle?: string;
}

export const FullScreenPerformanceMetrics = (
  props: FullScreenPerformanceMetricsProps,
) => {
  const [CHMPerfSummary, setCHMPerfSummary] = React.useState<tsDataObject>({
    columnNames: [],
    data: [],
    columnDisplayName: [],
    columnGroupName: [],
    columnShow: [],
    columnFormatting: [],
    columnHeaderClasses: [],
    tableTitle: "",
  });
  const [loading, setLoading] = React.useState<boolean>(true);
  const [csvData, setCSVData] = React.useState<agGridObject<string>[]>([]);
  const [month, setMonth] = React.useState<string>(
    (new Date().getMonth() + 1).toString(),
  );
  const [year, setYear] = React.useState<string>(
    new Date().getFullYear().toString(),
  );

  const [errorFlag, setErrorFlag] = React.useState<boolean>(false);
  const [errorCode, setErrorCode] = React.useState<string>("");
  const [errorMessage, setErrorMessage] = React.useState<string>("");
  const [errorType, setErrorType] = React.useState<string>("");

  const [TS_CHMWholesaleMTDQuery, TS_CHMWholesaleMTDQueryResults] =
    useLazyTS_CHMWholesaleMTDQuery();
  const [TS_CHMWholesaleQTDQuery, TS_CHMWholesaleQTDQueryResults] =
    useLazyTS_CHMWholesaleQTDQuery();
  const [TS_CHMWholesaleYTDQuery, TS_CHMWholesaleYTDQueryResults] =
    useLazyTS_CHMAEsPerfSummary2YTDQuery();
  const [TS_RetailDashboard, TS_RetailDashboardResults] =
    useLazyTS_RetailDashboardQuery();

  useEffect(() => {
    setLoading(true);
    const getDataWrapper = async () => {
      getData(
        new Date().getFullYear().toString(),
        (new Date().getMonth() + 1).toString(),
        "YTD",
      );
    };
    console.log("Changed scope");
    console.log("Entering new getData");
    getDataWrapper();
  }, [props.scope]);

  const getData = async (
    year: string,
    month: string,
    period: "MTD" | "QTD" | "YTD",
  ) => {
    setLoading(true);
    console.log("Deleting previous CSV file");
    setCSVData([]);
    if (props.scope === "Wholesale") {
      switch (period) {
        case "MTD":
          TS_CHMWholesaleMTDQuery({ month: month, year: year });
          break;
        case "QTD":
          TS_CHMWholesaleQTDQuery();
          break;
        case "YTD":
          TS_CHMWholesaleYTDQuery();
          break;
      }
    } else {
      if (period === "MTD") {
        TS_RetailDashboard({
          timePeriod: period,
          date: { month: month, year: year },
        });
      } else {
        TS_RetailDashboard({ timePeriod: period });
      }
    }

    console.log("Requested New Data");
  };

  useEffect(() => {
    if (
      TS_CHMWholesaleMTDQueryResults.isSuccess &&
      TS_CHMWholesaleMTDQueryResults.status === "fulfilled"
    ) {
      if (TS_CHMWholesaleMTDQueryResults.data === CHMPerfSummary) {
        if (CHMPerfSummary.data.length > 0) {
          generateWSTable();
        }
        setLoading(false);
      } else {
        setCHMPerfSummary(TS_CHMWholesaleMTDQueryResults.data);
        console.log("Set State setCHMPerfSummary, Wholesale, MTD");
      }
      setErrorFlag(false);
    } else if (TS_CHMWholesaleMTDQueryResults.isError) {
      setErrorFlag(true);
      setLoading(false);
      setErrorCode("500");
      setErrorMessage(tryAgainLaterMessage);
      setErrorType("InternalAPI");
    }
  }, [TS_CHMWholesaleMTDQueryResults]);

  useEffect(() => {
    if (
      TS_CHMWholesaleQTDQueryResults.isSuccess &&
      TS_CHMWholesaleQTDQueryResults.status === "fulfilled"
    ) {
      if (TS_CHMWholesaleQTDQueryResults.data === CHMPerfSummary) {
        if (CHMPerfSummary.data.length > 0) {
          generateWSTable();
        }
        setLoading(false);
      } else {
        setCHMPerfSummary(TS_CHMWholesaleQTDQueryResults.data);
        console.log("Set State setCHMPerfSummary, Wholesale, QTD");
      }
      setErrorFlag(false);
    } else if (TS_CHMWholesaleQTDQueryResults.isError) {
      setErrorFlag(true);
      setLoading(false);
      setErrorCode("500");
      setErrorMessage(tryAgainLaterMessage);
      setErrorType("InternalAPI");
    }
  }, [TS_CHMWholesaleQTDQueryResults]);

  useEffect(() => {
    if (
      TS_CHMWholesaleYTDQueryResults.isSuccess &&
      TS_CHMWholesaleYTDQueryResults.status === "fulfilled"
    ) {
      if (TS_CHMWholesaleYTDQueryResults.data === CHMPerfSummary) {
        if (CHMPerfSummary.data.length > 0) {
          generateWSTable();
        }
        setLoading(false);
      } else {
        setCHMPerfSummary(TS_CHMWholesaleYTDQueryResults.data);
        console.log("Set State setCHMPerfSummary, Wholesale, YTD");
      }
      setErrorFlag(false);
    } else if (TS_CHMWholesaleYTDQueryResults.isError) {
      setErrorFlag(true);
      setLoading(false);
      setErrorCode("500");
      setErrorMessage(tryAgainLaterMessage);
      setErrorType("InternalAPI");
    }
  }, [TS_CHMWholesaleYTDQueryResults]);

  useEffect(() => {
    if (
      TS_RetailDashboardResults.isSuccess &&
      TS_RetailDashboardResults.status === "fulfilled"
    ) {
      if (TS_RetailDashboardResults.data === CHMPerfSummary) {
        if (CHMPerfSummary.data.length > 0) {
          generateWSTable();
        }
        setLoading(false);
      } else {
        setCHMPerfSummary(TS_RetailDashboardResults.data);
        console.log("Set State setCHMPerfSummary, Retail");
      }
      setErrorFlag(false);
    } else if (TS_RetailDashboardResults.isError) {
      setErrorFlag(true);
      setLoading(false);
      setErrorCode("500");
      setErrorMessage(tryAgainLaterMessage);
      setErrorType("InternalAPI");
    }
  }, [TS_RetailDashboardResults]);

  useEffect(() => {
    if (CHMPerfSummary.data.length > 0) {
      generateWSTable();
    }
    setLoading(false);
  }, [CHMPerfSummary]);

  const handleResize: OnResizeCallback = (width, height) => {
    console.log(width, height);
  };

  const [title, setTitle] = React.useState<string>("Error");
  const [columnDefinitions, setColumnDefinitions] = React.useState<any>();
  const [rows, setRows] = React.useState<any>();
  const [cellStyle, setCellStyle] = React.useState<any>();
  const [identifier, setIdentifier] = React.useState<any>();

  const generateWSTable = () => {
    const regularColumnWidth = 100;
    let columnDefinitions: agGridColumnDefType[] = [];
    let dataForRows: any[] = [];
    let dataForRowsNoFormatting: any[] = [];
    let title: string = "Error";
    let identifier: string = "Account Executive";
    let cellStyle: { field: string; cellStyle: any } = {
      field: "",
      cellStyle: {},
    };
    if (CHMPerfSummary.data.length !== 0) {
      title = "";
      //let DisplayAs: DisplayAsType = ["string", "%1d", "string", "currency", "string", "currency", "string", "currency", "%1d", "%1d", "%1d", "%1d", "%1d", "%1d", "%1d", "%1d", "%1d", "%1d", "n0d", "%1d", "%1d", "%1d", "%1d", "%1d", "%1d", "%1d"];
      let DisplayAs: DisplayAsType = convertOldDisplayAs(
        [
          "string",
          "%1d",
          "string",
          "currency",
          "string",
          "currency",
          "string",
          "currency",
          "%1d",
          "%1d",
          "%1d",
          "%1d",
          "%1d",
          "%1d",
          "%1d",
          "%1d",
          "%1d",
          "%1d",
          "n0d",
          "%1d",
          "%1d",
          "%1d",
          "%1d",
          "%1d",
          "%1d",
          "%1d",
        ],
        CHMPerfSummary.columnNames,
      );
      var individualTitle: string = "";

      if (props.scope === "Retail") {
        individualTitle = "Loan Officer";
      } else {
        individualTitle = "Account Executive";
      }

      let sortByColumnName = "Score";
      let result = TSToAgGrid(CHMPerfSummary, false, sortByColumnName, true, [
        individualTitle,
        "Score",
      ]);

      let maxValueOfSortBy = result.rows[0].notFormatted[sortByColumnName];
      let minValueOfSortBy =
        result.rows[result.rows.length - 1].notFormatted[sortByColumnName];
      let rowsWithFormat: any[] = [];
      let rowsWithoutFormat: any[] = [];

      result.rows.forEach((element) => {
        rowsWithFormat.push(element.formatted);
        rowsWithoutFormat.push(element.notFormatted);
      });

      columnDefinitions = result.columns;
      dataForRowsNoFormatting = rowsWithoutFormat;
      dataForRows = rowsWithFormat;
      cellStyle = {
        field: sortByColumnName,
        cellStyle: (params: any) => {
          if (params.colDef.field === sortByColumnName) {
            const valueAsFloat =
              params.value !== null ? parseFloat(params.value) / 100 : 0;
            const weight =
              (valueAsFloat - minValueOfSortBy) /
              (maxValueOfSortBy - minValueOfSortBy);
            let red = 0;
            let green = 0;
            let blue = 0;

            const weightMoreThan50 = (weight - 0.5) / (1 - 0.5);
            const weightLessThan50 = (weight - 0) / (0.5 - 0);

            if (weight >= 0.5) {
              red = weightMoreThan50 * 66 + (1 - weightMoreThan50) * 255;
              green = weightMoreThan50 * 245 + (1 - weightMoreThan50) * 250;
              blue = weightMoreThan50 * 114 + (1 - weightMoreThan50) * 110;
            } else {
              red = weightLessThan50 * 255 + (1 - weightLessThan50) * 255;
              green = weightLessThan50 * 250 + (1 - weightLessThan50) * 115;
              blue = weightLessThan50 * 110 + (1 - weightLessThan50) * 110;
            }
            return {
              backgroundColor: `rgb(${red}, ${green}, ${blue})`,
              textAlign: "center",
            };
          } else {
            return { textAlign: "center" };
          }
        },
      };
    }

    setCSVData([
      {
        title: title,
        columnDefs: columnDefinitions,
        rowDefs: dataForRowsNoFormatting,
      },
    ]);
    setTitle(title);
    setColumnDefinitions(columnDefinitions);
    setRows(dataForRows);
    setCellStyle(cellStyle);
    setIdentifier(identifier);
  };

  const generateButtonGroups = (highlightId?: string) => {
    highlightId = highlightId ?? "BA0-2";
    return [
      {
        title: "Filter by Period:",
        buttons: [
          {
            name: "Month-to-Date",
            onClick: () => {
              getData(
                new Date().getFullYear().toString(),
                (new Date().getMonth() + 1).toString(),
                "MTD",
              );
            },
            highlighted: highlightId == "BA0-0",
          },
          {
            name: "Quarter-to-Date",
            onClick: () => {
              getData(
                new Date().getFullYear().toString(),
                (new Date().getMonth() + 1).toString(),
                "QTD",
              );
            },
            highlighted: highlightId == "BA0-1",
          },
          {
            name: "Year-to-Date",
            onClick: () => {
              getData(
                new Date().getFullYear().toString(),
                (new Date().getMonth() + 1).toString(),
                "YTD",
              );
            },
            highlighted: highlightId == "BA0-2",
          },
        ],
      },
    ];
  };

  const [buttonGroups, setButtonGroups] = React.useState<ButtonGroup[]>(
    generateButtonGroups(),
  );

  const handleButtonClicked = (id: string) => {
    setButtonGroups(generateButtonGroups(id));
  };

  return (
    <div
      className="module-master"
      style={{
        padding: "10px 10px 0 20px",
        height: "100%",
        overflow: "hidden",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <div className="module-title">
          {typeof props.overrideTitle !== "undefined"
            ? props.overrideTitle
            : "Wholesale Performance Scoreboard"}
        </div>
      </div>
      <OptionsMenu
        exportToCSV={{
          filename: `${typeof props.overrideTitle !== "undefined" ? props.overrideTitle : "Wholesale Performance Scoreboard"}`,
          CSVData: csvData,
        }}
        periodSelector={{
          setMonthFunction: setMonth,
          upToCurrentMonth: true,
          setYearFunction: setYear,
          startingYear: new Date().getFullYear(),
          buttonText: "Filter",
          onClickFunctionality: () => {
            getData(new Date().getFullYear().toString(), month, "MTD");
          },
          disableCondition: () => (year !== "" && month !== "" ? false : true),
          blockYear: true,
          hideYear: true,
          setAsDefault: false,
        }}
        buttonGroups={buttonGroups}
        onButtonClicked={handleButtonClicked}
      />
      <>
        {loading && (
          <div className="loading-div">
            <FontAwesomeIcon
              icon={faSpinner}
              size="2x"
              spin={true}
              style={{ margin: "0 auto" }}
            />
          </div>
        )}
        {!loading && !errorFlag && CHMPerfSummary.data.length > 0 && (
          <ReactResizeDetector>
            {cellStyle && (
              <CustomAgGridTable
                title={title}
                tableData={{
                  identifier: identifier,
                  rows: rows,
                  columns: columnDefinitions,
                }}
                customCellStyle={cellStyle}
                containerStyle={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  overflow: "hidden",
                }}
                domLayout="normal"
              />
            )}
          </ReactResizeDetector>
        )}
        {!loading && !errorFlag && !(CHMPerfSummary.data.length > 0) && (
          <>No data available.</>
        )}
        {errorFlag && (
          <div>
            <Error code={errorCode} type={errorType} message={errorMessage} />
          </div>
        )}
      </>
    </div>
  );
};
