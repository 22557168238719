import Plotly from "plotly.js";

export const generateTickVals = (vals: number[], step?: number): number[] => {
  step = step ?? 500.0;
  let max = Math.floor(Math.max(...vals) / step) * step + step;
  let min = Math.ceil(Math.min(...vals) / step) * step - step;
  let result = [];
  for (let i = min; i <= max; i += step) {
    result.push(i);
  }
  return result;
};

export const plotlyToImage = async (
  plotlyRef: any,
  height: number,
  width: number,
) => {
  // Ensure that the plot is instantiated
  if (plotlyRef) {
    let dataUrl = await Plotly.toImage(plotlyRef.current.el, {
      format: "png",
      width: width,
      height: height,
      scale: 2,
    });
    return dataUrl;
  }
};

export const plotlyDownloadImage = async (
  plotlyRef: any,
  filename?: string,
) => {
  // Ensure that the plot is instantiated
  if (plotlyRef) {
    Plotly.downloadImage(plotlyRef.current.el, {
      format: "png",
      filename: filename ?? "export",
      width: 1000,
      height: 500,
    });
  }
};
