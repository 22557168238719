import Bugsnag from "@bugsnag/js";
import "./Error.scss";
import { getUserSessionItem } from "..";
import { useAppSelector } from "../app/hooks";
import {
  CognitoJwtPayload,
  selectUserAuthContext,
} from "../features/console/consoleSlice";
import { CognitoOidcConfig } from "../CognitoOidcConfig";

interface ErrorProps {
  code: string;
  type: string;
  message: string;
  error?: any;
}

export const tryAgainLaterMessage: string = "Please try again later.";

export const Error = (props: ErrorProps) => {
  const userAuthContext = useAppSelector(selectUserAuthContext);
  Bugsnag.notify("Error component reached", function (event) {
    event.severity = "error";
    event.context = "Error component reached";
    event.setUser(
      userAuthContext?.username ??
        getUserSessionItem("userName") ??
        "No Username",
    );
    event.addMetadata("Params", {
      errorRaw: JSON.stringify(props.error ?? {}),
      url: window.location,
      errorType: props.type,
      errorCode: props.code,
      errorMessage: props.message,
      grant_type: "authorization_code",
      client_id: CognitoOidcConfig.clientId,
      redirect_uri: CognitoOidcConfig.callbackUri,
      client_secret: CognitoOidcConfig.clientSecret ?? "No client secret",
      SAToken: getUserSessionItem("SAToken"),
      accessToken: getUserSessionItem("accessToken"),
      idToken: getUserSessionItem("idToken"),
      userObject: getUserSessionItem("user"),
    });
  });
  return (
    <div>
      <div className="error-title">Something went wrong</div>
      <div className="error-text">
        The following error has been detected. Please try again later.
      </div>
      <div className="error-specifics">
        {props.type}-{props.code}: {props.message}
      </div>
      <div className="error-text">
        If the error persists, please contact us at{" "}
        <a href="mailto:info@gallusinsights.com">info@gallusinsights.com</a>
      </div>
    </div>
  );
};
