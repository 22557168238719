import jsPDF from "jspdf";
import { plotlyToImage } from "../helpers/plotly";
import * as htmlToImage from "html-to-image";

/**
 * Component used to save the page as PDF. jsPDF is a NIGHTMARE to work with, so check API documentation to understand how it works.
 * @param title The title of the document.
 * @param textObject A reference to a Plotly graph that contains text information. It will be displayed in the first page.
 * @param chartsRef The charts themselves.
 * @param tiled The way the charts will be displayed in the PDF. True will shrink all charts and save them as tiles of roughly half the width of the page, False displays the charts using the full width of the page
 */

var docHeight = 1122; //1122 and 793 are the dimensions for standard A4 pages.
var docWidth = 793;
const locale = "en";
const today = new Date();
const dayTimeStamp = today.toLocaleDateString(locale, { weekday: "long" });
const dateTimeStamp = `${dayTimeStamp}, ${today.getDate()} ${today.toLocaleDateString(locale, { month: "long" })}`;
const timeTimeStamp = today.toLocaleTimeString(locale, {
  hour: "numeric",
  hour12: true,
  minute: "numeric",
});
const timeStamp = dateTimeStamp + ", " + timeTimeStamp;

export async function exportPlotlyToPdf(
  title: string,
  chartsRef: any[],
  tiled: boolean,
  textObject?: any,
) {
  const doc = new jsPDF("p", "px", [docHeight, docWidth], true);
  await createPdfForPlotly(doc, title, chartsRef, tiled, textObject);
  let month = today.getUTCMonth() + 1; //months from 1-12
  let day = today.getUTCDate();
  let year = today.getUTCFullYear();
  doc.save(
    `Gallus_Report_${month + "_" + day + "_" + year}_${timeTimeStamp.replace(/ /g, "_")}.pdf`,
  );
}

async function exportHTMLToPdf(
  title: string,
  subtitle: string,
  landscape: boolean,
  reasonableFit: boolean,
) {
  const elements = document.getElementsByClassName("save-to-pdf");
  if (!reasonableFit) {
    var widthOfElements = 0;
    for (let i = 0; i < elements.length; i++) {
      const el = elements.item(i) as HTMLElement;
      widthOfElements +=
        el.offsetWidth + (landscape ? docHeight : docWidth) * 0.1;
    }
    docHeight = Math.max(docHeight, widthOfElements);
  }
  const doc = new jsPDF(
    landscape ? "l" : "p",
    "px",
    [docHeight, docWidth],
    true,
  );
  await createPdfForHTML(doc, title, subtitle, elements, landscape);
  let month = today.getUTCMonth() + 1; //months from 1-12
  let day = today.getUTCDate();
  let year = today.getUTCFullYear();
  doc.save(
    `Gallus_Report_${month + "_" + day + "_" + year}_${timeTimeStamp.replace(/ /g, "_")}.pdf`,
  );
  return false;
}

export function exportHTMLToPdfButton(
  title: string,
  subtitle: string,
  landscape: boolean,
  setProgressFlagFunction: (value: boolean) => void,
  reasonableFit: boolean,
  disableCondition?: () => boolean,
) {
  return (
    <div className="option-in-card">
      <div style={{ paddingLeft: "5px" }}>
        <button
          className="btn"
          disabled={
            typeof disableCondition === "function" ? disableCondition() : false
          }
          onClick={async () => {
            setProgressFlagFunction(true);
            setProgressFlagFunction(
              await exportHTMLToPdf(title, subtitle, landscape, reasonableFit),
            );
          }}
        >
          Save To PDF
        </button>
      </div>
    </div>
  );
}

const toDataURL = (url: string) =>
  fetch(url)
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        }),
    );

async function createPdfForPlotly(
  doc: jsPDF,
  title: string,
  chartsRef: any[],
  tiled: boolean,
  textObject?: any,
) {
  //Setting parameters up
  const marginLeft = docWidth * 0.05;
  const marginTop = docHeight * 0.05;
  const centerWidth = docWidth / 2;
  const smallVerticalPadding = docHeight * 0.01;
  const smallHorizontalPadding = docWidth * 0.02;
  const borderSize = 2;
  const gallusFontSize = docHeight * 0.05;
  const titleFontSize = docHeight * 0.04;
  const timestampFontSize = docHeight * 0.03;
  const tinyText = 15;

  let top = marginTop;
  let left = marginLeft;
  let logoSize = docWidth * 0.5;
  let logoPadding = docHeight * 0.2;
  let logoPositionX = (docWidth - logoSize) / 2;
  let logoPositionY = (docHeight - logoSize) / 2 - logoPadding;

  doc.setFillColor("000000");
  //

  //Adding Gallus Logo
  try {
    let logo = (await toDataURL("/black-icon.png")) as string;
    doc.addImage(logo, "png", logoPositionX, logoPositionY, logoSize, logoSize);
  } catch (e) {}
  logoPositionY += logoSize + smallVerticalPadding;

  doc.setFontSize(gallusFontSize);
  doc.text("Gallus", centerWidth, logoPositionY, { align: "center" }); //Gallus logo text
  //

  //Adding Title text
  doc.setFontSize(titleFontSize);
  logoPositionY = docHeight - docHeight * 0.1;
  doc.text(title, centerWidth, logoPositionY, { align: "center" }); //PDF title
  logoPositionY += gallusFontSize * 0.6;
  doc.setFontSize(timestampFontSize);
  doc.text(timeStamp, centerWidth, logoPositionY, { align: "center" }); //Timestamp

  top = marginTop;
  doc.addPage();
  //

  //Adding Parameters
  if (typeof textObject !== "undefined") {
    let heightTextObject = (docHeight - smallVerticalPadding) * 0.4;
    let widthTextObject = docWidth - 2 * marginLeft - smallHorizontalPadding;
    let imgData = await plotlyToImage(
      textObject,
      heightTextObject,
      widthTextObject,
    );
    doc.addImage(
      imgData!,
      "PNG",
      left,
      top,
      widthTextObject,
      heightTextObject,
      `textObject`,
    );
    doc.addPage();
  }
  top = marginTop;
  //
  doc.setFontSize(tinyText);
  doc.text(
    `Source: Gallus Insights`,
    centerWidth,
    docHeight - marginTop - tinyText,
    { align: "center" },
  ); //Source
  doc.text(
    `©${today.getUTCFullYear()} by Gallus Insights.`,
    centerWidth,
    docHeight - marginTop,
    { align: "center" },
  ); //Source
  //Adding charts

  if (tiled) {
    for (let i = 0; i < chartsRef.length; i++) {
      let height = (docWidth - smallHorizontalPadding) / 2;
      let width = (docWidth - 2 * marginLeft - smallHorizontalPadding) / 2;
      let imgData = await plotlyToImage(chartsRef[i], height, width);

      if (i % 2 === 0) {
        left = marginLeft;
      } else {
        left = docWidth - marginLeft - width;
      }

      //Add top border to images
      doc.rect(
        left - borderSize,
        top - borderSize,
        width + borderSize * 2,
        borderSize,
        "F",
      );
      //

      doc.addImage(imgData!, "PNG", left, top, width, height, `image${i}`);

      if (i % 2 !== 0) {
        top = top + height + smallVerticalPadding;
      }

      if (
        top + (docWidth - smallVerticalPadding) / 2 > docHeight &&
        i < chartsRef.length - 1
      ) {
        doc.addPage();
        top = marginTop;
      }
    }
  } else {
    for (let i = 0; i < chartsRef.length; i++) {
      let height = (docHeight - smallVerticalPadding) * 0.4;
      let width = docWidth - 2 * marginLeft - smallHorizontalPadding;
      let imgData = await plotlyToImage(chartsRef[i], height, width);

      //Add top border to images
      doc.rect(
        left - borderSize,
        top - borderSize,
        width + borderSize * 2,
        borderSize,
        "F",
      );
      //

      doc.addImage(imgData!, "PNG", left, top, width, height, `image${i}`);

      top = top + height + smallVerticalPadding;

      if (top + height > docHeight && i < chartsRef.length - 1) {
        doc.addPage();
        top = marginTop;
      }
    }
  }
  //
}

async function createPdfForHTML(
  doc: jsPDF,
  title: string,
  subtitle: string,
  HTMLElements: HTMLCollectionOf<Element>,
  landscape: boolean,
) {
  //Setting parameters up

  var pageWidth = landscape ? docHeight : docWidth;
  var pageHeight = landscape ? docWidth : docHeight;

  const marginLeft = pageWidth * 0.05;
  const marginTop = pageHeight * 0.05;
  const centerWidth = pageWidth / 2;
  const smallVerticalPadding = pageHeight * 0.01;
  const smallHorizontalPadding = pageWidth * 0.01;
  const gallusFontSize = pageHeight * 0.05;
  const titleFontSize = pageHeight * 0.04;
  const subtitleFontSize = pageHeight * 0.03;
  const timestampFontSize = pageHeight * 0.02;
  const tinyText = 15;

  let top = marginTop;
  let left = marginLeft;
  let logoSize = Math.min(pageHeight, pageWidth) * 0.5;
  let logoPadding = Math.min(pageHeight, pageWidth) * 0.2;
  let logoPositionX = (pageWidth - logoSize) / 2;
  let logoPositionY = (pageHeight - logoSize) / 2 - logoPadding;

  doc.setFillColor("000000");
  //

  //Adding Gallus Logo
  try {
    let logo = (await toDataURL("/black-icon.png")) as string;
    doc.addImage(logo, "png", logoPositionX, logoPositionY, logoSize, logoSize);
  } catch (e) {}
  logoPositionY += logoSize + smallVerticalPadding;

  doc.setFontSize(gallusFontSize);
  doc.text("Gallus", centerWidth, logoPositionY, { align: "center" }); //Gallus logo text
  //

  //Adding Title and Subtitle text
  doc.setFontSize(titleFontSize);
  logoPositionY = pageHeight - pageHeight * 0.1;
  doc.text(title, centerWidth, logoPositionY, { align: "center" }); //PDF title
  logoPositionY += gallusFontSize * 0.6;
  doc.setFontSize(subtitleFontSize);
  doc.text(subtitle, centerWidth, logoPositionY, { align: "center" }); //PDF subttitle
  logoPositionY += subtitleFontSize * 0.6;
  doc.setFontSize(timestampFontSize);
  doc.text(timeStamp, centerWidth, logoPositionY, { align: "center" }); //Timestamp
  top = marginTop;
  doc.addPage();
  var counter: number = 0;

  //Adding charts

  for (let i = 0; i < HTMLElements.length; i++) {
    const el = HTMLElements.item(i) as HTMLElement;
    const imgData = await htmlToImage.toPng(el);

    var proportions = el.offsetHeight / el.offsetWidth;
    var elHeight = pageHeight - 2 * marginTop - 2 * tinyText;
    var elWidth = elHeight / proportions;

    if (left + elWidth > pageWidth) {
      top += elHeight + marginTop;
    }

    if (top + elHeight > pageHeight) {
      doc.addPage();
      top = marginTop;
      left = marginLeft;
      counter = 0;
    }

    doc.addImage(imgData, "PNG", left, top, elWidth, elHeight, `image${i}`); //(doc.internal.pageSize.getWidth()-elWidth)/2 centers the image
    doc.setFontSize(tinyText);
    doc.text(
      `Source: Gallus Insights`,
      centerWidth,
      pageHeight - marginTop - tinyText,
      { align: "center" },
    ); //Source
    doc.text(
      `©${today.getUTCFullYear()} by Gallus Insights.`,
      centerWidth,
      pageHeight - marginTop,
      { align: "center" },
    ); //Source
    left += elWidth + smallHorizontalPadding;
    counter += 1;
  }
  //
}
