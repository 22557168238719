import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import { Amplify, API, Auth, Hub } from "aws-amplify";
import awsExports from "./aws-exports";
// import TsLiveboardEmbedCookieChecker from './TsLiveboardEmbedCookieChecker';
// import TsSearchEmbedCookieChecker from './TsSearchEmbedCookieCheker';
import {
  AuthType,
  EmbedConfig,
  init,
  prefetch,
} from "@thoughtspot/visual-embed-sdk";
import {
  CognitoJwtPayload,
  updateUserAuthContext,
} from "./features/console/consoleSlice";
import {
  CognitoUserPool,
  CognitoUserSession,
} from "amazon-cognito-identity-js";
import { environmentConfig } from "./environment";
import { initStore } from "./app/store";
import { CognitoOidcConfig } from "./CognitoOidcConfig";
import Bugsnag from "@bugsnag/js";
import axios from "axios";
import { Helmet } from "react-helmet";

Amplify.configure(awsExports);
API.configure(awsExports);
//Bugsnag.start("28fb8749ecf92db91bc3b0027474ab46");
Bugsnag.start({
  apiKey: "28fb8749ecf92db91bc3b0027474ab46",
  enabledBreadcrumbTypes: [],
});

const amplifyEndpoint = (API as any)._restApi._options.endpoints[0];
Amplify.configure({
  aws_cloud_logic_custom: [
    {
      ...amplifyEndpoint,
      endpoint:
        environmentConfig.amplifyApiEndpoint || amplifyEndpoint.endpoint,
    },
  ],
});

// prefetch(tsHost);
export const baseThoughtSpotConfig: EmbedConfig = {
  authType: AuthType.OIDCRedirect,
  thoughtSpotHost: environmentConfig.thoughtspotHost,
  disableLoginRedirect: true,
  autoLogin: true,
  loginFailedMessage: "Redirect to login page...",
  detectCookieAccessSlow: false,
  suppressNoCookieAccessAlert: true,
  customizations: {
    style: {
      customCSS: {
        rules_UNSTABLE: {
          ".bk-footer-logo-image": {
            display: "none",
          },
        },
      },
    },
  },
};

export const refreshToken = async () => {
  try {
    if (environmentConfig.useCognitoOidcLogin) {
      const userPool = new CognitoUserPool({
        UserPoolId: CognitoOidcConfig.userPoolId,
        ClientId: CognitoOidcConfig.clientId,
      });
      const cognitoUser = userPool.getCurrentUser();
      if (cognitoUser) {
        cognitoUser.getSession(
          (err: Error | null, userSession: CognitoUserSession) => {
            if (userSession) {
              updateUserSessionByCurrentSession(userSession);
            }
          },
        );
      }
    } else {
      const tokenBefore = getUserSessionItem("accessToken");
      const session = await Auth.currentSession();
      if (tokenBefore !== session.getAccessToken().getJwtToken()) {
        console.log("access token refreshed");
      }
      if (session.isValid()) {
        updateUserSessionByCurrentSession(session);
      }
    }
  } catch (e: any) {
    return e;
  }
};

export const updateUserSessionByCurrentSession = (
  session: CognitoUserSession,
) => {
  updateUserSession({
    username:
      session.getIdToken().payload["preferred_username"] ??
      session.getIdToken().payload["cognito:username"] ??
      "",
    idTokenPayload: session.getIdToken().payload,
    idToken: session.getIdToken().getJwtToken(),
    accessToken: session.getAccessToken().getJwtToken(),
    refreshToken: session.getRefreshToken().getToken(),
  });
};

export const updateUserSession = (payload: {
  idTokenPayload: any;
  username: any;
  idToken: string;
  accessToken: string;
  refreshToken: string;
}) => {
  sessionStorage.setItem("user", JSON.stringify(payload.idTokenPayload));
  sessionStorage.setItem(
    "userName",
    payload.idTokenPayload["preferred_username"] ??
      payload.idTokenPayload["cognito:username"] ??
      "",
  );
  sessionStorage.setItem("idToken", payload.idToken);
  sessionStorage.setItem("accessToken", payload.accessToken);
  sessionStorage.setItem("refreshToken", payload.refreshToken);
  // localStorage.setItem("user", JSON.stringify(payload.idTokenPayload));
  // localStorage.setItem("userName", payload.username);
  // localStorage.setItem("idToken", payload.idToken);
  // localStorage.setItem("accessToken", payload.accessToken);
  // localStorage.setItem("refreshToken", payload.refreshToken);
};

export const getUserSessionItem = (
  key:
    | "user"
    | "userName"
    | "idToken"
    | "accessToken"
    | "refreshToken"
    | "SAToken",
) => {
  return sessionStorage.getItem(key) ?? localStorage.getItem(key);
};

export const clearUserSession = () => {
  sessionStorage.removeItem("user");
  sessionStorage.removeItem("userName");
  sessionStorage.removeItem("idToken");
  sessionStorage.removeItem("accessToken");
  sessionStorage.removeItem("refreshToken");
  sessionStorage.removeItem("SAToken");
  // localStorage.removeItem("user");
  // localStorage.removeItem("userName");
  // localStorage.removeItem("idToken");
  // localStorage.removeItem("accessToken");
  // localStorage.removeItem("refreshToken");
};

export const updateSAToken = (
  SAToken: string,
  method: "CheckIn" | "Refresh",
) => {
  sessionStorage.setItem("SAToken", SAToken);
  console.log("Updated Gallus Token via " + method);
  return { SAToken: SAToken };
};

const getThoughtSpotAuthToken = async (username: string, orgId: Number) => {
  try {
    // const idToken = getUserSessionItem('idToken');
    // let jwtValidationResult: { isValid: boolean; payload: any } = await API.post("ServerlessAPI", "/jwt/", {
    //   body: {
    //       clientId: CognitoOidcConfig.clientId,
    //       idToken: idToken,
    //   },
    // });
    // console.log(jwtValidationResult);
    // if (!jwtValidationResult.isValid) {
    //   console.error("Current access token is invalid");
    // }
    const refreshTokenError = await refreshToken();
    if (refreshTokenError) {
      throw refreshTokenError;
    }
    const accessToken = getUserSessionItem("accessToken");
    console.log(`Get TS auth token for app embed: ${username};${orgId}`);
    const data = await API.post("ServerlessAPI", "/thoughtspot/token", {
      body: {
        username,
        orgId,
        accessToken,
        clientId: CognitoOidcConfig.clientId,
      },
    });
    //throw new Error();
    return data.token as string;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const gallusAmazonApi = axios.create({
  baseURL: "https://gallus-api-v3.gallusanalytics.com/",
});

const getThoughtSpotAuthTokenFallback = async (
  username: string,
  orgId: Number,
) => {
  try {
    const refreshTokenError = await refreshToken();
    if (refreshTokenError) {
      throw refreshTokenError;
    }
    const accessToken = getUserSessionItem("accessToken");
    console.log(`Get TS auth token for app embed: ${username};${orgId}`);
    const data = await gallusAmazonApi.post<any>(`thoughtspot/token`, {
      username,
      orgId,
      accessToken,
      clientId: CognitoOidcConfig.clientId,
    });

    //const data = await lazyGetTSAccessTokenFallbackQueryQuery({ orgID: orgId }).unwrap()

    return data.data.token as string;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const initTs = async (
  authType: "OIDC" | "TOKEN" = "OIDC",
  username: string | null = null,
  orgId: Number = 0,
) => {
  console.log("init");
  let tsAuthConfig = {};
  if (authType === "TOKEN") {
    if (!username) {
      throw new Error("Username is required with the authType of TOKEN");
    }
    tsAuthConfig = {
      authType: AuthType.TrustedAuthTokenCookieless,
      username: username,
      disableLoginRedirect: true,
      autoLogin: true,
      getAuthToken: async () => {
        let retry = 1;
        let error = null;
        while (retry <= 3) {
          try {
            return await getThoughtSpotAuthToken(username, orgId);
          } catch (e) {
            error = e;
            retry++;
          }
        }
        try {
          console.log("Got error");
          return await getThoughtSpotAuthTokenFallback(username, orgId);
        } catch {
          throw error;
        }
      },
    } as EmbedConfig;
  }
  let finalThoughtSpotConfig = {
    ...baseThoughtSpotConfig,
    ...tsAuthConfig,
  };
  //console.log(finalThoughtSpotConfig);
  init(finalThoughtSpotConfig);
};

const container = document.getElementById("root")!;
const root = createRoot(container);
export const store = initStore({});

function listenToAutoSignInEvent() {
  console.log("hub is listening");
  Hub.listen("auth", async ({ payload }) => {
    const { event } = payload;
    if (event === "autoSignIn") {
      const user = payload.data;
      // assign user
      let session = await Auth.userSession(user);
      updateUserSessionByCurrentSession(session);
      store.dispatch(
        updateUserAuthContext({
          idTokenPayload: session.getIdToken().payload as CognitoJwtPayload,
          idToken: session.getIdToken().getJwtToken(),
          accessToken: session.getAccessToken().getJwtToken(),
          refreshToken: session.getRefreshToken().getToken(),
        }),
      );
    } else if (event === "autoSignIn_failure") {
      // redirect to sign in page
    }
  });
}

listenToAutoSignInEvent();

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    {/* <TsLiveboardEmbedCookieChecker liveboardId="95341b9c-cfb0-496b-a115-78768b8add83" /> */}
    {/* <TsSearchEmbedCookieChecker dataSources={["56d6a2bb-0506-45c3-b376-525d758563fd",]} /> */}
    <Helmet>
      {environmentConfig.useAnalytics && (
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-39CLQTQKKW"
        ></script>
      )}
      {environmentConfig.useAnalytics && (
        <script src="/InitGoogleAnalytics.js"></script>
      )}
      {environmentConfig.useAnalytics && <script src="/InitHotjar.js"></script>}
    </Helmet>
    <App />
  </Provider>,
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
