import React, { useEffect } from "react";
import {
  currencySymbolFormatter,
  displayAsPercentage,
} from "../../../features/PlotlyFormatter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { noDataSymbol } from "../../../features/PlotlyFormatter";
import "ag-grid-community/styles/ag-grid.css";
import { OptionsMenu } from "../../../features/OptionsMenu";
import { agGridObject } from "../../../features/ExportToCSV";
import { ButtonGroup } from "../../../features/Interfaces";
import {
  agGridExtraData,
  CustomAgGridTable,
} from "../../../features/CustomAgGridTable";
import { sortObjectArrayBy } from "../../../features/SortArrayBy";
import {
  agGridColumnDefType,
  columnWidthCalculation,
} from "../../../features/TSToAgGrid";
import { button } from "../../../features/ButtonGroup";
import {
  useLazyCHMAccountExecutives_ViewOrderedByMetricsMonthAndYearQuery,
  useLazyCHMBranches_ViewOrderedByMetricsMonthAndYearQuery,
  useLazyCHMLoanOfficers_ViewOrderedByMetricsMonthAndYearQuery,
  useLazyDemo_ViewOrderedByMetricsMonthAndYearQuery,
  useLazyTS_Delta_ViewQuery,
} from "../../../services/gallus";
import {
  fundingsObject,
  locksObject,
  locksToFundsObject,
  submissionsObject,
} from "../../../services/types/gallus";
import { Error, tryAgainLaterMessage } from "../../../components/Error";

interface TablePerformanceMetricsProps {
  scope: "Account Executive" | "Branches" | "Loan Officers";
  useDemoData?: boolean;
  overrideTitle?: string;
}

type kindsOfTables =
  | "Funding"
  | "Submission"
  | "Lock"
  | "PT% Lock to Fund"
  | "Sub to Fund"
  | "Purchase %"
  | "Community %"
  | "Alt Doc %"
  | "Investor %"
  | "Wholesale Sales";

export const TablePerformanceMetrics = (
  props: TablePerformanceMetricsProps,
) => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [month, setMonth] = React.useState<string>(
    (new Date().getMonth() + 1).toString(),
  );
  const [year, setYear] = React.useState<string>(
    new Date().getFullYear().toString(),
  );
  const [searchPeriod, setSearchPeriod] = React.useState<
    "MTD" | "QTD" | "YTD" | "ALL"
  >("MTD");
  const [savingToPDF, setSavingToPDF] = React.useState<boolean>(false);
  const [showDelta, setShowDelta] = React.useState<boolean>(false);
  //let CSVData: agGridObject<string>[] = [];
  const [csvData, setCSVData] = React.useState<agGridObject<string>[]>([]);

  const [demoQuery, demoQueryResults] =
    useLazyDemo_ViewOrderedByMetricsMonthAndYearQuery();
  const [aeQuery, aeQueryResults] =
    useLazyCHMAccountExecutives_ViewOrderedByMetricsMonthAndYearQuery();
  const [branchesQuery, branchesQueryResults] =
    useLazyCHMBranches_ViewOrderedByMetricsMonthAndYearQuery();
  const [loQuery, loQueryResults] =
    useLazyCHMLoanOfficers_ViewOrderedByMetricsMonthAndYearQuery();
  const [deltaQuery, deltaResults] = useLazyTS_Delta_ViewQuery();

  const [tableData, setTableData] = React.useState<JSX.Element[]>([]);

  const [errorFlag, setErrorFlag] = React.useState<boolean>(false);
  const [errorCode, setErrorCode] = React.useState<string>("");
  const [errorMessage, setErrorMessage] = React.useState<string>("");
  const [errorType, setErrorType] = React.useState<string>("");

  const [sortBy, setSortBy] = React.useState<"Volume" | "Units">("Volume");

  useEffect(() => {
    setLoading(true);
  }, []);

  useEffect(() => {
    const getDataWrapper = () => {
      getData(
        new Date().getFullYear().toString(),
        (new Date().getMonth() + 1).toString(),
      );
    };
    console.log("Changed scope");
    console.log("Entering new getData");
    getDataWrapper();
  }, [props.scope]);

  const getData = (
    year: string,
    month: string,
    period?: "QTD" | "YTD" | "ALL",
    delta?: "MTD" | "QTD" | "YTD",
  ) => {
    setLoading(true);
    console.log("Deleting previous CSV Data");
    setCSVData([]);
    console.log("Deleting previous Table Data");
    setTableData([]);
    if (props.useDemoData) {
      demoQuery({ year: year, month: month, timePeriod: period });
    } else {
      switch (props.scope) {
        case "Account Executive":
          aeQuery({ year: year, month: month, timePeriod: period });
          console.log("Requested New Data");
          if (typeof delta !== "undefined") {
            deltaQuery({ period: delta });
          }
          break;
        case "Branches":
          branchesQuery({ year: year, month: month, timePeriod: period });
          console.log("Requested New Data");
          break;
        case "Loan Officers":
          loQuery({ year: year, month: month, timePeriod: period });
          console.log("Requested New Data");
          break;
      }
    }

    if (typeof period !== "undefined") {
      setSearchPeriod(period);
    } else {
      setSearchPeriod("MTD");
    }
  };

  useEffect(() => {
    if (
      props.useDemoData &&
      demoQueryResults.isSuccess &&
      demoQueryResults.status === "fulfilled"
    ) {
      let fundingReport: fundingsObject[] = [];
      let locksReport: locksObject[] = [];
      let locksToFundsReport: locksToFundsObject[] = [];
      let submissionsReport: submissionsObject[] = [];
      fundingReport = demoQueryResults.data.FundingsReport;
      locksReport = demoQueryResults.data.LocksReport;
      locksToFundsReport = demoQueryResults.data.LocksToFundsReport;
      submissionsReport = demoQueryResults.data.SubmissionsReport;
      console.log("Set demo States");
      if (tableData.length === 0 && csvData.length === 0) {
        generateTable("Submission", 10, 10, false, submissionsReport);
        generateTable("Lock", 10, 10, false, locksReport);
        generateTable("Funding", 10, 10, false, fundingReport);
        generateTable("PT% Lock to Fund", 10, 10, false, locksToFundsReport);
        generateTable("Sub to Fund", 10, 10, false, locksToFundsReport);
        console.log("Generated Tables");
        setErrorFlag(false);
        setLoading(false);
      }
    } else if (demoQueryResults.isError) {
      setErrorFlag(true);
      setLoading(false);
      setErrorCode("500");
      setErrorMessage(tryAgainLaterMessage);
      setErrorType("InternalAPI");
    }
  }, [demoQueryResults]);

  useEffect(() => {
    if (
      props.scope === "Branches" &&
      branchesQueryResults.isSuccess &&
      branchesQueryResults.status === "fulfilled"
    ) {
      let fundingReport: fundingsObject[] = [];
      let locksReport: locksObject[] = [];
      let locksToFundsReport: locksToFundsObject[] = [];
      let submissionsReport: submissionsObject[] = [];
      fundingReport = branchesQueryResults.data.FundingsReport;
      locksReport = branchesQueryResults.data.LocksReport;
      locksToFundsReport = branchesQueryResults.data.LocksToFundsReport;
      submissionsReport = branchesQueryResults.data.SubmissionsReport;
      console.log("Set branches States");

      if (tableData.length === 0 && csvData.length === 0) {
        generateTable("Submission", 10, 10, false, submissionsReport);
        generateTable("Lock", 10, 10, false, locksReport);
        generateTable("Funding", 10, 10, false, fundingReport);
        generateTable("PT% Lock to Fund", 10, 10, false, locksToFundsReport);
        generateTable("Sub to Fund", 10, 10, false, locksToFundsReport);
        console.log("Generated Tables");
        setErrorFlag(false);
        setLoading(false);
      }
    } else if (branchesQueryResults.isError) {
      setErrorFlag(true);
      setLoading(false);
      setErrorCode("500");
      setErrorMessage(tryAgainLaterMessage);
      setErrorType("InternalAPI");
    }
  }, [branchesQueryResults]);

  useEffect(() => {
    if (
      props.scope === "Loan Officers" &&
      loQueryResults.isSuccess &&
      loQueryResults.status === "fulfilled"
    ) {
      let fundingReport: fundingsObject[] = [];
      let locksReport: locksObject[] = [];
      let locksToFundsReport: locksToFundsObject[] = [];
      let submissionsReport: submissionsObject[] = [];
      fundingReport = loQueryResults.data.FundingsReport;
      locksReport = loQueryResults.data.LocksReport;
      locksToFundsReport = loQueryResults.data.LocksToFundsReport;
      submissionsReport = loQueryResults.data.SubmissionsReport;
      console.log("Set LO States");

      if (tableData.length === 0 && csvData.length === 0) {
        generateTable("Submission", 10, 10, false, submissionsReport);
        generateTable("Lock", 10, 10, false, locksReport);
        generateTable("Funding", 10, 10, false, fundingReport);
        generateTable("PT% Lock to Fund", 10, 10, false, locksToFundsReport);
        generateTable("Sub to Fund", 10, 10, false, locksToFundsReport);
        console.log("Generated Tables");
        setErrorFlag(false);
        setLoading(false);
      }
    } else if (loQueryResults.isError) {
      setErrorFlag(true);
      setLoading(false);
      setErrorCode("500");
      setErrorMessage(tryAgainLaterMessage);
      setErrorType("InternalAPI");
    }
  }, [loQueryResults]);

  useEffect(() => {
    if (
      aeQueryResults.isSuccess &&
      aeQueryResults.status === "fulfilled" &&
      !showDelta
    ) {
      if (tableData.length === 0 && csvData.length === 0) {
        generateTable(
          "Submission",
          5,
          5,
          false,
          aeQueryResults.data.SubmissionsReport,
        );
        generateTable("Lock", 5, 5, false, aeQueryResults.data.LocksReport);
        generateTable(
          "Funding",
          5,
          5,
          false,
          aeQueryResults.data.FundingsReport,
        );
        generateTable(
          "PT% Lock to Fund",
          5,
          5,
          false,
          aeQueryResults.data.LocksToFundsReport,
        );
        generateTable(
          "Sub to Fund",
          5,
          5,
          false,
          aeQueryResults.data.LocksToFundsReport,
        );
        console.log("Generated Tables");
        setErrorFlag(false);
        setLoading(false);
      }
    } else if (aeQueryResults.isError) {
      setErrorFlag(true);
      setLoading(false);
      setErrorCode("500");
      setErrorMessage(tryAgainLaterMessage);
      setErrorType("InternalAPI");
    }
  }, [aeQueryResults]);

  useEffect(() => {
    if (
      aeQueryResults.isSuccess &&
      aeQueryResults.status === "fulfilled" &&
      deltaResults.isSuccess &&
      deltaResults.status === "fulfilled" &&
      showDelta
    ) {
      if (tableData.length === 0 && csvData.length === 0) {
        generateTable(
          "Submission",
          5,
          5,
          true,
          aeQueryResults.data.SubmissionsReport,
        );
        generateTable("Lock", 5, 5, true, aeQueryResults.data.LocksReport);
        generateTable(
          "Funding",
          5,
          5,
          true,
          aeQueryResults.data.FundingsReport,
        );
        generateTable(
          "PT% Lock to Fund",
          5,
          5,
          true,
          aeQueryResults.data.LocksToFundsReport,
        );
        console.log("Generated Tables");
        setErrorFlag(false);
        setLoading(false);
      }
    } else if (aeQueryResults.isError || deltaResults.isError) {
      setErrorFlag(true);
      setLoading(false);
      setErrorCode("500");
      setErrorMessage(tryAgainLaterMessage);
      setErrorType("InternalAPI");
    }
  }, [aeQueryResults, deltaResults]);

  const generateTable = (
    table: kindsOfTables,
    topN: number,
    bottomN: number,
    delta: boolean,
    dataSource: any,
  ) => {
    const regularColumnWidth = 130;
    let columnDefinitions: agGridColumnDefType[] = [];
    let dataForRows: any[] = [];
    let dataForRowsNoFormatting: any[] = [];
    let topAE: string[] = [];
    let bottomAE: string[] = [];
    let middleAE: string[] = [];
    let title: string = "Error";
    let tmpTotalSum1: number = 0;
    let tmpTotalSum2: number = 0;
    let totals: agGridExtraData[] = [];
    let identifier: string = "";
    let rowRules: any = {};
    let cellStyle: { field: string; cellStyle: any } = {
      field: "",
      cellStyle: {},
    };

    switch (props.scope) {
      case "Account Executive":
        identifier = "Account Executive";
        columnDefinitions.push({
          field: identifier,
          headerName: identifier,
          maxWidth: columnWidthCalculation(identifier),
          pinned: "left",
        });
        break;
      case "Branches":
        identifier = "Branches";
        columnDefinitions.push({
          field: identifier,
          headerName: identifier,
          maxWidth: columnWidthCalculation(identifier),
          pinned: "left",
        });
        break;
      case "Loan Officers":
        identifier = "Loan Officers";
        columnDefinitions.push({
          field: identifier,
          headerName: identifier,
          maxWidth: columnWidthCalculation(identifier),
          pinned: "left",
        });
        break;
      default:
        identifier = "Account Executive";
        break;
    }

    let tmp: any = {};
    let tmpNoFormatting: any = {};
    switch (table) {
      case "Funding": {
        title = "Funding";
        columnDefinitions.push({
          field: "#",
          headerName: "#",
          maxWidth: regularColumnWidth,
        });
        columnDefinitions.push({
          field: "$",
          headerName: "$",
          maxWidth: regularColumnWidth,
        });

        let propertyColumnOne = delta
          ? "FundingsNumber"
          : "TotalFundingsNumber";
        let propertyColumnTwo = delta ? "FundingsUSD" : "TotalFundingsUSD";
        let dataLocation = delta
          ? sortObjectArrayBy(
              deltaResults.data!.SubmissionsReport,
              sortBy === "Volume" ? propertyColumnTwo : propertyColumnOne,
              false,
              false,
            )
          : sortObjectArrayBy(
              dataSource,
              sortBy === "Volume" ? propertyColumnTwo : propertyColumnOne,
              false,
              false,
            );

        dataLocation.forEach((row: any) => {
          tmp[identifier] = row.FullName;
          tmpNoFormatting[identifier] = row.FullName;
          if (row[propertyColumnOne] !== null) {
            if (row.FullName === "Average") {
              tmp["#"] = row[propertyColumnOne].toFixed(1);
              tmpNoFormatting["#"] = row[propertyColumnOne].toFixed(1);
            } else {
              tmp["#"] = row[propertyColumnOne];
              tmpNoFormatting["#"] = row[propertyColumnOne];
              tmpTotalSum1 += row[propertyColumnOne];
            }
          } else {
            tmp["#"] = noDataSymbol;
            tmpNoFormatting["#"] = noDataSymbol;
          }
          tmp["$"] = currencySymbolFormatter(row[propertyColumnTwo]);
          tmpNoFormatting["$"] = row[propertyColumnTwo];

          if (row.FullName !== "Average") {
            tmpTotalSum2 += row[propertyColumnTwo];
          }
          dataForRows.push(tmp);
          dataForRowsNoFormatting.push(tmpNoFormatting);
          tmp = {};
          tmpNoFormatting = {};
        });
        totals.push({
          value: "Total",
          columnSize: columnWidthCalculation(identifier),
        });
        totals.push({
          value: tmpTotalSum1.toString(),
          columnSize: regularColumnWidth,
        });
        totals.push({
          value: currencySymbolFormatter(tmpTotalSum2),
          columnSize: regularColumnWidth,
        });

        dataLocation.slice(0, topN).forEach((element: any) => {
          topAE.push(element.FullName);
        });
        dataLocation.slice(-bottomN).forEach((element: any) => {
          bottomAE.push(element.FullName);
        });
        dataLocation.slice(topN, -bottomN).forEach((element: any) => {
          middleAE.push(element.FullName);
        });

        rowRules = {
          "table-highlight-green": function (params: any) {
            return topAE.includes(params.data[identifier]);
          },
          "table-highlight-red": function (params: any) {
            return bottomAE.includes(params.data[identifier]);
          },
          "table-highlight-yellow": function (params: any) {
            return middleAE.includes(params.data[identifier]);
          },
          "table-average": function (params: any) {
            return params.data[identifier] === "Average";
          },
        };
        break;
      }
      case "Submission": {
        if (props.scope === "Account Executive") {
          title = "Submission";
        } else {
          title = "Applications";
        }
        columnDefinitions.push({
          field: "#",
          headerName: "#",
          maxWidth: regularColumnWidth,
        });
        columnDefinitions.push({
          field: "$",
          headerName: "$",
          maxWidth: regularColumnWidth,
        });

        let propertyColumnOne = delta
          ? "SubmissionsNumber"
          : "TotalApplicationsNumber";
        let propertyColumnTwo = delta
          ? "SubmissionsUSD"
          : "TotalApplicationsUSD";
        let dataLocation = delta
          ? sortObjectArrayBy(
              deltaResults.data!.SubmissionsReport,
              sortBy === "Volume" ? propertyColumnTwo : propertyColumnOne,
              false,
              false,
            )
          : sortObjectArrayBy(
              dataSource,
              sortBy === "Volume" ? propertyColumnTwo : propertyColumnOne,
              false,
              false,
            );

        dataLocation.forEach((row: any) => {
          tmp[identifier] = row.FullName;
          tmpNoFormatting[identifier] = row.FullName;

          if (row[propertyColumnOne] !== null) {
            if (row.FullName === "Average") {
              tmp["#"] = row[propertyColumnOne].toFixed(1);
              tmpNoFormatting["#"] = row[propertyColumnOne].toFixed(1);
            } else {
              tmp["#"] = row[propertyColumnOne];
              tmpNoFormatting["#"] = row[propertyColumnOne];
              tmpTotalSum1 += row[propertyColumnOne];
            }
          } else {
            tmp["#"] = noDataSymbol;
            tmpNoFormatting["#"] = noDataSymbol;
          }
          tmp["$"] = currencySymbolFormatter(row[propertyColumnTwo]);
          tmpNoFormatting["$"] = row[propertyColumnTwo];
          if (row.FullName !== "Average") {
            tmpTotalSum2 += row[propertyColumnTwo];
          }
          dataForRows.push(tmp);
          dataForRowsNoFormatting.push(tmpNoFormatting);
          tmp = {};
          tmpNoFormatting = {};
        });

        totals.push({
          value: "Total",
          columnSize: columnWidthCalculation(identifier),
        });
        totals.push({
          value: tmpTotalSum1.toString(),
          columnSize: regularColumnWidth,
        });
        totals.push({
          value: currencySymbolFormatter(tmpTotalSum2),
          columnSize: regularColumnWidth,
        });

        dataLocation.slice(0, topN).forEach((element: any) => {
          topAE.push(element.FullName);
        });
        dataLocation.slice(-bottomN).forEach((element: any) => {
          bottomAE.push(element.FullName);
        });
        dataLocation.slice(topN, -bottomN).forEach((element: any) => {
          middleAE.push(element.FullName);
        });

        rowRules = {
          "table-highlight-green": function (params: any) {
            return topAE.includes(params.data[identifier]);
          },
          "table-highlight-red": function (params: any) {
            return bottomAE.includes(params.data[identifier]);
          },
          "table-highlight-yellow": function (params: any) {
            return middleAE.includes(params.data[identifier]);
          },
          "table-average": function (params: any) {
            return params.data[identifier] === "Average";
          },
        };
        break;
      }
      case "Lock": {
        title = "Lock";
        columnDefinitions.push({
          field: "#",
          headerName: "#",
          maxWidth: regularColumnWidth,
        });
        columnDefinitions.push({
          field: "$",
          headerName: "$",
          maxWidth: regularColumnWidth,
        });

        let propertyColumnOne = delta ? "LocksNumber" : "TotalLocksNumber";
        let propertyColumnTwo = delta ? "LocksUSD" : "TotalLocksUSD";
        let dataLocation = delta
          ? sortObjectArrayBy(
              deltaResults.data!.SubmissionsReport,
              sortBy === "Volume" ? propertyColumnTwo : propertyColumnOne,
              false,
              false,
            )
          : sortObjectArrayBy(
              dataSource,
              sortBy === "Volume" ? propertyColumnTwo : propertyColumnOne,
              false,
              false,
            );

        dataLocation.forEach((row: any) => {
          tmp[identifier] = row.FullName;
          tmpNoFormatting[identifier] = row.FullName;

          if (row[propertyColumnOne] !== null) {
            if (row.FullName === "Average") {
              tmp["#"] = row[propertyColumnOne].toFixed(1);
              tmpNoFormatting["#"] = row[propertyColumnOne].toFixed(1);
            } else {
              tmp["#"] = row[propertyColumnOne];
              tmpNoFormatting["#"] = row[propertyColumnOne];
              tmpTotalSum1 += row[propertyColumnOne];
            }
          } else {
            tmp["#"] = noDataSymbol;
            tmpNoFormatting["#"] = noDataSymbol;
          }
          tmp["$"] = currencySymbolFormatter(row[propertyColumnTwo]);
          tmpNoFormatting["$"] = row[propertyColumnTwo];
          if (row.FullName !== "Average") {
            tmpTotalSum2 += row[propertyColumnTwo];
          }
          dataForRows.push(tmp);
          dataForRowsNoFormatting.push(tmpNoFormatting);
          tmp = {};
          tmpNoFormatting = {};
        });

        totals.push({
          value: "Total",
          columnSize: columnWidthCalculation(identifier),
        });
        totals.push({
          value: tmpTotalSum1.toString(),
          columnSize: regularColumnWidth,
        });
        totals.push({
          value: currencySymbolFormatter(tmpTotalSum2),
          columnSize: regularColumnWidth,
        });

        dataLocation.slice(0, topN).forEach((element: any) => {
          topAE.push(element.FullName);
        });
        dataLocation.slice(-bottomN).forEach((element: any) => {
          bottomAE.push(element.FullName);
        });
        dataLocation.slice(topN, -bottomN).forEach((element: any) => {
          middleAE.push(element.FullName);
        });

        rowRules = {
          "table-highlight-green": function (params: any) {
            return topAE.includes(params.data[identifier]);
          },
          "table-highlight-red": function (params: any) {
            return bottomAE.includes(params.data[identifier]);
          },
          "table-highlight-yellow": function (params: any) {
            return middleAE.includes(params.data[identifier]);
          },
          "table-average": function (params: any) {
            return params.data[identifier] === "Average";
          },
        };
        break;
      }
      case "PT% Lock to Fund": {
        title = "PT% Lock to Fund";
        columnDefinitions.push({
          field: "%",
          headerName: "%",
          maxWidth: regularColumnWidth,
        });
        let dataLocation = delta
          ? sortObjectArrayBy(
              deltaResults.data!.SubmissionsReport,
              "PTLockToFund",
              false,
              false,
            )
          : sortObjectArrayBy(
              dataSource,
              "TotalLocksToFundsNumber",
              false,
              false,
            );
        let propertyColumnOne = delta
          ? "PTLockToFund"
          : "TotalLocksToFundsNumber";

        dataLocation.forEach((row) => {
          tmp[identifier] = row.FullName;
          tmpNoFormatting[identifier] = row.FullName;

          if (row[propertyColumnOne] !== null) {
            tmp["%"] = displayAsPercentage(row[propertyColumnOne], false, 0);
            tmpNoFormatting["%"] = row[propertyColumnOne];
            if (delta) {
              tmpTotalSum1 += row[propertyColumnOne];
            }
            if (row.FullName === "Average") {
              tmpTotalSum1 = row[propertyColumnOne];
            }
          } else {
            tmp["%"] = noDataSymbol;
            tmpNoFormatting["%"] = noDataSymbol;
          }

          dataForRows.push(tmp);
          dataForRowsNoFormatting.push(tmpNoFormatting);
          tmp = {};
          tmpNoFormatting = {};
        });

        totals.push({
          value: delta ? "Total" : "Average",
          columnSize: columnWidthCalculation(identifier),
        });
        totals.push({
          value: displayAsPercentage(tmpTotalSum1, false, 0),
          columnSize: regularColumnWidth,
        });

        dataLocation.slice(0, topN).forEach((element) => {
          topAE.push(element.FullName);
        });
        dataLocation.slice(-bottomN).forEach((element) => {
          bottomAE.push(element.FullName);
        });
        dataLocation.slice(topN, -bottomN).forEach((element) => {
          middleAE.push(element.FullName);
        });

        rowRules = {
          "table-highlight-green": function (params: any) {
            return topAE.includes(params.data[identifier]);
          },
          "table-highlight-red": function (params: any) {
            return bottomAE.includes(params.data[identifier]);
          },
          "table-highlight-yellow": function (params: any) {
            return middleAE.includes(params.data[identifier]);
          },
          "table-average": function (params: any) {
            return params.data[identifier] === "Average";
          },
        };
        break;
      }
      case "Sub to Fund": {
        title = "Sub to Fund";
        columnDefinitions.push({
          field: "Days",
          headerName: "Days",
          maxWidth: regularColumnWidth,
          sortable: true,
        });
        let sortedSubToFund = sortObjectArrayBy(
          dataSource,
          "TotalSubToFund",
          false,
          true,
        );

        sortedSubToFund.forEach((row) => {
          tmp[identifier] = row.FullName;
          tmpNoFormatting[identifier] = row.FullName;

          if (row.TotalSubToFund !== null) {
            tmp["Days"] = row.TotalSubToFund.toFixed(1);
            tmpNoFormatting["Days"] = row.TotalSubToFund.toFixed(1);
            if (row.FullName === "Average") {
              tmpTotalSum1 = row.TotalSubToFund;
            }
          } else {
            tmp["Days"] = noDataSymbol;
            tmpNoFormatting["Days"] = noDataSymbol;
          }
          dataForRows.push(tmp);
          dataForRowsNoFormatting.push(tmpNoFormatting);
          tmp = {};
          tmpNoFormatting = {};
        });

        totals.push({
          value: "Average",
          columnSize: columnWidthCalculation(identifier),
        });
        totals.push({
          value: tmpTotalSum1.toFixed(1),
          columnSize: regularColumnWidth,
        });

        sortedSubToFund.slice(0, topN).forEach((element) => {
          topAE.push(element.FullName);
        });
        sortedSubToFund.slice(-bottomN).forEach((element) => {
          bottomAE.push(element.FullName);
        });
        sortedSubToFund.slice(topN, -bottomN).forEach((element) => {
          middleAE.push(element.FullName);
        });

        rowRules = {
          "table-highlight-green": function (params: any) {
            return topAE.includes(params.data[identifier]);
          },
          "table-highlight-red": function (params: any) {
            return bottomAE.includes(params.data[identifier]);
          },
          "table-highlight-yellow": function (params: any) {
            return middleAE.includes(params.data[identifier]);
          },
          "table-average": function (params: any) {
            return params.data[identifier] === "Average";
          },
        };
        break;
      }
    }
    let tmpcsv = csvData;
    tmpcsv.push({
      title: title,
      columnDefs: columnDefinitions,
      rowDefs: dataForRowsNoFormatting,
    });
    setCSVData(tmpcsv);
    console.log("Generated CSV");
    console.log("Generated Table " + title);
    setTableData((current) => [
      ...current,
      <CustomAgGridTable
        title={title}
        tableData={{
          identifier: identifier,
          rows: dataForRows,
          columns: columnDefinitions,
        }}
        rowClassRules={rowRules}
        extraTableData={totals.length === 0 ? undefined : totals}
        customCellStyle={cellStyle}
      />,
    ]);
  };

  const generateOptionMenuButtonGroups = (highlightId?: string) => {
    let result: ButtonGroup[] = [];
    highlightId = highlightId ?? "BA0-0";
    const monthToDate: button = {
      name: "Month-to-Date",
      onClick: () => {
        getData(
          new Date().getFullYear().toString(),
          (new Date().getMonth() + 1).toString(),
        );
        setShowDelta(false);
      },
      highlighted: highlightId == "BA0-0",
    };
    const yearToDate: button = {
      name: "Year-to-Date",
      onClick: () => {
        getData(
          new Date().getFullYear().toString(),
          (new Date().getMonth() + 1).toString(),
          "YTD",
        );
        setShowDelta(false);
      },
      highlighted: highlightId == "BA0-2",
    };
    const quarterToDate: button = {
      name: "Quarter-to-Date",
      onClick: () => {
        getData(
          new Date().getFullYear().toString(),
          (new Date().getMonth() + 1).toString(),
          "QTD",
        );
        setShowDelta(false);
      },
      highlighted: highlightId == "BA0-1",
    };
    const allTime: button = {
      name: "All-Time",
      onClick: () => {
        getData(
          new Date().getFullYear().toString(),
          (new Date().getMonth() + 1).toString(),
          "ALL",
        );
        setShowDelta(false);
      },
      highlighted: highlightId == "BA0-3",
    };
    const deltaMTD: button = {
      name: "Month-to-Date",
      onClick: () => {
        getData(
          new Date().getFullYear().toString(),
          (new Date().getMonth() + 1).toString(),
          undefined,
          "MTD",
        );
        setShowDelta(true);
      },
      highlighted: highlightId == "BA1-0",
    };
    const deltaQTD: button = {
      name: "Quarter-to-Date",
      onClick: () => {
        getData(
          new Date().getFullYear().toString(),
          (new Date().getMonth() + 1).toString(),
          "QTD",
          "QTD",
        );
        setShowDelta(true);
      },
      highlighted: highlightId == "BA1-1",
    };
    const deltaYTD: button = {
      name: "Year-to-Date",
      onClick: () => {
        getData(
          new Date().getFullYear().toString(),
          (new Date().getMonth() + 1).toString(),
          "YTD",
          "YTD",
        );
        setShowDelta(true);
      },
      highlighted: highlightId == "BA1-2",
    };

    switch (props.scope) {
      case "Account Executive":
        if (props.useDemoData) {
          result.push({
            title: "Filter by Period:",
            buttons: [monthToDate, quarterToDate, yearToDate, allTime],
          });
        } else {
          result.push(
            {
              title: "Filter by Period:",
              buttons: [monthToDate, quarterToDate, yearToDate, allTime],
            },
            {
              title: "Filter by Delta:",
              buttons: [deltaMTD, deltaQTD, deltaYTD],
            },
          );
        }
        break;
      default:
        result.push({
          title: "Filter by Period:",
          buttons: [monthToDate, quarterToDate, yearToDate, allTime],
        });
        break;
    }

    return result;
  };

  const [optionMenuButtonGroups, setOptionMenuButtonGroups] = React.useState<
    ButtonGroup[]
  >(generateOptionMenuButtonGroups());

  const handlerButtonClicked = (id: string) => {
    setOptionMenuButtonGroups(generateOptionMenuButtonGroups(id));
  };

  return (
    <div className="module-master" style={{ padding: "10px 10px 0 20px" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div className="module-title">
          {typeof props.overrideTitle !== "undefined"
            ? props.overrideTitle
            : props.scope + " Performance Scoreboard"}
        </div>
      </div>
      <div
        className={
          savingToPDF
            ? "modal display-block modal-disable-padding-top"
            : "modal display-none modal-disable-padding-top"
        }
      >
        <div className="saving-to-file-modal-text">
          Now saving, please wait.
        </div>
      </div>
      <OptionsMenu
        buttonGroups={optionMenuButtonGroups}
        exportToCSV={{
          filename: `${typeof props.overrideTitle !== "undefined" ? props.overrideTitle : props.scope + " Performance Scoreboard"}`,
          CSVData: csvData,
        }}
        periodSelector={{
          setMonthFunction: setMonth,
          upToCurrentMonth: true,
          setYearFunction: setYear,
          startingYear: 2019,
          buttonText: "Filter",
          onClickFunctionality: () => {
            getData(year, month);
          },
          disableCondition: () => (year !== "" && month !== "" ? false : true),
          blockYear: false,
          hideYear: false,
          setAsDefault: false,
        }}
        saveAsPDF={{
          title: props.overrideTitle ?? props.scope + " Performance Scoreboard",
          subtitle:
            new Date().getMonth() +
            1 +
            "/" +
            new Date().getFullYear() +
            ": " +
            searchPeriod,
          landscape: true,
          setProgressFlagFunction: setSavingToPDF,
          disableCondition: () =>
            loading || searchPeriod === "ALL" || props.scope === "Loan Officers"
              ? true
              : false,
          reasonableFit: false,
        }}
        onButtonClicked={handlerButtonClicked}
        customObject={
          <div>
            <div className="option-in-card">
              <button
                className={sortBy === "Volume" ? "btn clicked-button" : "btn"}
                onClick={() => {
                  setSortBy("Volume");
                }}
              >
                Sort By Volume
              </button>
            </div>
            <div className="option-in-card">
              <button
                className={sortBy === "Units" ? "btn clicked-button" : "btn"}
                onClick={() => {
                  setSortBy("Units");
                }}
              >
                Sort By Units
              </button>
            </div>
          </div>
        }
      />
      <div>
        {errorFlag && (
          <div>
            <Error code={errorCode} type={errorType} message={errorMessage} />
          </div>
        )}
        {loading && (
          <div className="loading-div">
            <FontAwesomeIcon
              icon={faSpinner}
              size="2x"
              spin={true}
              style={{ margin: "0 auto" }}
            />
          </div>
        )}
        {!errorFlag && !loading && (
          <div style={{ display: "flex", overflowX: "scroll" }}>
            {tableData}
          </div>
        )}
      </div>
    </div>
  );
};
