import React from "react";

export const ContactUs = () => {
  return (
    <div className="hero-image">
      <h1>
        For inquiries, please contact us at:
        <br></br>
        <a href="mailto:info@gallusinsights.com">info@gallusinsights.com</a>
      </h1>
    </div>
  );
};
