import * as React from "react";
import { AppEmbed } from "@thoughtspot/visual-embed-sdk/react";
import { EmbedProps } from "@thoughtspot/visual-embed-sdk/lib/src/react/util";
import {
  Action,
  AppViewConfig,
  MessagePayload,
  Page,
} from "@thoughtspot/visual-embed-sdk";
import { getUserSessionItem, initTs } from "../..";
//import TsAppEmbedCookieChecker from '../../TsAppEmbedCookieChecker';
import { useAppSelector } from "../../app/hooks";
import {
  CognitoJwtPayload,
  selectUserAuthContext,
} from "../../features/console/consoleSlice";
import {
  Alert,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useLazyGetTSAccessTokenQuery } from "../../services/serverless";
import { smallScreenCssRules } from "./smallScreenCssRules";
import { useResizeDetector } from "react-resize-detector";
import { SubdomainOrgIDArray } from "../../Routes";
import { Error as ErrorComponent } from "../Error";
import { sessionChecker } from "../../helpers/session";

export interface AppProps extends EmbedProps, AppViewConfig {
  cssRules: {
    [selector: string]: {
      [declaration: string]: string;
    };
  };
  breakPoint?: "xxl" | "xl" | "lg" | "md" | "sm" | "xs";
  orgId?: number;
  defaultPage?: Page;
  enableFullFeatures?: boolean;
  disableEditButtonArray?: string[];
  disableFunctionality?: Action[];
}

export const AppEmbedWrapper = (props: AppProps) => {
  const normalDisabledActions: Action[] = [
    Action.ImportTML,
    Action.Schedule,
    Action.SchedulesList,
    ...(props.disableFunctionality ?? []),
  ];
  const userAuthContext = useAppSelector(selectUserAuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [hasNoCookieAccess, setHasNoCookieAccess] =
    React.useState<boolean>(false);
  const [modalIsOpen, setModalIsOpen] = React.useState<boolean>(false);
  const [pinnedLiveboard, setPinnedLiveboard] = React.useState<string>("");
  const [embedPath, setEmbedPath] = React.useState<string>("");
  const [currentQueryParameters, setSearchParams] = useSearchParams();
  const newQueryParameter: URLSearchParams = new URLSearchParams();
  // const tsAccessTokenResult = useGetTSAccessTokenQuery(props.orgId ?? 0);
  const [getTSAccessToken, getTSAccessTokenResult] =
    useLazyGetTSAccessTokenQuery();
  const [disabledActionsArray, setDisabledActionsArray] = React.useState<
    Action[]
  >(!props.enableFullFeatures ? normalDisabledActions : []);

  const { width, height, ref } = useResizeDetector();
  const [rulesHaveBeenSet, setRulesHaveBeenSet] =
    React.useState<boolean>(false);

  //forceReRender is a state used ONLY to force the appEmbed hook to rerender.
  //NO clue why changing embedPath doesn't trigger a re render and this does.
  const [forceReRender, setForceReRender] = React.useState<boolean>(false);

  const currentDisabledActions = React.useRef<string[]>(
    !props.enableFullFeatures ? normalDisabledActions : [],
  );

  const [currentCssRules, setCurrentCssRules] = React.useState<{
    [selector: string]: {
      [declaration: string]: string;
    };
  }>();

  const onLiveboardRendered = (payload: MessagePayload) => {
    console.log("liveboard rendered");
  };

  const handleNoCookieAccess = () => {
    console.log("no cookie access");
    setHasNoCookieAccess(true);
  };

  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen);
  };

  const redirect = () => {
    newQueryParameter.set("productURL", "pinboard/" + pinnedLiveboard);
    setSearchParams(newQueryParameter);
    setEmbedPath("pinboard/" + pinnedLiveboard);
  };

  React.useEffect(() => {
    let productUrl = currentQueryParameters.get("productURL");
    const fromMenu = currentQueryParameters.get("fromMenu");
    if (productUrl) {
      productUrl = productUrl.replace("/insights/", "");
      setEmbedPath(productUrl);
    }
    if (fromMenu) {
      // setEmbedPath(productUrl.replace("%2F", "/"));
      setForceReRender(!forceReRender);
    }
  }, [currentQueryParameters]);

  const handleEditingAccess = (currentPath: string) => {
    let userSession = getUserSessionItem("user");
    //TODO: instead of finding the following object, make it a prop. Would do it now but the change is pretty major. Will open ticket
    let currentOrgObject = SubdomainOrgIDArray.find(
      (object) => object.orgID === props.orgId,
    );

    if (
      userSession &&
      props.disableEditButtonArray &&
      props.orgId &&
      currentOrgObject?.editableRightsGroup
    ) {
      let user = JSON.parse(userSession) as CognitoJwtPayload; //get user info
      let userCanEdit = user["cognito:groups"]?.includes(
        currentOrgObject?.editableRightsGroup,
      );

      //if the user does NOT belong to the correct group...
      if (!userCanEdit) {
        //disable liveboards editing button
        let disableEditButtonRequired = false;

        disableEditButtonRequired =
          props.disableEditButtonArray.some((liveboardId) => {
            return currentPath.includes(liveboardId);
          }) ?? false;

        let newDisabledActions = [];
        if (disableEditButtonRequired) {
          newDisabledActions = !props.enableFullFeatures
            ? [...normalDisabledActions, Action.Edit]
            : [Action.Edit];
        } else {
          newDisabledActions = !props.enableFullFeatures
            ? normalDisabledActions
            : [];
        }
        if (
          newDisabledActions.length !== currentDisabledActions.current.length &&
          currentPath.includes("pinboard/")
        ) {
          setDisabledActionsArray(newDisabledActions);
          currentDisabledActions.current = newDisabledActions;
        }
      } else {
        if (
          currentCssRules &&
          currentCssRules['.classic-nav-module__navItem[href="/#/data"]']
        ) {
          var {
            '.classic-nav-module__navItem[href="/#/data"]': _,
            ...newCssRules
          } = currentCssRules;
          setCurrentCssRules(newCssRules);
        }
      }
    }
  };

  const handleRouteChanged = (payload: MessagePayload) => {
    //the search parameters change according to the current route, so users can share links easily
    //I remove the first character ("/"), to be a little cleaner
    newQueryParameter.set("productURL", payload.data.currentPath);
    setSearchParams(newQueryParameter);

    handleEditingAccess(payload.data.currentPath);
  };

  handleEditingAccess(embedPath); //in case user reloads a page that should not be editable

  React.useEffect(() => {
    if (width && !rulesHaveBeenSet) {
      {
        setRulesHaveBeenSet(true);
        setCurrentCssRules(cssRulesHelper(width > 650)); //Screen starts looking bad at around 650px width
        console.log(
          "Setting CSS rules: " + (width > 650 ? "Full Screen" : "Mobile"),
        );
      }
    }
  }, [width]);

  const cssRulesHelper: any = (normalRules: boolean) => {
    if (normalRules) {
      return props.cssRules;
    } else {
      return { ...props.cssRules, ...smallScreenCssRules };
    }
  };

  async function handleAuthExpire(payload: MessagePayload) {
    console.error(payload);
    // Make sure the Cognito access token is not expired so that we could use it to generate the TS access token. Otherwise, it redirects the user to the login page.
    await sessionChecker(() => {
      navigate(
        `/login?redirect_uri=${encodeURIComponent(`${location.pathname}${location.search}`)}`,
      );
    });
  }

  async function handleAuthFailure(payload: MessagePayload) {
    console.error(payload);
    await sessionChecker(() => {
      navigate(
        `/login?redirect_uri=${encodeURIComponent(`${location.pathname}${location.search}`)}`,
      );
    });
  }

  function handleAuthInit(payload: MessagePayload) {
    console.debug(payload);
  }

  const [appEmbed, setAppEmbed] = React.useState<JSX.Element>();
  React.useEffect(() => {
    const render = async () => {
      //the following If statement is used to forward links from an external component (for example, the TS Landing page component), or an empty tab
      //this If statement is only run ONCE
      // if (currentQueryParameters.get("productURL") !== null) {
      //     setEmbedPath(currentQueryParameters.get("productURL")!.toString().replace("%2F", "/"));
      // }

      console.log(`rendering ${embedPath}`);
      try {
        await sessionChecker(() => {
          navigate(
            `/login?redirect_uri=${encodeURIComponent(`${location.pathname}${location.search}`)}`,
          );
        });
        await getTSAccessToken(props.orgId ?? 0).unwrap();
        await initTs(
          "TOKEN",
          userAuthContext?.username ?? getUserSessionItem("userName"),
          props.orgId ?? 0,
        );
        setAppEmbed(
          <div ref={ref} style={{ height: "100%" }}>
            <AppEmbed
              onAuthExpire={handleAuthExpire}
              onAuthFailure={handleAuthFailure}
              onAuthInit={handleAuthInit}
              dataPanelV2={true}
              disabledActions={disabledActionsArray}
              hiddenActions={disabledActionsArray}
              customizations={{
                style: {
                  customCSS: {
                    rules_UNSTABLE: currentCssRules ?? props.cssRules,
                  },
                },
              }}
              {...props}
              onLiveboardRendered={onLiveboardRendered}
              pageId={props.defaultPage ?? Page.Liveboards}
              path={embedPath}
              onNoCookieAccess={handleNoCookieAccess}
              onPin={(payload) => {
                setPinnedLiveboard(payload.data.liveboardId);
                setModalIsOpen(true);
              }}
              onRouteChange={handleRouteChanged}
            />
          </div>,
        );
      } catch (e) {
        setAppEmbed(
          <ErrorComponent type="External" code="500" message="" error={e} />,
        );
      }
    };
    render();
  }, [
    hasNoCookieAccess,
    props,
    forceReRender,
    disabledActionsArray,
    currentCssRules,
  ]);

  return (
    <>
      {hasNoCookieAccess && (
        <Alert color="info">
          Please allow third party cookies from the browser settings.
        </Alert>
      )}
      {appEmbed}
      <Modal isOpen={modalIsOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Pinned Board</ModalHeader>
        <div>
          <ModalBody>Do you want to go to the liveboard?</ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => {
                toggleModal();
                redirect();
              }}
            >
              Yes
            </Button>
            <Button color="secondary" onClick={() => toggleModal()}>
              Cancel
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </>
  );
};
