import { DataEncoding, tsDataObject } from "../../../services/types/gallus";

const dataArrayFormatted = [
  ["Size", "Title", "", "", "", "", "", "", "", "", "", "", "", ""],
  [
    "Originations",
    "text-underlined",
    { value: 24661690000, overrideEncoding: "$skb1d" },
    { value: 20205236000, overrideEncoding: "$skb1d" },
    0.220559363919333,
    { value: 6525898000, overrideEncoding: "$skb1d" },
    { value: 3852539000, overrideEncoding: "$skb1d" },
    0.693921333437507,
    { value: 33628993000, overrideEncoding: "$skb1d" },
    { value: 27630535000, overrideEncoding: "$skb1d" },
    0.217095253494006,
    { value: 6090634000, overrideEncoding: "$skb1d" },
    { value: 4558351000, overrideEncoding: "$skb1d" },
    0.336148532660166,
  ],
  [
    "Servicing",
    "CSSCLASSGOESHERE",
    { value: 534557627000, overrideEncoding: "$-skb1d" },
    { value: 510697615000, overrideEncoding: "$-skb1d" },
    0.0467204296616894,
    { value: 89092933000, overrideEncoding: "$-skb1d" },
    { value: 86319074000, overrideEncoding: "$-skb1d" },
    0.032134948528294,
    { value: 189482798000, overrideEncoding: "$-skb1d" },
    { value: 259557321000, overrideEncoding: "$-skb1d" },
    -0.269977062215094,
    { value: 114278549000, overrideEncoding: "$-skb1d" },
    { value: 142337251000, overrideEncoding: "$-skb1d" },
    -0.197128311828925,
  ],
  [
    null,
    "Spacing",
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ],
  ["Balance Sheet", "Title", "", "", "", "", "", "", "", "", "", "", "", ""],
  [
    "Tangible Assets",
    "CSSCLASSGOESHERE",
    22408895000,
    20973268000,
    0.0684503244797139,
    4349392000,
    3723171000,
    0.168195605305263,
    12921641000,
    12797334000,
    0.00971350751648736,
    5942777000,
    6193270000,
    -0.0404460002551157,
  ],
  [
    "Tangible Equity",
    "text-underlined",
    { value: 7574102000, overrideEncoding: "$-sk0d" },
    { value: 7363544000, overrideEncoding: "$-sk0d" },
    0.0285946549650549,
    { value: 991984000, overrideEncoding: "$-sk0d" },
    { value: 981269000, overrideEncoding: "$-sk0d" },
    0.0109195337873713,
    { value: 2329012000, overrideEncoding: "$-sk0d" },
    { value: 2457058000, overrideEncoding: "$-sk0d" },
    -0.052113543921226,
    { value: 578938000, overrideEncoding: "$-sk0d" },
    { value: 637342000, overrideEncoding: "$-sk0d" },
    -0.0916368292062974,
  ],
  [
    "TCE / TA",
    "CSSCLASSGOESHERE",
    { value: 0.337995336226976, overrideEncoding: "$%1d" },
    { value: 0.351091875619956, overrideEncoding: "$%1d" },
    { value: -0.0130965393929799, overrideEncoding: "$%1d" },
    { value: 0.228074176804482, overrideEncoding: "$%1d" },
    { value: 0.263557327879917, overrideEncoding: "$%1d" },
    { value: -0.0354831510754353, overrideEncoding: "$%1d" },
    { value: 0.180241193823602, overrideEncoding: "$%1d" },
    { value: 0.191997645759656, overrideEncoding: "$%1d" },
    { value: -0.011756451936054, overrideEncoding: "$%1d" },
    { value: 0.0974187656713351, overrideEncoding: "$%1d" },
    { value: 0.102908802619618, overrideEncoding: "$%1d" },
    { value: -0.00549003694828261, overrideEncoding: "$%1d" },
  ],
  [
    null,
    "Spacing",
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ],
  ["Income Statement", "Title", "", "", "", "", "", "", "", "", "", "", "", ""],
  [
    "Revenue",
    "CSSCLASSGOESHERE",
    1300722000,
    1383716000,
    -0.0599790708497986,
    285685000,
    231782000,
    0.232559042548602,
    507094000,
    569956000,
    -0.110292724350652,
    265390000,
    222785000,
    0.191238189285634,
  ],
  [
    "Expense",
    "CSSCLASSGOESHERE",
    { value: 1108680000, overrideEncoding: "$-sk0d" },
    { value: 1085346000, overrideEncoding: "$-sk0d" },
    0.0214991348381071,
    { value: 241158000, overrideEncoding: "$-sk0d" },
    { value: 193239000, overrideEncoding: "$-sk0d" },
    0.247977892661419,
    { value: 430022000, overrideEncoding: "$-sk0d" },
    { value: 385692000, overrideEncoding: "$-sk0d" },
    0.114936270391919,
    { value: 342547000, overrideEncoding: "$-sk0d" },
    { value: 307949000, overrideEncoding: "$-sk0d" },
    0.11234977220254,
  ],
  [
    "Net Income",
    "SubTitleOrange",
    177925000,
    290714000,
    -0.387972371471618,
    37591000,
    28400000,
    0.32362676056338,
    76286000,
    180531000,
    -0.577435454298708,
    -65853000,
    -71504000,
    -0.0790305437458045,
  ],
  [
    null,
    "Spacing",
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ],
  ["Profitability", "Title", "", "", "", "", "", "", "", "", "", "", "", ""],
  [
    "GoS Margins",
    "CSSCLASSGOESHERE",
    { value: 0.0299, overrideEncoding: "$%2d" },
    { value: 0.0311, overrideEncoding: "$%2d" },
    { value: -0.0012, overrideEncoding: "$%2d" },
    { value: 0.0315, overrideEncoding: "$%2d" },
    { value: 0.029, overrideEncoding: "$%2d" },
    { value: 0.0025, overrideEncoding: "$%2d" },
    { value: 0.0106, overrideEncoding: "$%2d" },
    { value: 0.0108, overrideEncoding: "$%2d" },
    { value: -0.000200000000000001, overrideEncoding: "$%2d" },
    { value: 0.0306, overrideEncoding: "$%2d" },
    { value: 0.0284, overrideEncoding: "$%2d" },
    { value: 0.0022, overrideEncoding: "$%2d" },
  ],
  [
    "Efficiency Ratio",
    "CSSCLASSGOESHERE",
    { value: 0.852357383053412, overrideEncoding: "$%1d" },
    { value: 0.784370492210829, overrideEncoding: "$%1d" },
    { value: 0.0679868908425825, overrideEncoding: "$%1d" },
    { value: 0.844139524301241, overrideEncoding: "$%1d" },
    { value: 0.8337101241684, overrideEncoding: "$%1d" },
    { value: 0.0104294001328412, overrideEncoding: "$%1d" },
    { value: 0.848012400067838, overrideEncoding: "$%1d" },
    { value: 0.676704868445985, overrideEncoding: "$%1d" },
    { value: 0.171307531621852, overrideEncoding: "$%1d" },
    { value: 1.29073062285693, overrideEncoding: "$%1d" },
    { value: 1.38226990147452, overrideEncoding: "$%1d" },
    { value: -0.0915392786175882, overrideEncoding: "$%1d" },
  ],
  [
    "Net Margin",
    "CSSCLASSGOESHERE",
    { value: 0.136789413879369, overrideEncoding: "$%1d" },
    { value: 0.210096580512186, overrideEncoding: "$%1d" },
    { value: -0.0733071666328175, overrideEncoding: "$%1d" },
    { value: 0.131581987153683, overrideEncoding: "$%1d" },
    { value: 0.122528928044456, overrideEncoding: "$%1d" },
    { value: 0.00905305910922768, overrideEncoding: "$%1d" },
    { value: 0.150437591452472, overrideEncoding: "$%1d" },
    { value: 0.316745503161648, overrideEncoding: "$%1d" },
    { value: -0.166307911709175, overrideEncoding: "$%1d" },
    { value: -0.248136704472663, overrideEncoding: "$%1d" },
    { value: -0.320955181004107, overrideEncoding: "$%1d" },
    { value: 0.0728184765314443, overrideEncoding: "$%1d" },
  ],
  [
    "ROTCE",
    "SubTitleOrange",
    { value: 0.0939649347209742, overrideEncoding: "$%1d" },
    { value: 0.157920696881828, overrideEncoding: "$%1d" },
    { value: -0.0639557621608534, overrideEncoding: "$%1d" },
    { value: 0.151579057726738, overrideEncoding: "$%1d" },
    { value: 0.115768459005635, overrideEncoding: "$%1d" },
    { value: 0.035810598721103, overrideEncoding: "$%1d" },
    { value: 0.13101864653338, overrideEncoding: "$%1d" },
    { value: 0.293897824145787, overrideEncoding: "$%1d" },
    { value: -0.162879177612408, overrideEncoding: "$%1d" },
    { value: -0.454991726229752, overrideEncoding: "$%1d" },
    { value: -0.448763772040757, overrideEncoding: "$%1d" },
    { value: -0.00622795418899491, overrideEncoding: "$%1d" },
  ],
];

const columnFormattingFormatted: DataEncoding[] = [
  "string",
  "string",
  "$sk0d",
  "$sk0d",
  "$%1d",
  "$sk0d",
  "$sk0d",
  "$%1d",
  "$sk0d",
  "$sk0d",
  "$%1d",
  "$sk0d",
  "$sk0d",
  "$%1d",
];

export const GrateBenchmarkQ224: tsDataObject = {
  data: dataArrayFormatted,
  columnNames: [
    "RowName",
    "CSSClass",
    "Q2 2024 Rocket",
    "Q1 2024 Rocket",
    "QoQ Rocket",
    "Q2 2024 Guild",
    "Q1 2024 Guild",
    "QoQ Guild",
    "Q2 2024 UWM",
    "Q1 2024 UWM",
    "QoQ UWM",
    "Q2 2024 Loan Depot",
    "Q1 2024 Loan Depot",
    "QoQ Loan Depot",
  ],
  columnDisplayName: [
    "RowName",
    "CSSClass",
    "Q2 2024",
    "Q1 2024",
    "QoQ",
    "Q2 2024",
    "Q1 2024",
    "QoQ",
    "Q2 2024",
    "Q1 2024",
    "QoQ",
    "Q2 2024",
    "Q1 2024",
    "QoQ",
  ],
  columnShow: [
    true,
    false,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
  ],
  columnGroupName: [
    {
      nameOfGroup: "Rocket",
      headerClass: "grate-group",
      columnsToGroup: ["Q2 2024 Rocket", "Q1 2024 Rocket", "QoQ Rocket"],
    },
    {
      nameOfGroup: "Guild",
      headerClass: "grate-group",
      columnsToGroup: ["Q2 2024 Guild", "Q1 2024 Guild", "QoQ Guild"],
    },
    {
      nameOfGroup: "UWM",
      headerClass: "grate-group",
      columnsToGroup: ["Q2 2024 UWM", "Q1 2024 UWM", "QoQ UWM"],
    },
    {
      nameOfGroup: "Loan Depot",
      headerClass: "grate-group",
      columnsToGroup: [
        "Q2 2024 Loan Depot",
        "Q1 2024 Loan Depot",
        "QoQ Loan Depot",
      ],
    },
  ],
  columnFormatting: columnFormattingFormatted,
  columnHeaderClasses: [
    "grid-bold",
    "",
    "grate-column",
    "grate-column",
    "grate-column",
    "grate-column",
    "grate-column",
    "grate-column",
    "grate-column",
    "grate-column",
    "grate-column",
    "grate-column",
    "grate-column",
    "grate-column",
  ],
  columnDataClasses: [
    "text-left",
    "",
    "text-right",
    "text-right",
    "text-right",
    "text-right",
    "text-right",
    "text-right",
    "text-right",
    "text-right",
    "text-right",
    "text-right",
    "text-right",
    "text-right",
  ],
  tableTitle: "",
};
