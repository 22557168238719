import { useRef } from "react";
import "../Module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { exportPlotlyToPdf } from "../../../features/SaveAsPdf";
import { plotlyFormatter } from "../../../features/PlotlyFormatter";
import Plot from "react-plotly.js";
import { OptionsMenu } from "../../../features/OptionsMenu";

interface WaterfallPlotsProps {}

interface WaterfallPlotsState {}

export const WaterfallPlots = (props: WaterfallPlotsProps) => {
  const guild2022WalkChartOptions = [
    {
      name: "Guild Expenses 2022 (bps)",
      type: "waterfall",
      orientation: "v",
      measure: ["relative", "relative", "relative", "relative", "total"],
      x: [
        "Salaries, Inc.",
        "G&A",
        "Occ. & Eq.",
        "D&A",
        "Total Opex", //total
      ],
      textposition: "auto",
      /*text: [
                "<b>" + plotlyFormatter(309, "financials") + "</b>",
                "<b>" + plotlyFormatter(15, "financials") + "</b>",
                "<b>" + plotlyFormatter(35, "financials") + "</b>",
                "<b>" + plotlyFormatter(8, "financials") + "</b>",
                "<b>" + plotlyFormatter(366, "financials") + "</b>",
            ],*/
      text: [
        "<b>" + 309 + "</b>",
        "<b>" + 15 + "</b>",
        "<b>" + 35 + "</b>",
        "<b>" + 8 + "</b>",
        "<b>" + 366 + "</b>",
      ],
      y: [Math.round(309), Math.round(15), Math.round(35), Math.round(8), 0],
      connector: {
        line: {
          color: "rgb(63, 63, 63)",
        },
      },
      decreasing: {
        marker: {
          color: "red",
          line: {
            color: "black",
            width: 1,
          },
        },
      },
      increasing: {
        marker: {
          color: "rgb(124, 181, 236)",
          line: {
            color: "black",
            width: 1,
          },
        },
      },
      totals: {
        marker: {
          color: "#D3D3D3",
          line: {
            color: "black",
            width: 1,
          },
        },
      },
      cliponaxis: false,
    },
  ];
  const guild2023WalkChartOptions = [
    {
      name: "Guild Expenses 2023 (bps)",
      type: "waterfall",
      orientation: "v",
      measure: ["relative", "relative", "relative", "relative", "total"],
      x: [
        "Salaries, Inc.",
        "G&A",
        "Occ. & Eq.",
        "D&A",
        "Total Opex", //total
      ],
      textposition: "auto",
      text: [
        "<b>" + 333 + "</b>",
        "<b>" + 48 + "</b>",
        "<b>" + 45 + "</b>",
        "<b>" + 9 + "</b>",
        "<b>" + 436 + "</b>",
      ],
      y: [Math.round(333), Math.round(48), Math.round(45), Math.round(9), 0],
      connector: {
        line: {
          color: "rgb(63, 63, 63)",
        },
      },
      decreasing: {
        marker: {
          color: "red",
          line: {
            color: "black",
            width: 1,
          },
        },
      },
      increasing: {
        marker: {
          color: "rgb(124, 181, 236)",
          line: {
            color: "black",
            width: 1,
          },
        },
      },
      totals: {
        marker: {
          color: "#D3D3D3",
          line: {
            color: "black",
            width: 1,
          },
        },
      },
      cliponaxis: false,
    },
  ];

  const plotlyRefChart1 = useRef<Plot>();
  const plotlyRefChart2 = useRef<Plot>();

  function exportToPdfWrapper() {
    exportPlotlyToPdf(
      "Guild Expenses",
      [plotlyRefChart1, plotlyRefChart2],
      false,
    );
  }

  return (
    <div className="module-master">
      <div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="module-title">Guild Expenses</div>
          <div className="module-subtitle">(bps)</div>
        </div>
        <OptionsMenu
          customObject={
            <div className="option-in-card">
              <div style={{ paddingLeft: "5px" }}>
                <button className="btn" onClick={exportToPdfWrapper}>
                  Save To PDF
                </button>
              </div>
            </div>
          }
        />
        <div className="module-row">
          <div className="module-chart-container border-on-top">
            <Plot
              data={guild2022WalkChartOptions as any}
              layout={{
                title: {
                  text:
                    "<b> <span style='text-decoration:underline;'>" +
                    "Guild Expenses 2022 (bps)" +
                    "</span> </b>",
                  y: 0.97,
                  x: 0.05,
                },
                autosize: true,
                xaxis: {
                  title: {
                    font: {
                      size: 18,
                    },
                  },
                },
                yaxis: {
                  title: {
                    text: "",
                    font: {
                      size: 18,
                    },
                  },
                },
              }}
              useResizeHandler
              config={{
                responsive: true,
                displayModeBar: false,
                staticPlot: true,
              }}
              style={{ width: "100%" }}
            />
          </div>
          <div className="module-chart-container border-on-top">
            <Plot
              data={guild2023WalkChartOptions as any}
              layout={{
                title: {
                  text:
                    "<b> <span style='text-decoration:underline;'>" +
                    "Guild Expenses 2023 (bps)" +
                    "</span> </b>",
                  y: 0.97,
                  x: 0.05,
                },
                autosize: true,
                xaxis: {
                  title: {
                    font: {
                      size: 18,
                    },
                  },
                },
                yaxis: {
                  title: {
                    text: "",
                    font: {
                      size: 18,
                    },
                  },
                },
              }}
              useResizeHandler
              config={{
                responsive: true,
                displayModeBar: false,
                staticPlot: true,
              }}
              style={{ width: "100%" }}
            />
          </div>
        </div>
        <div style={{ display: "none" }}>
          <Plot
            ref={plotlyRefChart1 as any}
            data={guild2022WalkChartOptions as any}
            layout={{
              title: "DTC CM Walk",
              autosize: true,
              margin: {
                t: 50,
              },
              xaxis: {
                tickfont: { size: 8 },
                title: {
                  font: {
                    size: 18,
                  },
                },
              },
              yaxis: {
                title: {
                  text: "",
                  font: {
                    size: 18,
                  },
                },
              },
            }}
            useResizeHandler
            config={{ responsive: true }}
            style={{ width: "100%" }}
          />
          <Plot
            ref={plotlyRefChart2 as any}
            data={guild2023WalkChartOptions as any}
            layout={{
              title: "Partner CM Walk",
              autosize: true,
              margin: {
                t: 50,
              },
              xaxis: {
                tickfont: { size: 8 },
                title: {
                  font: {
                    size: 18,
                  },
                },
              },
              yaxis: {
                title: {
                  text: "",
                  font: {
                    size: 18,
                  },
                },
              },
            }}
            useResizeHandler
            config={{ responsive: true }}
            style={{ width: "100%" }}
          />
        </div>
      </div>
    </div>
  );
};
