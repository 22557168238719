import { useEffect, useState } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import HC_more from "highcharts/highcharts-more";
import "../Module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { Table } from "reactstrap";
import { ResultFromMultipleInputSliders } from "../../../components/generic-financial-model/MultipleInputSliders";
import Switch from "react-switch";
import {
  useLazyXlsxProcessorQuery,
  useXlsxProcessorQuery,
} from "../../../services/serverless";
import { XlsxProcessorRequest } from "../../../services/types/gallus";
import { Error } from "../../../components/Error";

const tableNumberFormat = (data: number, format: string) => {
  switch (format) {
    case "percentage":
      return Math.round(data * 100) + "%";
    case "full":
      if (data < 0) {
        return (
          "($" +
          Math.round(-1 * data)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
          ")"
        );
      } else {
        if (data === 0) {
          return "0";
        } else {
          return (
            "$" +
            Math.round(data)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          );
        }
      }
    case "partial":
      if (data < 0) {
        return (
          "(" +
          Math.round(-1 * data)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
          ")"
        );
      } else {
        if (data === 0) {
          return "0";
        } else {
          return Math.round(data)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
      }
  }
};

interface EbitdaRegCapitalProps {}

interface EbitdaRegCapitalState {
  hudFHANetWorthChartOptions: Highcharts.Options;
  hudFHAMinimumLiquidityChartOptions: Highcharts.Options;
  nyNetWorthChartOptions: Highcharts.Options;
  gnmaNetWorthChartOptions: Highcharts.Options;
  chartsLoadedFlag: boolean;
  Q4Mode: boolean;
  inputValues: number[];
  initialInputValues: number[];
  initialDataLoaded: boolean;
  Q4hudFHANetWorthChartOptions: Highcharts.Options;
  Q4hudFHAMinimumLiquidityChartOptions: Highcharts.Options;
  Q4nyNetWorthChartOptions: Highcharts.Options;
  Q4gnmaNetWorthChartOptions: Highcharts.Options;
  Q4Table: number[];
  Q4ChartsLoadedFlag: boolean;
  errorFlag: boolean;
  errorCode: string;
  errorType: string;
  errorMessage: string;
}

export const EbitdaRegCapital = (props: EbitdaRegCapitalProps) => {
  const [initialData, setInitialData] = useState<XlsxProcessorRequest | null>(
    null,
  );
  const [state, setState] = useState<EbitdaRegCapitalState>({
    hudFHANetWorthChartOptions: {},
    hudFHAMinimumLiquidityChartOptions: {},
    nyNetWorthChartOptions: {},
    gnmaNetWorthChartOptions: {},
    chartsLoadedFlag: false,
    Q4Mode: false,
    inputValues: [],
    initialInputValues: [],
    initialDataLoaded: false,
    Q4hudFHANetWorthChartOptions: {},
    Q4hudFHAMinimumLiquidityChartOptions: {},
    Q4nyNetWorthChartOptions: {},
    Q4gnmaNetWorthChartOptions: {},
    Q4Table: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    Q4ChartsLoadedFlag: false,
    errorFlag: false,
    errorCode: "0",
    errorType: "",
    errorMessage: "",
  });

  const response = useXlsxProcessorQuery({
    DataSource:
      "https://docs.google.com/spreadsheets/d/11tGVBAlviN0zg-p2-WyMIRoFMe4oClcK/",
    InputSheetName: null,
    Inputs: [],
    OutputSheetName: null,
    Outputs: [
      { SheetName: "Inputs", RowIndex: 8, CellIndex: 3, Value: null },
      { SheetName: "Inputs", RowIndex: 9, CellIndex: 3, Value: null },
    ],
  });

  useEffect(() => {
    if (response.isSuccess && response.status === "fulfilled") {
      setInitialData(response.data);
      let inputValuesArray: number[] = [
        response.data.Outputs[0].Value,
        response.data.Outputs[1].Value,
      ];
      setState({
        ...state,
        inputValues: inputValuesArray,
        initialInputValues: inputValuesArray,
        initialDataLoaded: true,
        errorFlag: false,
      });
    } else if (response.isError) {
      console.log("FAILED to initialize parameters");
      console.log(response.error);
      setState({
        ...state,
        errorFlag: true,
        errorCode: "500",
        errorType: "Internal",
        errorMessage: "FAILED to initialize parameters",
      });
    }
  }, [response]);

  useEffect(() => {
    if (state.initialDataLoaded) {
      getValuesTLMQ22022();
    }
  }, [state.initialDataLoaded]);

  const [xlsxProcessorQueryForTLMQ42022, xlsxProcessorQueryForTLMQ42022Result] =
    useLazyXlsxProcessorQuery();

  async function getValuesTLMQ42022() {
    if (initialData !== null) {
      xlsxProcessorQueryForTLMQ42022({
        DataSource:
          "https://docs.google.com/spreadsheets/d/11tGVBAlviN0zg-p2-WyMIRoFMe4oClcK/",
        InputSheetName: null,
        Inputs: [
          {
            SheetName: "Inputs",
            RowIndex: 8,
            CellIndex: 3,
            Value: state.inputValues[0],
          },
          {
            SheetName: "Inputs",
            RowIndex: 9,
            CellIndex: 3,
            Value: state.inputValues[1],
          },
        ],
        OutputSheetName: null,
        Outputs: [
          {
            SheetName: "Html-Reg Cap Req",
            RowIndex: 6,
            CellIndex: 2,
            Value: null,
          }, //graph 1 HUD & FHA Net Worth
          {
            SheetName: "Html-Reg Cap Req",
            RowIndex: 7,
            CellIndex: 2,
            Value: null,
          },
          {
            SheetName: "Html-Reg Cap Req",
            RowIndex: 8,
            CellIndex: 2,
            Value: null,
          },
          {
            SheetName: "Html-Reg Cap Req",
            RowIndex: 9,
            CellIndex: 2,
            Value: null,
          },
          {
            SheetName: "Html-Reg Cap Req",
            RowIndex: 10,
            CellIndex: 2,
            Value: null,
          },
          {
            SheetName: "Html-Reg Cap Req",
            RowIndex: 11,
            CellIndex: 2,
            Value: null,
          },
          {
            SheetName: "Html-Reg Cap Req",
            RowIndex: 12,
            CellIndex: 2,
            Value: null,
          },
          {
            SheetName: "Html-Reg Cap Req",
            RowIndex: 18,
            CellIndex: 5,
            Value: null,
          }, //graph 2 NY Net Worth
          {
            SheetName: "Html-Reg Cap Req",
            RowIndex: 19,
            CellIndex: 5,
            Value: null,
          },
          {
            SheetName: "Html-Reg Cap Req",
            RowIndex: 20,
            CellIndex: 5,
            Value: null,
          },
          {
            SheetName: "Html-Reg Cap Req",
            RowIndex: 21,
            CellIndex: 5,
            Value: null,
          },
          {
            SheetName: "Html-Reg Cap Req",
            RowIndex: 22,
            CellIndex: 5,
            Value: null,
          },
          {
            SheetName: "Html-Reg Cap Req",
            RowIndex: 23,
            CellIndex: 5,
            Value: null,
          },
          {
            SheetName: "Html-Reg Cap Req",
            RowIndex: 24,
            CellIndex: 5,
            Value: null,
          },
          {
            SheetName: "Html-Reg Cap Req",
            RowIndex: 29,
            CellIndex: 9,
            Value: null,
          }, //graph 3 GNMA Net Worth
          {
            SheetName: "Html-Reg Cap Req",
            RowIndex: 30,
            CellIndex: 9,
            Value: null,
          },
          {
            SheetName: "Html-Reg Cap Req",
            RowIndex: 31,
            CellIndex: 9,
            Value: null,
          },
          {
            SheetName: "Html-Reg Cap Req",
            RowIndex: 32,
            CellIndex: 9,
            Value: null,
          },
          {
            SheetName: "Html-Reg Cap Req",
            RowIndex: 33,
            CellIndex: 9,
            Value: null,
          },
          {
            SheetName: "Html-Reg Cap Req",
            RowIndex: 34,
            CellIndex: 9,
            Value: null,
          },
          {
            SheetName: "Html-Reg Cap Req",
            RowIndex: 35,
            CellIndex: 9,
            Value: null,
          },
          {
            SheetName: "Html-Reg Cap Req",
            RowIndex: 45,
            CellIndex: 12,
            Value: null,
          }, //graph 4 Minimun Liquidity
          {
            SheetName: "Html-Reg Cap Req",
            RowIndex: 45,
            CellIndex: 13,
            Value: null,
          },
          {
            SheetName: "Html-Reg Cap Req",
            RowIndex: 46,
            CellIndex: 12,
            Value: null,
          },
          {
            SheetName: "Html-Reg Cap Req",
            RowIndex: 46,
            CellIndex: 13,
            Value: null,
          },
          {
            SheetName: "Html-Reg Cap Req",
            RowIndex: 54,
            CellIndex: 16,
            Value: null,
          }, //graph 5 GNMA Ratios First
          {
            SheetName: "Html-Reg Cap Req",
            RowIndex: 55,
            CellIndex: 16,
            Value: null,
          },
          {
            SheetName: "Html-Reg Cap Req",
            RowIndex: 56,
            CellIndex: 16,
            Value: null,
          },
          {
            SheetName: "Html-Reg Cap Req",
            RowIndex: 57,
            CellIndex: 16,
            Value: null,
          },
          {
            SheetName: "Html-Reg Cap Req",
            RowIndex: 58,
            CellIndex: 16,
            Value: null,
          },
          {
            SheetName: "Html-Reg Cap Req",
            RowIndex: 54,
            CellIndex: 19,
            Value: null,
          }, //graph 5 GNMA Ratios Second
          {
            SheetName: "Html-Reg Cap Req",
            RowIndex: 55,
            CellIndex: 19,
            Value: null,
          },
          {
            SheetName: "Html-Reg Cap Req",
            RowIndex: 56,
            CellIndex: 19,
            Value: null,
          },
          {
            SheetName: "Html-Reg Cap Req",
            RowIndex: 57,
            CellIndex: 19,
            Value: null,
          },
          {
            SheetName: "Html-Reg Cap Req",
            RowIndex: 58,
            CellIndex: 19,
            Value: null,
          },
        ],
      });
    }
  }

  useEffect(() => {
    if (
      xlsxProcessorQueryForTLMQ42022Result.isSuccess &&
      xlsxProcessorQueryForTLMQ42022Result.status === "fulfilled"
    ) {
      Highcharts.setOptions({
        lang: {
          decimalPoint: ".",
          thousandsSep: ",",
        },
      });

      setState({
        ...state,
        Q4hudFHANetWorthChartOptions: {
          chart: {
            type: "waterfall",
          },

          tooltip: {
            enabled: false,
          },

          title: {
            text: "HUD & FHA Net Worth ($ in M)",
            align: "left",
            style: {
              fontSize: "20",
            },
          },

          xAxis: {
            type: "category",
            lineWidth: 3,
            lineColor: "#000000",
            labels: {
              style: {
                fontSize: "16",
                fontWeight: "bold",
              },
            },
          },

          yAxis: {
            visible: true,
            title: {
              text: "USD",
            },
            plotLines: [
              {
                value: 0,
                width: 2,
                color: "#000000",
                zIndex: 10,
              },
            ],
            labels: {
              formatter: function () {
                var label: string = this.axis.defaultLabelFormatter.call(this);
                if (/-/.test(label)) {
                  return "($" + label.substring(1).slice(0, -1) + ")";
                } else {
                  var value: string;
                  if (label !== "0") {
                    value = "$" + label.slice(0, -1);
                  } else {
                    value = label.slice(0, -1);
                  }
                  return value;
                }
              },
            },
          },

          legend: {
            enabled: false,
          },

          series: [
            {
              type: "waterfall",
              upColor: "#4273E8",

              data: [
                {
                  name: "Equity",
                  y: xlsxProcessorQueryForTLMQ42022Result.data.Outputs[0].Value,
                  color: "#1c40ca",
                },
                {
                  name: "Due from Parent",
                  y: xlsxProcessorQueryForTLMQ42022Result.data.Outputs[1].Value,
                  color: "#ff0000",
                },
                {
                  name: "Net Worth",
                  isSum: true, //y: data.Outputs[2].Value,
                  color: "#9c9c9c",
                },
                {
                  name: "Unac. Assets",
                  y: xlsxProcessorQueryForTLMQ42022Result.data.Outputs[3].Value,
                  color: "#ff0000",
                },
                {
                  name: "Adj. Net Worth",
                  isSum: true, //y:data.Outputs[4].Value,
                  color: "#9c9c9c",
                },
                {
                  name: "Min Net Worth",
                  y: xlsxProcessorQueryForTLMQ42022Result.data.Outputs[5].Value,
                  color: "#ff0000",
                },
                {
                  name: "Excess Net Worth",
                  isSum: true,
                  color: "#9c9c9c",
                },
              ],
              dataLabels: {
                enabled: true,
                formatter: function () {
                  if (this.y! < 0) {
                    return "($" + Math.round((-1 * this.y!) / 100) / 10 + ")";
                  } else {
                    return "$" + Math.round(this.y! / 100) / 10;
                  }
                },
                style: {
                  fontSize: "16",
                  fontWeight: "bold",
                },
              },
              pointPadding: 0,
            },
          ],
        },
        Q4hudFHAMinimumLiquidityChartOptions: {
          chart: {
            type: "column",
          },
          title: {
            text: "HUD & FHA Minimum Liquidity ($ in M)",
            align: "left",
            style: {
              fontSize: "20",
            },
          },

          tooltip: {
            enabled: false,
          },
          xAxis: {
            lineWidth: 3,
            lineColor: "#000000",
            categories: ["HUD & FHA", "GNMA"],
            labels: {
              style: {
                fontSize: "16",
                fontWeight: "bold",
              },
            },
            crosshair: true,
          },
          yAxis: {
            visible: false,
            title: {
              useHTML: true,
              text: "",
            },
            labels: {
              formatter: function () {
                var label: string = this.axis.defaultLabelFormatter.call(this);
                if (/-/.test(label)) {
                  return "(" + label.substring(1) + ")";
                } else {
                  return label;
                }
              },
            },
          },
          plotOptions: {
            column: {
              pointWidth: 100,
              pointPadding: 0.2,
              borderWidth: 1,
              borderColor: "#000000",
            },
          },
          series: [
            {
              type: "column",
              name: "Cash & Equivalent",
              data: [
                xlsxProcessorQueryForTLMQ42022Result.data.Outputs[21].Value,
                xlsxProcessorQueryForTLMQ42022Result.data.Outputs[22].Value,
              ],
              color: "#1c40ca",
              dataLabels: {
                enabled: true,
                formatter: function () {
                  if (this.y! < 0) {
                    return "($" + Math.round((-1 * this.y!) / 100) / 10 + ")";
                  } else {
                    return "$" + Math.round(this.y! / 100) / 10;
                  }
                },
                style: {
                  fontSize: "16",
                  fontWeight: "bold",
                },
              },
            },
            {
              type: "column",
              name: "Minimum Liquidity",
              data: [
                xlsxProcessorQueryForTLMQ42022Result.data.Outputs[23].Value,
                xlsxProcessorQueryForTLMQ42022Result.data.Outputs[24].Value,
              ],
              color: "#00a0ea",
              dataLabels: {
                enabled: true,
                formatter: function () {
                  if (this.y! < 0) {
                    return "($" + Math.round((-1 * this.y!) / 100) / 10 + ")";
                  } else {
                    return "$" + Math.round(this.y! / 100) / 10;
                  }
                },
                style: {
                  fontSize: "16",
                  fontWeight: "bold",
                },
              },
            },
          ],
        },
        Q4nyNetWorthChartOptions: {
          chart: {
            type: "waterfall",
          },

          tooltip: {
            enabled: false,
          },

          title: {
            text: "NY Net Worth ($ in M)",
            align: "left",
            style: {
              fontSize: "20",
            },
          },

          xAxis: {
            type: "category",
            lineWidth: 3,
            lineColor: "#000000",
            labels: {
              style: {
                fontSize: "16",
                fontWeight: "bold",
              },
            },
          },

          yAxis: {
            visible: true,
            title: {
              text: "USD",
            },
            plotLines: [
              {
                value: 0,
                width: 2,
                color: "#000000",
                zIndex: 10,
              },
            ],
            labels: {
              formatter: function () {
                var label: string = this.axis.defaultLabelFormatter.call(this);
                if (/-/.test(label)) {
                  return "($" + label.substring(1).slice(0, -1) + ")";
                } else {
                  var value: string;
                  if (label !== "0") {
                    value = "$" + label.slice(0, -1);
                  } else {
                    value = label.slice(0, -1);
                  }
                  return value;
                }
              },
            },
          },

          legend: {
            enabled: false,
          },

          series: [
            {
              type: "waterfall",
              upColor: "#4273E8",

              data: [
                {
                  name: "Equity",
                  y: xlsxProcessorQueryForTLMQ42022Result.data.Outputs[7].Value,
                  color: "#1c40ca",
                },
                {
                  name: "Due from Parent",
                  y: xlsxProcessorQueryForTLMQ42022Result.data.Outputs[8].Value,
                  color: "#ff0000",
                },
                {
                  name: "Net Worth",
                  isSum: true, //y: data.Outputs[9].Value,
                  color: "#9c9c9c",
                },
                {
                  name: "Unac. Assets",
                  y: xlsxProcessorQueryForTLMQ42022Result.data.Outputs[10]
                    .Value,
                  color: "#ff0000",
                },
                {
                  name: "Adj. Net Worth",
                  isSum: true, //y:data.Outputs[11].Value,
                  color: "#9c9c9c",
                },
                {
                  name: "Min Net Worth",
                  y: xlsxProcessorQueryForTLMQ42022Result.data.Outputs[12]
                    .Value,
                  color: "#ff0000",
                },
                {
                  name: "Excess Net Worth",
                  isSum: true, //y:data.Outputs[13].Value
                  color: "#9c9c9c",
                },
              ],
              dataLabels: {
                enabled: true,
                formatter: function () {
                  if (this.y! < 0) {
                    return "($" + Math.round((-1 * this.y!) / 100) / 10 + ")";
                  } else {
                    return "$" + Math.round(this.y! / 100) / 10;
                  }
                },
                style: {
                  fontSize: "16",
                  fontWeight: "bold",
                },
              },
              pointPadding: 0,
            },
          ],
        },
        Q4gnmaNetWorthChartOptions: {
          chart: {
            type: "waterfall",
          },

          tooltip: {
            enabled: false,
          },

          title: {
            text: "GNMA Net Worth ($ in M)",
            align: "left",
            style: {
              fontSize: "20",
            },
          },

          xAxis: {
            type: "category",
            lineWidth: 3,
            lineColor: "#000000",
            labels: {
              style: {
                fontSize: "16",
                fontWeight: "bold",
              },
            },
          },

          yAxis: {
            visible: true,
            title: {
              text: "USD",
            },
            plotLines: [
              {
                value: 0,
                width: 2,
                color: "#000000",
                zIndex: 10,
              },
            ],
            labels: {
              formatter: function () {
                var label: string = this.axis.defaultLabelFormatter.call(this);
                if (/-/.test(label)) {
                  return "($" + label.substring(1).slice(0, -1) + ")";
                } else {
                  var value: string;
                  if (label !== "0") {
                    value = "$" + label.slice(0, -1);
                  } else {
                    value = label.slice(0, -1);
                  }
                  return value;
                }
              },
            },
          },

          legend: {
            enabled: false,
          },

          series: [
            {
              type: "waterfall",
              upColor: "#4273E8",

              data: [
                {
                  name: "Equity",
                  y: xlsxProcessorQueryForTLMQ42022Result.data.Outputs[14]
                    .Value,
                  color: "#1c40ca",
                },
                {
                  name: "Due from Parent",
                  y: xlsxProcessorQueryForTLMQ42022Result.data.Outputs[15]
                    .Value,
                  color: "#ff0000",
                },
                {
                  name: "Net Worth",
                  isSum: true, //y: data.Outputs[16].Value,
                  color: "#9c9c9c",
                },
                {
                  name: "Unac. Assets",
                  y: xlsxProcessorQueryForTLMQ42022Result.data.Outputs[17]
                    .Value,
                  color: "#ff0000",
                },
                {
                  name: "Adj. Net Worth",
                  isSum: true, //y:data.Outputs[18].Value,
                  color: "#9c9c9c",
                },
                {
                  name: "Min Net Worth",
                  y: xlsxProcessorQueryForTLMQ42022Result.data.Outputs[19]
                    .Value,
                  color: "#ff0000",
                },
                {
                  name: "Excess Net Worth",
                  isSum: true, //y:data.Outputs[20].Value
                  color: "#9c9c9c",
                },
              ],
              dataLabels: {
                enabled: true,
                formatter: function () {
                  if (this.y! < 0) {
                    return "($" + Math.round((-1 * this.y!) / 100) / 10 + ")";
                  } else {
                    return "$" + Math.round(this.y! / 100) / 10;
                  }
                },
                style: {
                  fontSize: "16",
                  fontWeight: "bold",
                },
              },
              pointPadding: 0,
            },
          ],
        },
        Q4ChartsLoadedFlag: true,
        Q4Table: [
          xlsxProcessorQueryForTLMQ42022Result.data.Outputs[25].Value,
          xlsxProcessorQueryForTLMQ42022Result.data.Outputs[26].Value,
          xlsxProcessorQueryForTLMQ42022Result.data.Outputs[27].Value,
          xlsxProcessorQueryForTLMQ42022Result.data.Outputs[28].Value,
          xlsxProcessorQueryForTLMQ42022Result.data.Outputs[29].Value,
          xlsxProcessorQueryForTLMQ42022Result.data.Outputs[30].Value,
          xlsxProcessorQueryForTLMQ42022Result.data.Outputs[31].Value,
          xlsxProcessorQueryForTLMQ42022Result.data.Outputs[32].Value,
          xlsxProcessorQueryForTLMQ42022Result.data.Outputs[33].Value,
          xlsxProcessorQueryForTLMQ42022Result.data.Outputs[34].Value,
        ],
        errorFlag: false,
      });
    } else if (xlsxProcessorQueryForTLMQ42022Result.isError) {
      console.log("FAILED to initialize parameters");
      console.log(xlsxProcessorQueryForTLMQ42022Result.error);
      setState({
        ...state,
        errorFlag: true,
        errorCode: "500",
        errorType: "Internal",
        errorMessage: "FAILED to initialize parameters",
      });
    }
  }, [xlsxProcessorQueryForTLMQ42022Result]);

  async function getValuesTLMQ22022() {
    /*
        xlsxProcessor({
            "AccessToken": getUserSessionItem('accessToken'),
            "DataSource": "Core.Celeri_Debt_EBITDA_Model_v37.xlsx",
            "InputSheetName": null,
            "Inputs": [],
            "OutputSheetName": null,
            "Outputs": [
                { "SheetName": "HTML_Reg_Cap_Detail", "RowIndex": 5, "CellIndex": 3, "Value": null },   //graph 1
                { "SheetName": "HTML_Reg_Cap_Detail", "RowIndex": 6, "CellIndex": 3, "Value": null },
                { "SheetName": "HTML_Reg_Cap_Detail", "RowIndex": 7, "CellIndex": 3, "Value": null },
                { "SheetName": "HTML_Reg_Cap_Detail", "RowIndex": 8, "CellIndex": 3, "Value": null },
                { "SheetName": "HTML_Reg_Cap_Detail", "RowIndex": 9, "CellIndex": 3, "Value": null },
                { "SheetName": "HTML_Reg_Cap_Detail", "RowIndex": 10, "CellIndex": 3, "Value": null },
                { "SheetName": "HTML_Reg_Cap_Detail", "RowIndex": 11, "CellIndex": 3, "Value": null },
                { "SheetName": "HTML_Reg_Cap_Detail", "RowIndex": 15, "CellIndex": 9, "Value": null }, //graph 2
                { "SheetName": "HTML_Reg_Cap_Detail", "RowIndex": 16, "CellIndex": 9, "Value": null },
                { "SheetName": "HTML_Reg_Cap_Detail", "RowIndex": 20, "CellIndex": 12, "Value": null }, //graph 3
                { "SheetName": "HTML_Reg_Cap_Detail", "RowIndex": 21, "CellIndex": 12, "Value": null },
                { "SheetName": "HTML_Reg_Cap_Detail", "RowIndex": 22, "CellIndex": 12, "Value": null },
                { "SheetName": "HTML_Reg_Cap_Detail", "RowIndex": 23, "CellIndex": 12, "Value": null },
                { "SheetName": "HTML_Reg_Cap_Detail", "RowIndex": 24, "CellIndex": 12, "Value": null },
                { "SheetName": "HTML_Reg_Cap_Detail", "RowIndex": 25, "CellIndex": 12, "Value": null },
                { "SheetName": "HTML_Reg_Cap_Detail", "RowIndex": 26, "CellIndex": 12, "Value": null },
                { "SheetName": "HTML_Reg_Cap_Detail", "RowIndex": 30, "CellIndex": 16, "Value": null }, //graph 4
                { "SheetName": "HTML_Reg_Cap_Detail", "RowIndex": 31, "CellIndex": 16, "Value": null },
                { "SheetName": "HTML_Reg_Cap_Detail", "RowIndex": 32, "CellIndex": 16, "Value": null },
                { "SheetName": "HTML_Reg_Cap_Detail", "RowIndex": 33, "CellIndex": 16, "Value": null },
                { "SheetName": "HTML_Reg_Cap_Detail", "RowIndex": 34, "CellIndex": 16, "Value": null },
                { "SheetName": "HTML_Reg_Cap_Detail", "RowIndex": 35, "CellIndex": 16, "Value": null },
                { "SheetName": "HTML_Reg_Cap_Detail", "RowIndex": 36, "CellIndex": 16, "Value": null },
            ]
        }).then(data => {*/

    ////
    let data = [
      62.1, -37.4, 24.7, -13.7, 11.1, -2.5, 8.6, 11.5, 11.5, 0.5, 1.0, 62.1,
      -37.4, 24.7, -11.6, 13.1, -8.8, 4.3, 62.1, -37.4, 24.7, -11.6, 13.1, -5.0,
      8.1,
    ];
    ////

    Highcharts.setOptions({
      lang: {
        decimalPoint: ".",
        thousandsSep: ",",
      },
    });

    HC_more(Highcharts);

    setState({
      ...state,
      hudFHANetWorthChartOptions: {
        chart: {
          type: "waterfall",
        },

        tooltip: {
          enabled: false,
        },

        title: {
          text: "HUD & FHA Net Worth ($ in M)",
          align: "left",
          style: {
            fontSize: "20",
          },
        },

        xAxis: {
          type: "category",
          lineWidth: 3,
          lineColor: "#000000",
          labels: {
            style: {
              fontSize: "16",
              fontWeight: "bold",
            },
          },
        },

        yAxis: {
          visible: true,
          title: {
            text: "USD",
          },
          plotLines: [
            {
              value: 0,
              width: 2,
              color: "#000000",
              zIndex: 10,
            },
          ],
          labels: {
            formatter: function () {
              var label: string = this.axis.defaultLabelFormatter.call(this);
              if (/-/.test(label)) {
                return "($" + label.substring(1) + ")";
              } else {
                var value: string;
                if (label !== "0") {
                  value = "$" + label;
                } else {
                  value = label;
                }
                return value;
              }
            },
          },
        },

        legend: {
          enabled: false,
        },

        series: [
          {
            type: "waterfall",
            upColor: "#4273E8",

            data: [
              {
                name: "Equity",
                y: data[0],
                color: "#1c40ca",
              },
              {
                name: "Due from Parent",
                y: data[1],
                color: "#ff0000",
              },
              {
                name: "Net Worth",
                isSum: true, //y: data[2],
                color: "#9c9c9c",
              },
              {
                name: "Unac. Assets",
                y: data[3],
                color: "#ff0000",
              },
              {
                name: "Adj. Net Worth",
                isSum: true, //y:data[4],
                color: "#9c9c9c",
              },
              {
                name: "Min Net Worth",
                y: data[5],
                color: "#ff0000",
              },
              {
                name: "Excess Net Worth",
                isSum: true,
                color: "#9c9c9c",
              },
            ],
            dataLabels: {
              enabled: true,
              formatter: function () {
                if (this.y! < 0) {
                  return "($" + this.y! * -1 + ")";
                } else {
                  return "$" + this.y!;
                }
              },
              style: {
                fontSize: "16",
                fontWeight: "bold",
              },
            },
            pointPadding: 0,
          },
        ],
      },
      hudFHAMinimumLiquidityChartOptions: {
        chart: {
          type: "column",
        },
        title: {
          text: "HUD & FHA Minimum Liquidity ($ in M)",
          align: "left",
          style: {
            fontSize: "20",
          },
        },

        tooltip: {
          enabled: false,
        },
        xAxis: {
          lineWidth: 3,
          lineColor: "#000000",
          categories: ["HUD & FHA", "GNMA"],
          labels: {
            style: {
              fontSize: "16",
              fontWeight: "bold",
            },
          },
          crosshair: true,
        },
        yAxis: {
          visible: false,
          title: {
            useHTML: true,
            text: "",
          },
          labels: {
            formatter: function () {
              var label: string = this.axis.defaultLabelFormatter.call(this);
              if (/-/.test(label)) {
                return "(" + label.substring(1) + ")";
              } else {
                return label;
              }
            },
          },
        },
        plotOptions: {
          column: {
            pointWidth: 100,
            pointPadding: 0.2,
            borderWidth: 1,
            borderColor: "#000000",
          },
        },
        series: [
          {
            type: "column",
            name: "Cash & Equivalent",
            data: [data[7], data[8]],
            color: "#1c40ca",
            dataLabels: {
              enabled: true,
              formatter: function () {
                if (this.y! < 0) {
                  return "($" + Highcharts.numberFormat(this.y! * -1, 1) + ")";
                } else {
                  return "$" + Highcharts.numberFormat(this.y!, 1);
                }
              },
              style: {
                fontSize: "16",
                fontWeight: "bold",
              },
            },
          },
          {
            type: "column",
            name: "Minimum Liquidity",
            data: [data[9], data[10]],
            color: "#00a0ea",
            dataLabels: {
              enabled: true,
              formatter: function () {
                if (this.y! < 0) {
                  return "($" + Highcharts.numberFormat(this.y! * -1, 1) + ")";
                } else {
                  return "$" + Highcharts.numberFormat(this.y!, 1);
                }
              },
              style: {
                fontSize: "16",
                fontWeight: "bold",
              },
            },
          },
        ],
      },
      nyNetWorthChartOptions: {
        chart: {
          type: "waterfall",
        },

        tooltip: {
          enabled: false,
        },

        title: {
          text: "NY Net Worth ($ in M)",
          align: "left",
          style: {
            fontSize: "20",
          },
        },

        xAxis: {
          type: "category",
          lineWidth: 3,
          lineColor: "#000000",
          labels: {
            style: {
              fontSize: "16",
              fontWeight: "bold",
            },
          },
        },

        yAxis: {
          visible: true,
          title: {
            text: "USD",
          },
          plotLines: [
            {
              value: 0,
              width: 2,
              color: "#000000",
              zIndex: 10,
            },
          ],
          labels: {
            formatter: function () {
              var label: string = this.axis.defaultLabelFormatter.call(this);
              if (/-/.test(label)) {
                return "($" + label.substring(1) + ")";
              } else {
                var value: string;
                if (label !== "0") {
                  value = "$" + label;
                } else {
                  value = label;
                }
                return value;
              }
            },
          },
        },

        legend: {
          enabled: false,
        },

        series: [
          {
            type: "waterfall",
            upColor: "#4273E8",

            data: [
              {
                name: "Equity",
                y: data[11],
                color: "#1c40ca",
              },
              {
                name: "Due from Parent",
                y: data[12],
                color: "#ff0000",
              },
              {
                name: "Net Worth",
                isSum: true, //y: data[13],
                color: "#9c9c9c",
              },
              {
                name: "Unac. Assets",
                y: data[14],
                color: "#ff0000",
              },
              {
                name: "Adj. Net Worth",
                isSum: true, //y:data[15],
                color: "#9c9c9c",
              },
              {
                name: "Min Net Worth",
                y: data[16],
                color: "#ff0000",
              },
              {
                name: "Excess Net Worth",
                isSum: true, //y:data[17]
                color: "#9c9c9c",
              },
            ],
            dataLabels: {
              enabled: true,
              formatter: function () {
                if (this.y! < 0) {
                  return "($" + this.y! * -1 + ")";
                } else {
                  return "$" + this.y!;
                }
              },
              style: {
                fontSize: "16",
                fontWeight: "bold",
              },
            },
            pointPadding: 0,
          },
        ],
      },
      gnmaNetWorthChartOptions: {
        chart: {
          type: "waterfall",
        },

        tooltip: {
          enabled: false,
        },

        title: {
          text: "GNMA Net Worth ($ in M)",
          align: "left",
          style: {
            fontSize: "20",
          },
        },

        xAxis: {
          type: "category",
          lineWidth: 3,
          lineColor: "#000000",
          labels: {
            style: {
              fontSize: "16",
              fontWeight: "bold",
            },
          },
        },

        yAxis: {
          visible: true,
          title: {
            text: "USD",
          },
          plotLines: [
            {
              value: 0,
              width: 2,
              color: "#000000",
              zIndex: 10,
            },
          ],
          labels: {
            formatter: function () {
              var label: string = this.axis.defaultLabelFormatter.call(this);
              if (/-/.test(label)) {
                return "($" + label.substring(1) + ")";
              } else {
                var value: string;
                if (label !== "0") {
                  value = "$" + label;
                } else {
                  value = label;
                }
                return value;
              }
            },
          },
        },

        legend: {
          enabled: false,
        },

        series: [
          {
            type: "waterfall",
            upColor: "#4273E8",

            data: [
              {
                name: "Equity",
                y: data[18],
                color: "#1c40ca",
              },
              {
                name: "Due from Parent",
                y: data[19],
                color: "#ff0000",
              },
              {
                name: "Net Worth",
                isSum: true, //y: data[20],
                color: "#9c9c9c",
              },
              {
                name: "Unac. Assets",
                y: data[21],
                color: "#ff0000",
              },
              {
                name: "Adj. Net Worth",
                isSum: true, //y:data[22],
                color: "#9c9c9c",
              },
              {
                name: "Min Net Worth",
                y: data[23],
                color: "#ff0000",
              },
              {
                name: "Excess Net Worth",
                isSum: true, //y:data[24]
                color: "#9c9c9c",
              },
            ],
            dataLabels: {
              enabled: true,
              formatter: function () {
                if (this.y! < 0) {
                  return "($" + this.y! * -1 + ")";
                } else {
                  return "$" + this.y!;
                }
              },
              style: {
                fontSize: "16",
                fontWeight: "bold",
              },
            },
            pointPadding: 0,
          },
        ],
      },
      chartsLoadedFlag: true,
    });
    /*
                    }).catch(err => {
                        console.log('FAILED to initialize parameters');
                        console.log(err);
                    });*/
  }

  const handleChange = (newValue: number[]) => {
    setState({ ...state, inputValues: newValue });
  };

  return (
    <div className="module-master">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          {!state.Q4Mode ? (
            <div className="module-title">
              Regulatory Capital Requirement (Q2 2022)
            </div>
          ) : (
            <div className="module-title">
              Regulatory Capital Requirement (Q4 2022)
            </div>
          )}
        </div>
        <div style={{ display: "flex", padding: 20 }} data-toggle="buttons">
          <div>LTM 4Q 2022</div>
          <div>
            <Switch
              onChange={(e) => setState({ ...state, Q4Mode: !state.Q4Mode })}
              checked={state.Q4Mode}
              checkedIcon={false}
              uncheckedIcon={false}
            />
          </div>
        </div>
      </div>

      <div>
        {!(state.chartsLoadedFlag && state.initialDataLoaded) && (
          <div className="loading-div">
            <FontAwesomeIcon
              icon={faSpinner}
              size="2x"
              spin={true}
              style={{ margin: "0 auto" }}
            />
          </div>
        )}
        {state.errorFlag && (
          <Error
            code={state.errorCode}
            type={state.errorType}
            message={state.errorMessage}
          />
        )}
        {state.chartsLoadedFlag &&
          state.initialDataLoaded &&
          !state.errorFlag && (
            <div style={{ width: "100%", height: "100%" }}>
              <div
                style={{
                  width: "100%",
                  display: !state.Q4Mode ? "block" : "none",
                }}
              >
                <div className="module-chart-container">
                  <HighchartsReact
                    highcharts={Highcharts}
                    constructorType={"chart"}
                    options={state.hudFHANetWorthChartOptions}
                  />
                </div>
                <div className="module-chart-container">
                  <HighchartsReact
                    highcharts={Highcharts}
                    constructorType={"chart"}
                    options={state.nyNetWorthChartOptions}
                  />
                </div>
                <div className="module-chart-container">
                  <HighchartsReact
                    highcharts={Highcharts}
                    constructorType={"chart"}
                    options={state.gnmaNetWorthChartOptions}
                  />
                </div>
                <div className="module-row">
                  <div className="module-chart-container">
                    <HighchartsReact
                      highcharts={Highcharts}
                      constructorType={"chart"}
                      options={state.hudFHAMinimumLiquidityChartOptions}
                    />
                  </div>
                  <div className="module-center-table">
                    <div style={{ padding: "5px" }}>
                      <div className="module-table-2-header">
                        <div className="module-table-2-title">GNMA Ratios</div>
                        <div className="module-table-2-subtitle">
                          ($ in 000s)
                        </div>
                      </div>
                      <div className="module-center-table">
                        <div className="module-table-2-align-right">
                          <Table responsive>
                            <thead></thead>
                            <tbody>
                              <tr>
                                <th scope="row">Adjusted Net Worth</th>
                                <td className="module-table-2-number">
                                  $13,097
                                </td>
                              </tr>
                              <tr className="module-table-2-bottom-line">
                                <th scope="row">(/) Total Assets</th>
                                <td className="module-table-2-number">
                                  44,467
                                </td>
                              </tr>
                              <tr className="module-table-2-sum">
                                <th scope="row">
                                  % Adj. Net Worth/Total Assets
                                </th>
                                <td className="module-table-2-number">
                                  29.45%
                                </td>
                              </tr>
                              <tr className="module-table-2-bottom-line">
                                <th scope="row">Min. % Req.</th>
                                <td className="module-table-2-blue module-table-2-number">
                                  6.00%
                                </td>
                              </tr>
                              <tr className="module-table-2-sum">
                                <th scope="row">Amt. Above Min. % Req.</th>
                                <td className="module-table-2-number">
                                  23.45%
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                        <div className="module-table-2-align-right">
                          <Table responsive>
                            <thead></thead>
                            <tbody>
                              <tr>
                                <th scope="row">Liquid Assets</th>
                                <td className="module-table-2-number">
                                  $11,548
                                </td>
                              </tr>
                              <tr className="module-table-2-bottom-line">
                                <th scope="row">(/) Required Net Worth</th>
                                <td className="module-table-2-blue module-table-2-number">
                                  5,000
                                </td>
                              </tr>
                              <tr className="module-table-2-sum">
                                <th scope="row">
                                  % Liquid Assets / Req. Net Worth
                                </th>
                                <td className="module-table-2-number">
                                  230.96%
                                </td>
                              </tr>
                              <tr className="module-table-2-bottom-line">
                                <th scope="row">Min. % Req.</th>
                                <td className="module-table-2-blue module-table-2-number">
                                  20.00%
                                </td>
                              </tr>
                              <tr className="module-table-2-sum">
                                <th scope="row">Amt. Above Min. % Req.</th>
                                <td className="module-table-2-number">
                                  210.96%
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ display: state.Q4Mode ? "block" : "none" }}>
                <div className="module-input-sliders">
                  <ResultFromMultipleInputSliders
                    data={[
                      {
                        startingValue: state.initialInputValues[0],
                        firstSymbol: "$",
                        secondSymbol: "K",
                        label: "Insurance Payment",
                      },
                      {
                        startingValue: state.initialInputValues[1],
                        firstSymbol: "$",
                        secondSymbol: "K",
                        label: "Settlement",
                      },
                    ]}
                    resultResolver={(values) => 0}
                    showLabels={true}
                    result={{
                      firstSymbol: "",
                      secondSymbol: "",
                      label: "",
                    }}
                    showResult={false}
                    onNewValuesChange={(values) => handleChange(values)}
                    columnlabel={""}
                    vertical={false}
                  />
                  <div
                    style={{ display: "flex", padding: 20 }}
                    data-toggle="buttons"
                  >
                    <button
                      className="btn btn-primary"
                      onClick={getValuesTLMQ42022}
                    >
                      Run Model
                    </button>
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: state.Q4ChartsLoadedFlag ? "block" : "none",
                  }}
                >
                  <div className="module-chart-container">
                    <HighchartsReact
                      highcharts={Highcharts}
                      constructorType={"chart"}
                      options={state.Q4hudFHANetWorthChartOptions}
                    />
                  </div>
                  <div className="module-chart-container">
                    <HighchartsReact
                      highcharts={Highcharts}
                      constructorType={"chart"}
                      options={state.Q4nyNetWorthChartOptions}
                    />
                  </div>
                  <div className="module-chart-container">
                    <HighchartsReact
                      highcharts={Highcharts}
                      constructorType={"chart"}
                      options={state.Q4gnmaNetWorthChartOptions}
                    />
                  </div>
                  <div className="module-row">
                    <div className="module-chart-container">
                      <HighchartsReact
                        highcharts={Highcharts}
                        constructorType={"chart"}
                        options={state.Q4hudFHAMinimumLiquidityChartOptions}
                      />
                    </div>
                    <div
                      style={{
                        fontSize: "large",
                        width: "100%",
                        padding: "2px",
                      }}
                    >
                      <div style={{ padding: "5px" }}>
                        <div>
                          <div className="module-table-2-title">
                            GNMA Ratios
                          </div>
                          <div>($ in 000s)</div>
                        </div>
                        <div className="module-center-table">
                          <div className="module-table-2-align-right">
                            <Table responsive>
                              <thead></thead>
                              <tbody>
                                <tr>
                                  <th scope="row">Adjusted Net Worth</th>
                                  <td className="module-table-2-number">
                                    {tableNumberFormat(
                                      state.Q4Table[0],
                                      "full",
                                    )}
                                  </td>
                                </tr>
                                <tr className="module-table-2-bottom-line">
                                  <th scope="row">(/) Total Assets</th>
                                  <td className="module-table-2-number">
                                    {tableNumberFormat(
                                      state.Q4Table[1],
                                      "partial",
                                    )}
                                  </td>
                                </tr>
                                <tr className="module-table-2-sum">
                                  <th scope="row">
                                    % Adj. Net Worth/Total Assets
                                  </th>
                                  <td className="module-table-2-number">
                                    {tableNumberFormat(
                                      state.Q4Table[2],
                                      "percentage",
                                    )}
                                  </td>
                                </tr>
                                <tr className="module-table-2-bottom-line">
                                  <th scope="row">Min. % Req.</th>
                                  <td className="module-table-2-blue module-table-2-number">
                                    {tableNumberFormat(
                                      state.Q4Table[3],
                                      "percentage",
                                    )}
                                  </td>
                                </tr>
                                <tr className="module-table-2-sum">
                                  <th scope="row">Amt. Above Min. % Req.</th>
                                  <td className="module-table-2-number">
                                    {tableNumberFormat(
                                      state.Q4Table[4],
                                      "percentage",
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                          <div className="module-table-2-align-right">
                            <Table responsive>
                              <thead></thead>
                              <tbody>
                                <tr>
                                  <th scope="row">Liquid Assets</th>
                                  <td className="module-table-2-number">
                                    {tableNumberFormat(
                                      state.Q4Table[5],
                                      "full",
                                    )}
                                  </td>
                                </tr>
                                <tr className="module-table-2-bottom-line">
                                  <th scope="row">(/) Required Net Worth</th>
                                  <td className="module-table-2-blue module-table-2-number">
                                    {tableNumberFormat(
                                      state.Q4Table[6],
                                      "partial",
                                    )}
                                  </td>
                                </tr>
                                <tr className="module-table-2-sum">
                                  <th scope="row">
                                    % Liquid Assets / Req. Net Worth
                                  </th>
                                  <td className="module-table-2-number">
                                    {tableNumberFormat(
                                      state.Q4Table[7],
                                      "percentage",
                                    )}
                                  </td>
                                </tr>
                                <tr className="module-table-2-bottom-line">
                                  <th scope="row">Min. % Req.</th>
                                  <td className="module-table-2-blue module-table-2-number">
                                    {tableNumberFormat(
                                      state.Q4Table[8],
                                      "percentage",
                                    )}
                                  </td>
                                </tr>
                                <tr className="module-table-2-sum">
                                  <th scope="row">Amt. Above Min. % Req.</th>
                                  <td className="module-table-2-number">
                                    {tableNumberFormat(
                                      state.Q4Table[9],
                                      "percentage",
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
      </div>
    </div>
  );
};
