import { useEffect } from "react";

interface TestProps {}

export const Test = (props: TestProps) => {

  function handleClick() {
    console.log( 4 * (2 / 3));
  }

  return (
    <div style={{ textAlign: "center" }}>
      <button onClick={handleClick}>Press</button>
    </div>
  );
};
