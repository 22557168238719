/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "ServerlessAPI",
            "endpoint": "https://ro5uicxhw8.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_QVXsIAsJH",
    "aws_user_pools_web_client_id": "29ng5gmu527bh7k0sgnabehp9n",
    "oauth": {
        "domain": "gallusnewdomain.auth.us-east-1.amazoncognito.com",
        "scope": [
            "email",
            "openid",
            "phone",
            "profile"
        ],
        "redirectSignIn": "http://localhost:3000/,http://localhost:3000/callback,https://chm.gallusanalytics.com/callback,https://gallus.thoughtspot.cloud/callosum/v1/oidc/callback,https://gallusanalytics.com/callback,https://gallusnewdomain/callosum/v1/oidc/callback,https://gin-164.gallusanalytics.com/callback,https://openidconnect.net/callback,https://pr-34.gallusanalytics.com/callback,https://v2-dev.gallusanalytics.com/callback,https://www.gallusanalytics.com/callback",
        "redirectSignOut": "http://localhost:3000,https://gallus.thoughtspot.cloud/callosum/v1/oidc/callback,https://gallusanalytics.com,https://gin-164.gallusanalytics.com,https://v2-dev.gallusanalytics.com,https://www.gallusanalytics.com,https://www.gallusanalytics.com/logout.html",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
