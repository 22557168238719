import { GinBuild, GinVersion } from "./GinVersion";

class Version {
  static get FullVersion(): string {
    var DO_NOT_CHANGE_ABCDEF = `${GinVersion}.${parseInt(GinBuild)}`;
    return DO_NOT_CHANGE_ABCDEF;
  }
}

export default Version;
