import {
  configureStore,
  ThunkAction,
  Action,
  PreloadedState,
  combineReducers,
} from "@reduxjs/toolkit";
import counterReducer from "../features/counter/counterSlice";
import consoleReducer from "../features/console/consoleSlice";
import { gallusApi, gallusAgilityApi } from "../services/gallus";
import { serverlessApi, serverlessFallbackApi } from "../services/serverless";
import { thoughtspotV1Api } from "../services/thoughtSpotV1";
import { thoughtspotV2Api } from "../services/thoughtSpotV2";

const rootReducer = combineReducers({
  counter: counterReducer,
  console: consoleReducer,
  [gallusApi.reducerPath]: gallusApi.reducer,
  [gallusAgilityApi.reducerPath]: gallusAgilityApi.reducer,
  [serverlessApi.reducerPath]: serverlessApi.reducer,
  [serverlessFallbackApi.reducerPath]: serverlessFallbackApi.reducer,
  [thoughtspotV1Api.reducerPath]: thoughtspotV1Api.reducer,
  [thoughtspotV2Api.reducerPath]: thoughtspotV2Api.reducer,
});

export const initStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware().concat([
        gallusApi.middleware,
        gallusAgilityApi.middleware,
        serverlessApi.middleware,
        serverlessFallbackApi.middleware,
        thoughtspotV1Api.middleware,
        thoughtspotV2Api.middleware,
      ]);
    },
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof initStore>;
export type AppDispatch = AppStore["dispatch"];
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
