import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import {
  CognitoJwtPayload,
  selectUserAuthContext,
  updateUserAuthContext,
} from "../../features/console/consoleSlice";
import "./LandingPage.scss";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Error } from "../../components/Error";
import { Auth } from "aws-amplify";
import { useDispatch } from "react-redux";
import { getUserSessionItem, updateSAToken } from "../..";
import { SubscribeProductCard } from "../../components/SubscribeProductCard";
import {
  useGetProductsQuery,
  useLazyGetSubscriptionsQuery,
  useLazyUserCheckInQuery,
} from "../../services/gallus";
import { Product, Subscriptions } from "../../services/types/gallus";

interface LandingPageProps {}

/**
 * Landing page component. It connects to an API, gets the available products, and shows them if the user has access priviledges
 */
export const LandingPage = (props: LandingPageProps) => {
  const userAuthContext = useAppSelector(selectUserAuthContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [listOfButtons, setListOfButtons] = useState<JSX.Element[]>([]);
  const [loadedButtonsFlag, setLoadedButtonsFlag] = useState<boolean>(false);
  const [errorFlag, setErrorFlag] = useState<boolean>(false);
  const [errorCode, setErrorCode] = useState<string>("");
  const [errorType, setErrorType] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const getProductsQueryResult = useGetProductsQuery();
  const [lazyGetSubscriptionsQuery, lazyGetSubscriptionsQueryResult] =
    useLazyGetSubscriptionsQuery();
  const [lazyUserCheckInQuery, lazyUserCheckInQueryResult] =
    useLazyUserCheckInQuery();

  /**
   * This function calls our APIs to request all the products available in our site, what products the user is subscribed to, and generates the Payment Url if the user needs to subscribe.
   */
  const generateButtons = async () => {
    lazyGetSubscriptionsQuery({
      cognitoSubject: userAuthContext!.idTokenPayload["sub"],
    });
  };

  useEffect(() => {
    if (
      getProductsQueryResult.isSuccess &&
      getProductsQueryResult.status === "fulfilled" &&
      lazyGetSubscriptionsQueryResult.isSuccess &&
      lazyGetSubscriptionsQueryResult.status === "fulfilled"
    ) {
      let accessible: boolean = false;
      let redirectUrl: string | null = "";
      let innerBrowsing: boolean = false;

      let listOfSubscribedProducts: Subscriptions[] = [];
      lazyGetSubscriptionsQueryResult.data.forEach((sub) => {
        listOfSubscribedProducts.push(sub);
      });

      for (let product of getProductsQueryResult.data) {
        if (product.Enabled) {
          if (
            product.Permissions.some(
              (groupsOfProduct) =>
                userAuthContext?.idTokenPayload["cognito:groups"]?.includes(
                  groupsOfProduct,
                ) ?? false,
            )
          ) {
            //we make sure the user can see the product
            if (
              listOfSubscribedProducts.some(
                (subscription) =>
                  subscription.DateEnd === null ||
                  new Date(subscription.DateEnd) >= new Date(),
              ) ||
              product.IsFree
            ) {
              //if the user is subscribed to a product, or the product is free, the button will be displayed
              redirectUrl = product.URLProduct;
              innerBrowsing = true;
              accessible = true;
            } else {
              accessible = false;
              redirectUrl = null;
              innerBrowsing = false;
            }
            listOfButtons.push(
              buttonWrapper(product, innerBrowsing, redirectUrl),
            );
          }
        }
      }

      setErrorFlag(false);
      if (listOfButtons.length === 1 && redirectUrl && accessible) {
        window.location.assign(redirectUrl);
      } else {
        setListOfButtons(listOfButtons);
        setLoadedButtonsFlag(true);
      }
    } else if (getProductsQueryResult.isError) {
      const error = JSON.parse(getProductsQueryResult.error as string);
      setErrorFlag(true);
      setErrorCode(error.retCode);
      setErrorMessage(error.retMessage);
      setErrorType("InternalAPI");
    } else if (lazyGetSubscriptionsQueryResult.isError) {
      const error = JSON.parse(lazyGetSubscriptionsQueryResult.error as string);
      setErrorFlag(true);
      setErrorCode(error.retCode);
      setErrorMessage(error.retMessage);
      setErrorType("InternalAPI");
    }
  }, [getProductsQueryResult, lazyGetSubscriptionsQueryResult]);

  /**
   * Wrapper for generating a button's HTML code.
   * @param name  name of the product.
   * @param description description of the product.
   * @param innerBrowsing if true, the button's url is one in the https://www.gallusanalytics.com/ domain.
   * @param url the URL the user will be moved to after clicking the button.
   * @param background URL of the background image. If blank, no image will be displayed.
   * @returns
   */
  const buttonWrapper = (
    product: Product,
    innerBrowsing: boolean,
    url: string | null,
  ): JSX.Element => {
    if (innerBrowsing) {
      return (
        <div className="lp-pill" key={product.Name}>
          <div>
            <div className="lp-pill-name">{product.Name}</div>
            <div className="lp-pill-description">{product.Description}</div>
          </div>
          <div>
            {url && (
              <button
                type="button"
                className="btn btn-primary lp-pill-button"
                onClick={(e) => {
                  navigate(url);
                }}
              >
                Go to product
              </button>
            )}
            {!url && (
              <button
                className="btn btn-primary lp-pill-button"
                disabled={true}
              >
                Please contact us
              </button>
            )}
          </div>
          <div
            className="lp-pill-background"
            style={{ backgroundImage: `url(${product.IconURL})` }}
          ></div>
        </div>
      );
    } else {
      return <SubscribeProductCard product={product} />;
    }
  };

  /**
   * Creates TS User.
   * @returns Boolean flag, true if return code is 200, false if user isn't logged in or return code is different than 200.
   */
  const checkInUser = () => {
    if (userAuthContext) {
      lazyUserCheckInQuery({ userPayload: getUserSessionItem("user") });
    }
  };

  useEffect(() => {
    const successCallback = async () => {
      await generateButtons();
    };
    if (
      lazyUserCheckInQueryResult.isSuccess &&
      lazyUserCheckInQueryResult.status === "fulfilled"
    ) {
      updateSAToken(lazyUserCheckInQueryResult.data.SAToken, "CheckIn");
      setErrorFlag(false);
      successCallback();
    } else if (lazyUserCheckInQueryResult.isError) {
      const error = JSON.parse(lazyUserCheckInQueryResult.error as string);
      setErrorFlag(true);
      setErrorCode(error.retCode);
      setErrorMessage(error.retMessage);
      setErrorType("InternalAPI");
    }
  }, [lazyUserCheckInQueryResult]);

  useEffect(() => {
    const getSession = async () => {
      try {
        if (!userAuthContext) {
          const session = await Auth.currentSession();
          dispatch(
            updateUserAuthContext({
              idTokenPayload: session.getIdToken().payload as CognitoJwtPayload,
              idToken: session.getIdToken().getJwtToken(),
              accessToken: session.getAccessToken().getJwtToken(),
              refreshToken: session.getRefreshToken().getToken(),
            }),
          );
        }
      } catch (e) {
        navigate("/login");
      }
    };
    getSession();
  }, []);

  useEffect(() => {
    if (userAuthContext) {
      checkInUser();
    } else {
      navigate("/login");
    }
  }, [userAuthContext]);

  return (
    <>
      <div className="landing-page-master">
        {!errorFlag ? (
          <div>
            <div style={{ display: !loadedButtonsFlag ? "none" : "block" }}>
              {listOfButtons.length !== 0 ? (
                <div className="landing-page-text">
                  Please select a product:
                </div>
              ) : (
                <div style={{ textAlign: "center" }}>
                  <div className="landing-page-no-product-title">
                    There are no products associated with your account.
                  </div>
                  <div className="landing-page-no-product-text">
                    Please email us at{" "}
                    <a href="mailto:sales@gallusinsights.com">
                      sales@gallusinsights.com
                    </a>{" "}
                    for a free consultation.
                  </div>
                </div>
              )}
            </div>
            <div
              className="loading-div"
              style={{ display: !loadedButtonsFlag ? "block" : "none" }}
            >
              <FontAwesomeIcon
                icon={faSpinner}
                size="2x"
                spin={true}
                style={{ margin: "0 auto" }}
              />
            </div>
            <div
              className="landing-page-buttons"
              data-toggle="buttons"
              style={{ display: loadedButtonsFlag ? "flex" : "none" }}
            >
              {listOfButtons}
            </div>
          </div>
        ) : (
          <Error code={errorCode} type={errorType} message={errorMessage} />
        )}
      </div>
    </>
  );
};
