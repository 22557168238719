import { Navigate, useLocation, useNavigate } from "react-router-dom";

interface RedirectProps {
  redirectTo: string;
  redirectMessage?: string;
}

export const Redirect = (props: RedirectProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  const generateJSX = () => {
    if (props.redirectMessage) {
      setTimeout(() => navigate(props.redirectTo + location.search), 5000);
      return <div className="redirect-text">{props.redirectMessage}</div>;
    } else {
      return <Navigate to={props.redirectTo + location.search} />;
    }
  };

  return generateJSX();
};
