import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const DefaultSidebarBreakpoint = "lg";

interface SidebarToggleProps {
  onClick?: () => void;
  breakpoint?: "xxl" | "xl" | "lg" | "md" | "sm" | "xs";
}

const SidebarToggle = (props: SidebarToggleProps) => {
  return (
    <div className={"sidebar-toggle-wrapper " + (props.breakpoint ?? "xxl")}>
      <button
        className="btn btn-xs btn-primary"
        onClick={() => props.onClick && props.onClick()}
      >
        <FontAwesomeIcon icon={faBars} size="lg" />
      </button>
    </div>
  );
};

export default SidebarToggle;
