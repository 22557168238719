import { tsDataObject } from "../../../services/types/gallus";

export const GrateMBAMoneyQ4: tsDataObject = {
  data: [
    [null, "Spacing", null, null, null, null, null],
    [
      "Total Production Revenue",
      "SubTitleBlue",
      null,
      { value: "10375.802531", overrideEncoding: "$sk0d-S" },
      { value: "9734.789589", overrideEncoding: "$sk0d-S" },
      { value: "11052.712676", overrideEncoding: "$sk0d-S" },
      { value: "11081.961956", overrideEncoding: "$sk0d-S" },
    ],
    ["Expenses", "Title", null, null, null, null, null],
    [
      "Sales Personnel",
      null,
      null,
      { value: "3128.662988", overrideEncoding: "$sk0d-S" },
      { value: "3267.097863", overrideEncoding: "$sk0d-S" },
      { value: "3431.614494", overrideEncoding: "$sk0d-S" },
      { value: "3484.256066", overrideEncoding: "$sk0d-S" },
    ],
    [
      "Fulfillment Personnel",
      null,
      null,
      1719.6985689,
      1315.6893805,
      1789.6717583,
      1485.4928425,
    ],
    [
      "Production Support Employees",
      null,
      null,
      910.18441234,
      618.87037575,
      994.69137343,
      849.38836039,
    ],
    [
      "Management and Directors",
      null,
      null,
      678.46363436,
      542.53121491,
      735.69062309,
      598.90681152,
    ],
    [
      "Benefits",
      null,
      null,
      1028.1336194,
      945.83948557,
      1095.6118181,
      1289.053185,
    ],
    [
      "Personnel",
      "SubTitleBlue",
      null,
      { value: "7465.143223.1", overrideEncoding: "$sk0d-S" },
      { value: "6690.028320.23", overrideEncoding: "$sk0d-S" },
      { value: "8047.280067.02", overrideEncoding: "$sk0d-S" },
      { value: "7707.097265.51", overrideEncoding: "$sk0d-S" },
    ],
    [
      "Occupancy and Equipment",
      null,
      null,
      775.54098685,
      564.53198859,
      842.14000032,
      682.10000075,
    ],
    [
      "Technology-Related Expenses",
      null,
      null,
      586.00988392,
      437.10062386,
      584.39288986,
      482.13380133,
    ],
    [
      "Other Direct Expenses",
      null,
      null,
      2677.016465,
      1801.1625842,
      2697.9593902,
      2257.9079674,
    ],
    [
      "Direct Loan Production Expenses",
      "SubTitleBlue",
      null,
      { value: "11503.710558.87", overrideEncoding: "$sk0d-S" },
      { value: "9492.823516.88", overrideEncoding: "$sk0d-S" },
      { value: "12171.772347.4", overrideEncoding: "$sk0d-S" },
      { value: "11129.239034.99", overrideEncoding: "$sk0d-S" },
    ],
    [
      "Corporate Allocation",
      "SubTitleBlue",
      null,
      981.46654556,
      793.75394898,
      917.59025661,
      575.9253738,
    ],
    [
      "Total Expenses",
      "SubTitlePurple",
      null,
      { value: "12485.177104.43", overrideEncoding: "$sk0d-S" },
      { value: "10286.577465.86", overrideEncoding: "$sk0d-S" },
      { value: "13089.362604.01", overrideEncoding: "$sk0d-S" },
      { value: "11705.164408.79", overrideEncoding: "$sk0d-S" },
    ],
    [
      "Total Net Production Income",
      "SubTitlePurple",
      null,
      -2109.37457343,
      -551.78787636,
      -2036.64992801,
      -623.202452790001,
    ],
    [null, "Spacing", null, null, null, null, null],
    ["Memo", "Title", null, null, null, null, null],
    [
      "Avg. Pull-Through (Closings/Applications #)",
      "SubTitleOrange",
      null,
      { value: "74.2", overrideEncoding: "string" },
      { value: "78.8", overrideEncoding: "string" },
      { value: "76.1", overrideEncoding: "string" },
      { value: "77.0", overrideEncoding: "string" },
    ],
    [
      "Closings per Sales Employee per Mo.",
      "SubTitleOrange",
      null,
      { value: "2.4", overrideEncoding: "string" },
      { value: "2.7", overrideEncoding: "string" },
      { value: "2.1", overrideEncoding: "string" },
      { value: "2.6", overrideEncoding: "string" },
    ],
    [
      "Closings per Fulfillment Employee per Mo.",
      "SubTitleOrange",
      null,
      { value: "4.0", overrideEncoding: "string" },
      { value: "4.9", overrideEncoding: "string" },
      { value: "4.0", overrideEncoding: "string" },
      { value: "4.6", overrideEncoding: "string" },
    ],
    [
      "Closings per Prod Support Employee per Mo.",
      "SubTitleOrange",
      null,
      { value: "7.9", overrideEncoding: "string" },
      { value: "12.9", overrideEncoding: "string" },
      { value: "6.7", overrideEncoding: "string" },
      { value: "13.2", overrideEncoding: "string" },
    ],
    [
      "Closings per Total Prod. Employee per Mo.",
      "SubTitleOrange",
      null,
      { value: "1.1", overrideEncoding: "string" },
      { value: "1.3", overrideEncoding: "string" },
      { value: "1.1", overrideEncoding: "string" },
      { value: "1.2", overrideEncoding: "string" },
    ],
  ],
  columnNames: [
    "RowName",
    "CSSClass",
    "G-Rate",
    "Total Industry",
    "Tot. Ind. >1B",
    "Retail / CD",
    "Ret. / CD > 500m",
  ],
  columnDisplayName: [
    "($ per Loan)",
    "CSSClass",
    "G-Rate",
    "Total Industry",
    "Tot. Ind. >1B",
    "Retail / CD",
    "Ret. / CD > 500m",
  ],
  columnShow: [true, false, true, true, true, true, true],
  columnGroupName: [
    {
      nameOfGroup: "Income Statement for Q4 2023 ($ per Loan)",
      headerClass: "grate-group",
      columnsToGroup: [
        "G-Rate",
        "Total Industry",
        "Tot. Ind. >1B",
        "Retail / CD",
        "Ret. / CD > 500m",
      ],
    },
  ],
  columnFormatting: [
    "string",
    "string",
    "$-sk0d-S",
    "$-sk0d-S",
    "$-sk0d-S",
    "$-sk0d-S",
    "$-sk0d-S",
  ],
  columnHeaderClasses: [
    "grid-bold",
    "",
    "grate-column",
    "grate-column",
    "grate-column",
    "grate-column",
    "grate-column",
  ],
  columnDataClasses: [
    "text-left",
    "",
    "text-right",
    "text-right",
    "text-right",
    "text-right",
    "text-right",
  ],
  tableTitle: "",
};

export const GrateMBABasisPointsQ4: tsDataObject = {
  data: [
    [null, "Spacing", null, null, null, null, null],
    [
      "Total Production Revenue",
      "SubTitleBlue",
      null,
      334.18042838,
      304.59201804,
      356.50674776,
      349.21384697,
    ],
    ["Expenses", "Title", null, null, null, null, null],
    [
      "Sales Personnel",
      null,
      null,
      100.50618241,
      104.63428126,
      110.77318153,
      111.275981,
    ],
    [
      "Fulfillment Personnel",
      null,
      null,
      56.562567665,
      41.255915177,
      58.913968002,
      46.85605345,
    ],
    [
      "Production Support Employees",
      null,
      null,
      30.56259023,
      20.666472404,
      33.408162805,
      27.965989581,
    ],
    [
      "Management and Directors",
      null,
      null,
      22.369660687,
      16.987198676,
      24.312086577,
      18.397052019,
    ],
    [
      "Benefits",
      null,
      null,
      33.903237836,
      30.092812688,
      35.992794151,
      40.543176391,
    ],
    [
      "Personnel",
      "SubTitleBlue",
      null,
      243.904238828,
      213.636680205,
      263.400193065,
      245.038252441,
    ],
    [
      "Occupancy and Equipment",
      null,
      null,
      25.840102419,
      18.386441916,
      28.07707705,
      22.449057602,
    ],
    [
      "Technology-Related Expenses",
      null,
      null,
      19.73417393,
      13.962485368,
      19.750765403,
      15.839780797,
    ],
    [
      "Other Direct Expenses",
      null,
      null,
      83.951664263,
      57.062704276,
      84.682737265,
      74.17718383,
    ],
    [
      "Direct Loan Production Expenses",
      "SubTitleBlue",
      null,
      373.43017944,
      303.048311765,
      395.910772783,
      357.50427467,
    ],
    [
      "Corporate Allocation",
      "SubTitleBlue",
      null,
      33.494483413,
      25.186147313,
      32.348900775,
      19.489850093,
    ],
    [
      "Total Expenses",
      "SubTitlePurple",
      null,
      406.924662853,
      328.234459078,
      428.259673558,
      376.994124763,
    ],
    [
      "Total Net Production Income",
      "SubTitlePurple",
      null,
      -72.744234473,
      -23.6424410379999,
      -71.752925798,
      -27.7802777929999,
    ],
    [null, "Spacing", null, null, null, null, null],
    ["Memo", "Title", null, null, null, null, null],
    [
      "Avg. Pull-Through (Closings/Applications #)",
      "SubTitleOrange",
      null,
      { value: 74.2, overrideEncoding: "string" },
      { value: 78.8, overrideEncoding: "string" },
      { value: 76.1, overrideEncoding: "string" },
      { value: "77.0", overrideEncoding: "string" },
    ],
    [
      "Closings per Sales Employee per Mo.",
      "SubTitleOrange",
      null,
      { value: 2.4, overrideEncoding: "string" },
      { value: 2.7, overrideEncoding: "string" },
      { value: 2.1, overrideEncoding: "string" },
      { value: 2.6, overrideEncoding: "string" },
    ],
    [
      "Closings per Fulfillment Employee per Mo.",
      "SubTitleOrange",
      null,
      { value: "4.0", overrideEncoding: "string" },
      { value: 4.9, overrideEncoding: "string" },
      { value: "4.0", overrideEncoding: "string" },
      { value: 4.6, overrideEncoding: "string" },
    ],
    [
      "Closings per Prod Support Employee per Mo.",
      "SubTitleOrange",
      null,
      { value: 7.9, overrideEncoding: "string" },
      { value: 12.9, overrideEncoding: "string" },
      { value: 6.7, overrideEncoding: "string" },
      { value: 13.2, overrideEncoding: "string" },
    ],
    [
      "Closings per Total Prod. Employee per Mo.",
      "SubTitleOrange",
      null,
      { value: 1.1, overrideEncoding: "string" },
      { value: 1.3, overrideEncoding: "string" },
      { value: 1.1, overrideEncoding: "string" },
      { value: 1.2, overrideEncoding: "string" },
    ],
  ],
  columnNames: [
    "RowName",
    "CSSClass",
    "G-Rate",
    "Total Industry",
    "Tot. Ind. >1B",
    "Retail / CD",
    "Ret. / CD > 500m",
  ],
  columnDisplayName: [
    "Basis Points",
    "CSSClass",
    "G-Rate",
    "Total Industry",
    "Tot. Ind. >1B",
    "Retail / CD",
    "Ret. / CD > 500m",
  ],
  columnShow: [true, false, true, true, true, true, true],
  columnGroupName: [
    {
      nameOfGroup: "Income Statement for Q4 2023 (basis points)",
      headerClass: "grate-group",
      columnsToGroup: [
        "G-Rate",
        "Total Industry",
        "Tot. Ind. >1B",
        "Retail / CD",
        "Ret. / CD > 500m",
      ],
    },
  ],
  columnFormatting: [
    "string",
    "string",
    "$-s0d",
    "$-s0d",
    "$-s0d",
    "$-s0d",
    "$-s0d",
  ],
  columnHeaderClasses: [
    "grid-bold",
    "",
    "grate-column",
    "grate-column",
    "grate-column",
    "grate-column",
    "grate-column",
  ],
  columnDataClasses: [
    "text-left",
    "",
    "text-right",
    "text-right",
    "text-right",
    "text-right",
    "text-right",
  ],
  tableTitle: "",
};
