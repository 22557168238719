export const smallScreenCssRules = {
  ".bk-primary-nav-menu": {
    padding: "0px 0px 0px 0px !important",
    "flex-direction": "row !important",
    "align-items": "center !important",
    "justify-content": "space-around !important",
  },
  ".bk-primary-nav-monitor": {
    display: "none !important",
  },
  ".bk-primary-nav-expand": {
    display: "none !important",
  },
  ".bk-org-menu": {
    display: "none !important",
  },
  ".bk-eureka-search-bar": {
    "border-right": "0px !important",
  },
  ".bk-primary-nav-search-data": {
    "border-right": "0px !important",
  },
  ".stickers": {
    display: "none !important",
  },
  ".rd-button-size-m.rd-button-with-text:not(rd-button-with-icon)": {
    padding: "0 !important",
  },
  ".eureka-search-bar-module__eurekaSearchBar": {
    width: "100% !important",
  },
  ".bk-actionable-list-header": {
    padding: "10px 10px 10px !important",
  },
  ".bk-list-header-wrapper": {
    display: "none !important",
  },
};
