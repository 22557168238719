import { DataEncoding, tsDataObject } from "../../../services/types/gallus";

const dataArrayFormatted = [
  ["Size", "Title", "", "", "", "", "", "", "", "", "", "", "", ""],
  [
    "Originations",
    "text-underlined",
    { value: 22191440000, overrideEncoding: "$skb1d" },
    { value: 22330333000, overrideEncoding: "$skb1d" },
    -0.0062199251574081,
    { value: 4347306000, overrideEncoding: "$skb1d" },
    { value: 4549345000, overrideEncoding: "$skb1d" },
    -0.0444105689939981,
    { value: 29721633000, overrideEncoding: "$skb1d" },
    { value: 31846800000, overrideEncoding: "$skb1d" },
    -0.0667309431402842,
    { value: 6083143000, overrideEncoding: "$skb1d" },
    { value: 6273543000, overrideEncoding: "$skb1d" },
    -0.0303496764109212,
  ],
  [
    "Servicing",
    "CSSCLASSGOESHERE",
    { value: 506083328000, overrideEncoding: "$-skb1d" },
    { value: 503693198000, overrideEncoding: "$-skb1d" },
    0.00474520999983796,
    { value: 83705731000, overrideEncoding: "$-skb1d" },
    { value: 82030408000, overrideEncoding: "$-skb1d" },
    0.0204231947743085,
    { value: 281373662000, overrideEncoding: "$-skb1d" },
    { value: 294945929000, overrideEncoding: "$-skb1d" },
    -0.0460161191104286,
    { value: 143959705000, overrideEncoding: "$-skb1d" },
    { value: 142479870000, overrideEncoding: "$-skb1d" },
    0.0103862742154384,
  ],
  [
    null,
    "Spacing",
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ],
  ["Balance Sheet", "Title", "", "", "", "", "", "", "", "", "", "", "", ""],
  [
    "Tangible Assets",
    "CSSCLASSGOESHERE",
    19406521000,
    19607671000,
    -0.010258740061479,
    3310450000,
    3347745000,
    -0.0111403347626536,
    12204137000,
    12425919000,
    -0.0178483378171064,
    6078529000,
    6203504000,
    -0.0201458723972774,
  ],
  [
    "Tangible Equity",
    "text-underlined",
    { value: 7264155000, overrideEncoding: "$-sk0d" },
    { value: 7116848000, overrideEncoding: "$-sk0d" },
    0.020698348482362,
    { value: 1090794000, overrideEncoding: "$-sk0d" },
    { value: 1066571000, overrideEncoding: "$-sk0d" },
    0.0227110994017277,
    { value: 3092111000, overrideEncoding: "$-sk0d" },
    { value: 2947122000, overrideEncoding: "$-sk0d" },
    0.0491968096332625,
    { value: 768935000, overrideEncoding: "$-sk0d" },
    { value: 797344000, overrideEncoding: "$-sk0d" },
    -0.0356295400730425,
  ],
  [
    "TCE / TA",
    "CSSCLASSGOESHERE",
    { value: 0.374315159321962, overrideEncoding: "$%1d" },
    { value: 0.362962434447212, overrideEncoding: "$%1d" },
    { value: 0.0113527248747499, overrideEncoding: "$%1d" },
    { value: 0.329500219003459, overrideEncoding: "$%1d" },
    { value: 0.318593859448674, overrideEncoding: "$%1d" },
    { value: 0.0109063595547851, overrideEncoding: "$%1d" },
    { value: 0.253365805382224, overrideEncoding: "$%1d" },
    { value: 0.237175375117124, overrideEncoding: "$%1d" },
    { value: 0.0161904302650999, overrideEncoding: "$%1d" },
    { value: 0.126500177921336, overrideEncoding: "$%1d" },
    { value: 0.128531230091896, overrideEncoding: "$%1d" },
    { value: -0.00203105217056054, overrideEncoding: "$%1d" },
  ],
  [
    null,
    "Spacing",
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ],
  ["Income Statement", "Title", "", "", "", "", "", "", "", "", "", "", "", ""],
  [
    "Revenue",
    "CSSCLASSGOESHERE",
    1203168000,
    1236227000,
    -0.0267418524267792,
    257257000,
    236811000,
    0.0863388947303969,
    677116000,
    587520000,
    0.152498638344227,
    265661000,
    271833000,
    -0.0227051167444718,
  ],
  [
    "Expense",
    "CSSCLASSGOESHERE",
    { value: 1085543000, overrideEncoding: "$-sk0d" },
    { value: 1097857000, overrideEncoding: "$-sk0d" },
    -0.0112163970353152,
    { value: 183730000, overrideEncoding: "$-sk0d" },
    { value: 186370000, overrideEncoding: "$-sk0d" },
    -0.0141653699629769,
    { value: 375389000, overrideEncoding: "$-sk0d" },
    { value: 357516000, overrideEncoding: "$-sk0d" },
    0.0499921681826827,
    { value: 305128000, overrideEncoding: "$-sk0d" },
    { value: 330148000, overrideEncoding: "$-sk0d" },
    -0.0757841937555278,
  ],
  [
    "Net Income",
    "SubTitleOrange",
    109266000,
    132293000,
    -0.174060607893086,
    54243000,
    36936000,
    0.468567251461988,
    300993000,
    228794000,
    0.315563345192619,
    -34262000,
    -49759000,
    -0.311441146325288,
  ],
  [
    null,
    "Spacing",
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ],
  ["Profitability", "Title", "", "", "", "", "", "", "", "", "", "", "", ""],
  [
    "GoS Margins",
    "CSSCLASSGOESHERE",
    { value: 0.0276, overrideEncoding: "$%2d" },
    { value: 0.0267, overrideEncoding: "$%2d" },
    { value: 0.000899999999999998, overrideEncoding: "$%2d" },
    { value: 0.0377, overrideEncoding: "$%2d" },
    { value: 0.031, overrideEncoding: "$%2d" },
    { value: 0.0067, overrideEncoding: "$%2d" },
    { value: 0.0097, overrideEncoding: "$%2d" },
    { value: 0.009, overrideEncoding: "$%2d" },
    { value: 0.000699999999999999, overrideEncoding: "$%2d" },
    { value: 0.0274, overrideEncoding: "$%2d" },
    { value: 0.0275, overrideEncoding: "$%2d" },
    { value: -9.99999999999994e-5, overrideEncoding: "$%2d" },
  ],
  [
    "Efficiency Ratio",
    "CSSCLASSGOESHERE",
    { value: 0.902237260299476, overrideEncoding: "$%1d" },
    { value: 0.888070718403659, overrideEncoding: "$%1d" },
    { value: 0.0141665418958172, overrideEncoding: "$%1d" },
    { value: 0.714188535200208, overrideEncoding: "$%1d" },
    { value: 0.786998914746359, overrideEncoding: "$%1d" },
    { value: -0.0728103795461506, overrideEncoding: "$%1d" },
    { value: 0.554393929548261, overrideEncoding: "$%1d" },
    { value: 0.608517156862745, overrideEncoding: "$%1d" },
    { value: -0.0541232273144845, overrideEncoding: "$%1d" },
    { value: 1.14856151260441, overrideEncoding: "$%1d" },
    { value: 1.21452509445137, overrideEncoding: "$%1d" },
    { value: -0.0659635818469633, overrideEncoding: "$%1d" },
  ],
  [
    "Net Margin",
    "CSSCLASSGOESHERE",
    { value: 0.0908152477459507, overrideEncoding: "$%1d" },
    { value: 0.107013517743909, overrideEncoding: "$%1d" },
    { value: -0.0161982699979588, overrideEncoding: "$%1d" },
    { value: 0.210851405403935, overrideEncoding: "$%1d" },
    { value: 0.155972484386283, overrideEncoding: "$%1d" },
    { value: 0.0548789210176519, overrideEncoding: "$%1d" },
    { value: 0.444522061212554, overrideEncoding: "$%1d" },
    { value: 0.389423338779956, overrideEncoding: "$%1d" },
    { value: 0.055098722432598, overrideEncoding: "$%1d" },
    { value: -0.128968873865566, overrideEncoding: "$%1d" },
    { value: -0.183049887246949, overrideEncoding: "$%1d" },
    { value: 0.0540810133813839, overrideEncoding: "$%1d" },
  ],
  [
    "ROTCE",
    "SubTitleOrange",
    { value: 0.0601672183481768, overrideEncoding: "$%1d" },
    { value: 0.0743548267435247, overrideEncoding: "$%1d" },
    { value: -0.0141876083953479, overrideEncoding: "$%1d" },
    { value: 0.19891198521444, overrideEncoding: "$%1d" },
    { value: 0.138522423729878, overrideEncoding: "$%1d" },
    { value: 0.0603895614845618, overrideEncoding: "$%1d" },
    { value: 0.389368945681445, overrideEncoding: "$%1d" },
    { value: 0.310532105559254, overrideEncoding: "$%1d" },
    { value: 0.0788368401221914, overrideEncoding: "$%1d" },
    { value: -0.178230929792505, overrideEncoding: "$%1d" },
    { value: -0.249623750852831, overrideEncoding: "$%1d" },
    { value: 0.0713928210603262, overrideEncoding: "$%1d" },
  ],
];

const columnFormattingFormatted: DataEncoding[] = [
  "string",
  "string",
  "$sk0d",
  "$sk0d",
  "$%1d",
  "$sk0d",
  "$sk0d",
  "$%1d",
  "$sk0d",
  "$sk0d",
  "$%1d",
  "$sk0d",
  "$sk0d",
  "$%1d",
];

export const GrateBenchmarkQ323: tsDataObject = {
  data: dataArrayFormatted,
  columnNames: [
    "RowName",
    "CSSClass",
    "Q3 2023 Rocket",
    "Q2 2023 Rocket",
    "QoQ Rocket",
    "Q3 2023 Guild",
    "Q2 2023 Guild",
    "QoQ Guild",
    "Q3 2023 UWM",
    "Q2 2023 UWM",
    "QoQ UWM",
    "Q3 2023 Loan Depot",
    "Q2 2023 Loan Depot",
    "QoQ Loan Depot",
  ],
  columnDisplayName: [
    "RowName",
    "CSSClass",
    "Q3 2023",
    "Q2 2023",
    "QoQ",
    "Q3 2023",
    "Q2 2023",
    "QoQ",
    "Q3 2023",
    "Q2 2023",
    "QoQ",
    "Q3 2023",
    "Q2 2023",
    "QoQ",
  ],
  columnShow: [
    true,
    false,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
  ],
  columnGroupName: [
    {
      nameOfGroup: "Rocket",
      headerClass: "grate-group",
      columnsToGroup: ["Q3 2023 Rocket", "Q2 2023 Rocket", "QoQ Rocket"],
    },
    {
      nameOfGroup: "Guild",
      headerClass: "grate-group",
      columnsToGroup: ["Q3 2023 Guild", "Q2 2023 Guild", "QoQ Guild"],
    },
    {
      nameOfGroup: "UWM",
      headerClass: "grate-group",
      columnsToGroup: ["Q3 2023 UWM", "Q2 2023 UWM", "QoQ UWM"],
    },
    {
      nameOfGroup: "Loan Depot",
      headerClass: "grate-group",
      columnsToGroup: [
        "Q3 2023 Loan Depot",
        "Q2 2023 Loan Depot",
        "QoQ Loan Depot",
      ],
    },
  ],
  columnFormatting: columnFormattingFormatted,
  columnHeaderClasses: [
    "grid-bold",
    "",
    "grate-column",
    "grate-column",
    "grate-column",
    "grate-column",
    "grate-column",
    "grate-column",
    "grate-column",
    "grate-column",
    "grate-column",
    "grate-column",
    "grate-column",
    "grate-column",
  ],
  columnDataClasses: [
    "text-left",
    "",
    "text-right",
    "text-right",
    "text-right",
    "text-right",
    "text-right",
    "text-right",
    "text-right",
    "text-right",
    "text-right",
    "text-right",
    "text-right",
    "text-right",
  ],
  tableTitle: "",
};
