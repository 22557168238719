import React, { useEffect, useState } from "react";
import "./RocketSheet.scss";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import { ColumnResizedEvent } from "ag-grid-community";
import { useXlsxProcessorQuery } from "../../../services/serverless";
import { Error, tryAgainLaterMessage } from "../../../components/Error";

const numberFormat = (value: string, type: string) => {
  let result: string = "";
  switch (type) {
    case "number":
      if (parseFloat(value) < 0) {
        result =
          "(" +
          Math.round(-1 * parseFloat(value))
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
          ")";
      } else {
        result = Math.round(parseFloat(value))
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      break;
    case "percentage":
      if (parseFloat(value) < 0) {
        result =
          "-" +
          Math.round(-100 * parseFloat(value))
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
          "%";
      } else {
        if (parseFloat(value) === 0) {
          result = "-";
        } else {
          result =
            Math.round(100 * parseFloat(value))
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "%";
        }
      }
      break;
    case "ratio":
      result = (Math.round(parseFloat(value) * 10) / 10)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      break;
    case "money":
      if (parseFloat(value) < 0) {
        result =
          "($" +
          Math.round(-1 * parseFloat(value))
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
          ")";
      } else {
        result =
          "$" +
          Math.round(parseFloat(value))
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      break;
    case "tinynumber":
      if (parseFloat(value) < 0) {
        result =
          "(" +
          (Math.round(-100 * parseFloat(value)) / 100)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
          ")";
      } else {
        result = (Math.round(100 * parseFloat(value)) / 100)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      break;
    case "tinymoney":
      if (parseFloat(value) < 0) {
        result =
          "($" +
          (Math.round(-100 * parseFloat(value)) / 100)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
          ")";
      } else {
        result =
          "$" +
          (Math.round(100 * parseFloat(value)) / 100)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      break;
  }
  return result;
};

interface RocketBalanceSheetProps {}

interface RocketBalanceSheetState {
  columns: GridColumnAccountType[];
  dataBalanceSheet: GridRowType[];
  dataCapStructure: GridRowType[];
  loadedData: boolean;
  errorFlag: boolean;
  errorCode: string;
  errorMessage: string;
  errorType: string;
}

interface GridColumnAccountType {
  field: string;
  width?: number;
  minWidth?: number;
  maxWidth?: number;
  suppressSizeToFit?: boolean;
}

interface GridRowType {
  [key: string]: string;
}

export const RocketBalanceSheet = (props: RocketBalanceSheetProps) => {
  const [state, setState] = useState<RocketBalanceSheetState>({
    columns: [
      { field: "Accounts", width: 200, suppressSizeToFit: true },
      { field: "Q4-22" },
      { field: "Q3-22" },
      { field: "Q2-22" },
      { field: "Q1-22" },
      { field: "Q4-21" },
    ],
    dataBalanceSheet: [],
    dataCapStructure: [],
    loadedData: false,
    errorFlag: false,
    errorCode: "",
    errorMessage: "",
    errorType: "",
  });

  const balanceSheetData = useXlsxProcessorQuery({
    DataSource:
      "https://docs.google.com/spreadsheets/d/1PWu461r9p_NonDSQLtkwSlRNFtAEWftp/",
    InputSheetName: null,
    Inputs: [],
    OutputSheetName: "Webpage",
    Outputs: [
      { RowIndex: 38, CellIndex: 15, Value: null }, // Assets
      { RowIndex: 38, CellIndex: 16, Value: null },
      { RowIndex: 38, CellIndex: 17, Value: null },
      { RowIndex: 38, CellIndex: 18, Value: null },
      { RowIndex: 38, CellIndex: 19, Value: null },
      { RowIndex: 39, CellIndex: 15, Value: null }, //
      { RowIndex: 39, CellIndex: 16, Value: null },
      { RowIndex: 39, CellIndex: 17, Value: null },
      { RowIndex: 39, CellIndex: 18, Value: null },
      { RowIndex: 39, CellIndex: 19, Value: null },
      { RowIndex: 40, CellIndex: 15, Value: null }, //
      { RowIndex: 40, CellIndex: 16, Value: null },
      { RowIndex: 40, CellIndex: 17, Value: null },
      { RowIndex: 40, CellIndex: 18, Value: null },
      { RowIndex: 40, CellIndex: 19, Value: null },
      { RowIndex: 41, CellIndex: 15, Value: null }, //
      { RowIndex: 41, CellIndex: 16, Value: null },
      { RowIndex: 41, CellIndex: 17, Value: null },
      { RowIndex: 41, CellIndex: 18, Value: null },
      { RowIndex: 41, CellIndex: 19, Value: null },
      { RowIndex: 42, CellIndex: 15, Value: null }, //
      { RowIndex: 42, CellIndex: 16, Value: null },
      { RowIndex: 42, CellIndex: 17, Value: null },
      { RowIndex: 42, CellIndex: 18, Value: null },
      { RowIndex: 42, CellIndex: 19, Value: null },
      { RowIndex: 43, CellIndex: 15, Value: null }, //
      { RowIndex: 43, CellIndex: 16, Value: null },
      { RowIndex: 43, CellIndex: 17, Value: null },
      { RowIndex: 43, CellIndex: 18, Value: null },
      { RowIndex: 43, CellIndex: 19, Value: null },
      { RowIndex: 44, CellIndex: 15, Value: null }, //
      { RowIndex: 44, CellIndex: 16, Value: null },
      { RowIndex: 44, CellIndex: 17, Value: null },
      { RowIndex: 44, CellIndex: 18, Value: null },
      { RowIndex: 44, CellIndex: 19, Value: null },
      { RowIndex: 45, CellIndex: 15, Value: null }, //
      { RowIndex: 45, CellIndex: 16, Value: null },
      { RowIndex: 45, CellIndex: 17, Value: null },
      { RowIndex: 45, CellIndex: 18, Value: null },
      { RowIndex: 45, CellIndex: 19, Value: null },
      { RowIndex: 48, CellIndex: 15, Value: null }, // Liabilities
      { RowIndex: 48, CellIndex: 16, Value: null },
      { RowIndex: 48, CellIndex: 17, Value: null },
      { RowIndex: 48, CellIndex: 18, Value: null },
      { RowIndex: 48, CellIndex: 19, Value: null },
      { RowIndex: 49, CellIndex: 15, Value: null }, //
      { RowIndex: 49, CellIndex: 16, Value: null },
      { RowIndex: 49, CellIndex: 17, Value: null },
      { RowIndex: 49, CellIndex: 18, Value: null },
      { RowIndex: 49, CellIndex: 19, Value: null },
      { RowIndex: 50, CellIndex: 15, Value: null }, //
      { RowIndex: 50, CellIndex: 16, Value: null },
      { RowIndex: 50, CellIndex: 17, Value: null },
      { RowIndex: 50, CellIndex: 18, Value: null },
      { RowIndex: 50, CellIndex: 19, Value: null },
      { RowIndex: 51, CellIndex: 15, Value: null }, //
      { RowIndex: 51, CellIndex: 16, Value: null },
      { RowIndex: 51, CellIndex: 17, Value: null },
      { RowIndex: 51, CellIndex: 18, Value: null },
      { RowIndex: 51, CellIndex: 19, Value: null },
      { RowIndex: 52, CellIndex: 15, Value: null }, //
      { RowIndex: 52, CellIndex: 16, Value: null },
      { RowIndex: 52, CellIndex: 17, Value: null },
      { RowIndex: 52, CellIndex: 18, Value: null },
      { RowIndex: 52, CellIndex: 19, Value: null },
      { RowIndex: 55, CellIndex: 15, Value: null }, // Equity
      { RowIndex: 55, CellIndex: 16, Value: null },
      { RowIndex: 55, CellIndex: 17, Value: null },
      { RowIndex: 55, CellIndex: 18, Value: null },
      { RowIndex: 55, CellIndex: 19, Value: null },
      { RowIndex: 56, CellIndex: 15, Value: null }, //
      { RowIndex: 56, CellIndex: 16, Value: null },
      { RowIndex: 56, CellIndex: 17, Value: null },
      { RowIndex: 56, CellIndex: 18, Value: null },
      { RowIndex: 56, CellIndex: 19, Value: null },
      { RowIndex: 57, CellIndex: 15, Value: null }, //
      { RowIndex: 57, CellIndex: 16, Value: null },
      { RowIndex: 57, CellIndex: 17, Value: null },
      { RowIndex: 57, CellIndex: 18, Value: null },
      { RowIndex: 57, CellIndex: 19, Value: null },
      { RowIndex: 58, CellIndex: 15, Value: null }, //
      { RowIndex: 58, CellIndex: 16, Value: null },
      { RowIndex: 58, CellIndex: 17, Value: null },
      { RowIndex: 58, CellIndex: 18, Value: null },
      { RowIndex: 58, CellIndex: 19, Value: null },
      { RowIndex: 59, CellIndex: 15, Value: null }, //
      { RowIndex: 59, CellIndex: 16, Value: null },
      { RowIndex: 59, CellIndex: 17, Value: null },
      { RowIndex: 59, CellIndex: 18, Value: null },
      { RowIndex: 59, CellIndex: 19, Value: null },
      { RowIndex: 61, CellIndex: 15, Value: null }, //
      { RowIndex: 61, CellIndex: 16, Value: null },
      { RowIndex: 61, CellIndex: 17, Value: null },
      { RowIndex: 61, CellIndex: 18, Value: null },
      { RowIndex: 61, CellIndex: 19, Value: null },
      { RowIndex: 65, CellIndex: 15, Value: null }, // Capital Structure and Leverage
      { RowIndex: 65, CellIndex: 16, Value: null },
      { RowIndex: 65, CellIndex: 17, Value: null },
      { RowIndex: 65, CellIndex: 18, Value: null },
      { RowIndex: 65, CellIndex: 19, Value: null },
      { RowIndex: 66, CellIndex: 15, Value: null }, //
      { RowIndex: 66, CellIndex: 16, Value: null },
      { RowIndex: 66, CellIndex: 17, Value: null },
      { RowIndex: 66, CellIndex: 18, Value: null },
      { RowIndex: 66, CellIndex: 19, Value: null },
      { RowIndex: 67, CellIndex: 15, Value: null }, //
      { RowIndex: 67, CellIndex: 16, Value: null },
      { RowIndex: 67, CellIndex: 17, Value: null },
      { RowIndex: 67, CellIndex: 18, Value: null },
      { RowIndex: 67, CellIndex: 19, Value: null },
      { RowIndex: 68, CellIndex: 15, Value: null }, //
      { RowIndex: 68, CellIndex: 16, Value: null },
      { RowIndex: 68, CellIndex: 17, Value: null },
      { RowIndex: 68, CellIndex: 18, Value: null },
      { RowIndex: 68, CellIndex: 19, Value: null },
      { RowIndex: 71, CellIndex: 15, Value: null }, //
      { RowIndex: 71, CellIndex: 16, Value: null },
      { RowIndex: 71, CellIndex: 17, Value: null },
      { RowIndex: 71, CellIndex: 18, Value: null },
      { RowIndex: 71, CellIndex: 19, Value: null },
      { RowIndex: 72, CellIndex: 15, Value: null }, //
      { RowIndex: 72, CellIndex: 16, Value: null },
      { RowIndex: 72, CellIndex: 17, Value: null },
      { RowIndex: 72, CellIndex: 18, Value: null },
      { RowIndex: 72, CellIndex: 19, Value: null },
      { RowIndex: 73, CellIndex: 15, Value: null }, //
      { RowIndex: 73, CellIndex: 16, Value: null },
      { RowIndex: 73, CellIndex: 17, Value: null },
      { RowIndex: 73, CellIndex: 18, Value: null },
      { RowIndex: 73, CellIndex: 19, Value: null },
      { RowIndex: 74, CellIndex: 15, Value: null }, //
      { RowIndex: 74, CellIndex: 16, Value: null },
      { RowIndex: 74, CellIndex: 17, Value: null },
      { RowIndex: 74, CellIndex: 18, Value: null },
      { RowIndex: 74, CellIndex: 19, Value: null },
      { RowIndex: 77, CellIndex: 15, Value: null }, //
      { RowIndex: 77, CellIndex: 16, Value: null },
      { RowIndex: 77, CellIndex: 17, Value: null },
      { RowIndex: 77, CellIndex: 18, Value: null },
      { RowIndex: 77, CellIndex: 19, Value: null },
      { RowIndex: 78, CellIndex: 15, Value: null }, //
      { RowIndex: 78, CellIndex: 16, Value: null },
      { RowIndex: 78, CellIndex: 17, Value: null },
      { RowIndex: 78, CellIndex: 18, Value: null },
      { RowIndex: 78, CellIndex: 19, Value: null },
      { RowIndex: 79, CellIndex: 15, Value: null }, //
      { RowIndex: 79, CellIndex: 16, Value: null },
      { RowIndex: 79, CellIndex: 17, Value: null },
      { RowIndex: 79, CellIndex: 18, Value: null },
      { RowIndex: 79, CellIndex: 19, Value: null },
      { RowIndex: 80, CellIndex: 15, Value: null }, //
      { RowIndex: 80, CellIndex: 16, Value: null },
      { RowIndex: 80, CellIndex: 17, Value: null },
      { RowIndex: 80, CellIndex: 18, Value: null },
      { RowIndex: 80, CellIndex: 19, Value: null },
      { RowIndex: 83, CellIndex: 15, Value: null }, //
      { RowIndex: 83, CellIndex: 16, Value: null },
      { RowIndex: 83, CellIndex: 17, Value: null },
      { RowIndex: 83, CellIndex: 18, Value: null },
      { RowIndex: 83, CellIndex: 19, Value: null },
      { RowIndex: 84, CellIndex: 15, Value: null }, //
      { RowIndex: 84, CellIndex: 16, Value: null },
      { RowIndex: 84, CellIndex: 17, Value: null },
      { RowIndex: 84, CellIndex: 18, Value: null },
      { RowIndex: 84, CellIndex: 19, Value: null },
    ],
  });

  useEffect(() => {
    if (balanceSheetData.isSuccess && balanceSheetData.status === "fulfilled") {
      setState({
        ...state,
        dataBalanceSheet: [
          {
            Accounts: "Assets: ",
            "Q4-22": " ",
            "Q3-22": " ",
            "Q2-22": " ",
            "Q1-22": " ",
            "Q4-21": " ",
          },
          {
            Accounts: "Cash",
            "Q4-22": numberFormat(
              balanceSheetData.data.Outputs[0].Value,
              "money",
            ),
            "Q3-22": numberFormat(
              balanceSheetData.data.Outputs[1].Value,
              "money",
            ),
            "Q2-22": numberFormat(
              balanceSheetData.data.Outputs[2].Value,
              "money",
            ),
            "Q1-22": numberFormat(
              balanceSheetData.data.Outputs[3].Value,
              "money",
            ),
            "Q4-21": numberFormat(
              balanceSheetData.data.Outputs[4].Value,
              "money",
            ),
          },
          {
            Accounts: "Restricted Cash",
            "Q4-22": numberFormat(
              balanceSheetData.data.Outputs[5].Value,
              "number",
            ),
            "Q3-22": numberFormat(
              balanceSheetData.data.Outputs[6].Value,
              "number",
            ),
            "Q2-22": numberFormat(
              balanceSheetData.data.Outputs[7].Value,
              "number",
            ),
            "Q1-22": numberFormat(
              balanceSheetData.data.Outputs[8].Value,
              "number",
            ),
            "Q4-21": numberFormat(
              balanceSheetData.data.Outputs[9].Value,
              "number",
            ),
          },
          {
            Accounts: "LHFS",
            "Q4-22": numberFormat(
              balanceSheetData.data.Outputs[10].Value,
              "number",
            ),
            "Q3-22": numberFormat(
              balanceSheetData.data.Outputs[11].Value,
              "number",
            ),
            "Q2-22": numberFormat(
              balanceSheetData.data.Outputs[12].Value,
              "number",
            ),
            "Q1-22": numberFormat(
              balanceSheetData.data.Outputs[13].Value,
              "number",
            ),
            "Q4-21": numberFormat(
              balanceSheetData.data.Outputs[14].Value,
              "number",
            ),
          },
          {
            Accounts: "IRLC",
            "Q4-22": numberFormat(
              balanceSheetData.data.Outputs[15].Value,
              "number",
            ),
            "Q3-22": numberFormat(
              balanceSheetData.data.Outputs[16].Value,
              "number",
            ),
            "Q2-22": numberFormat(
              balanceSheetData.data.Outputs[17].Value,
              "number",
            ),
            "Q1-22": numberFormat(
              balanceSheetData.data.Outputs[18].Value,
              "number",
            ),
            "Q4-21": numberFormat(
              balanceSheetData.data.Outputs[19].Value,
              "number",
            ),
          },
          {
            Accounts: "MSR",
            "Q4-22": numberFormat(
              balanceSheetData.data.Outputs[20].Value,
              "number",
            ),
            "Q3-22": numberFormat(
              balanceSheetData.data.Outputs[21].Value,
              "number",
            ),
            "Q2-22": numberFormat(
              balanceSheetData.data.Outputs[22].Value,
              "number",
            ),
            "Q1-22": numberFormat(
              balanceSheetData.data.Outputs[23].Value,
              "number",
            ),
            "Q4-21": numberFormat(
              balanceSheetData.data.Outputs[24].Value,
              "number",
            ),
          },
          {
            Accounts: "GNMA Repurchase",
            "Q4-22": numberFormat(
              balanceSheetData.data.Outputs[25].Value,
              "number",
            ),
            "Q3-22": numberFormat(
              balanceSheetData.data.Outputs[26].Value,
              "number",
            ),
            "Q2-22": numberFormat(
              balanceSheetData.data.Outputs[27].Value,
              "number",
            ),
            "Q1-22": numberFormat(
              balanceSheetData.data.Outputs[28].Value,
              "number",
            ),
            "Q4-21": numberFormat(
              balanceSheetData.data.Outputs[29].Value,
              "number",
            ),
          },
          {
            Accounts: "Other",
            "Q4-22": numberFormat(
              balanceSheetData.data.Outputs[30].Value,
              "number",
            ),
            "Q3-22": numberFormat(
              balanceSheetData.data.Outputs[31].Value,
              "number",
            ),
            "Q2-22": numberFormat(
              balanceSheetData.data.Outputs[32].Value,
              "number",
            ),
            "Q1-22": numberFormat(
              balanceSheetData.data.Outputs[33].Value,
              "number",
            ),
            "Q4-21": numberFormat(
              balanceSheetData.data.Outputs[34].Value,
              "number",
            ),
          },
          {
            Accounts: "Total Assets",
            "Q4-22": numberFormat(
              balanceSheetData.data.Outputs[35].Value,
              "money",
            ),
            "Q3-22": numberFormat(
              balanceSheetData.data.Outputs[36].Value,
              "money",
            ),
            "Q2-22": numberFormat(
              balanceSheetData.data.Outputs[37].Value,
              "money",
            ),
            "Q1-22": numberFormat(
              balanceSheetData.data.Outputs[38].Value,
              "money",
            ),
            "Q4-21": numberFormat(
              balanceSheetData.data.Outputs[39].Value,
              "money",
            ),
          },
          {
            Accounts: "Liabilities: ",
            "Q4-22": " ",
            "Q3-22": " ",
            "Q2-22": " ",
            "Q1-22": " ",
            "Q4-21": " ",
          },
          {
            Accounts: "Funding Facilities",
            "Q4-22": numberFormat(
              balanceSheetData.data.Outputs[40].Value,
              "money",
            ),
            "Q3-22": numberFormat(
              balanceSheetData.data.Outputs[41].Value,
              "money",
            ),
            "Q2-22": numberFormat(
              balanceSheetData.data.Outputs[42].Value,
              "money",
            ),
            "Q1-22": numberFormat(
              balanceSheetData.data.Outputs[43].Value,
              "money",
            ),
            "Q4-21": numberFormat(
              balanceSheetData.data.Outputs[44].Value,
              "money",
            ),
          },
          {
            Accounts: "Other Financing & Debt",
            "Q4-22": numberFormat(
              balanceSheetData.data.Outputs[45].Value,
              "number",
            ),
            "Q3-22": numberFormat(
              balanceSheetData.data.Outputs[46].Value,
              "number",
            ),
            "Q2-22": numberFormat(
              balanceSheetData.data.Outputs[47].Value,
              "number",
            ),
            "Q1-22": numberFormat(
              balanceSheetData.data.Outputs[48].Value,
              "number",
            ),
            "Q4-21": numberFormat(
              balanceSheetData.data.Outputs[49].Value,
              "number",
            ),
          },
          {
            Accounts: "GNMA Repurchase",
            "Q4-22": numberFormat(
              balanceSheetData.data.Outputs[50].Value,
              "number",
            ),
            "Q3-22": numberFormat(
              balanceSheetData.data.Outputs[51].Value,
              "number",
            ),
            "Q2-22": numberFormat(
              balanceSheetData.data.Outputs[52].Value,
              "number",
            ),
            "Q1-22": numberFormat(
              balanceSheetData.data.Outputs[53].Value,
              "number",
            ),
            "Q4-21": numberFormat(
              balanceSheetData.data.Outputs[54].Value,
              "number",
            ),
          },
          {
            Accounts: "Other",
            "Q4-22": numberFormat(
              balanceSheetData.data.Outputs[55].Value,
              "number",
            ),
            "Q3-22": numberFormat(
              balanceSheetData.data.Outputs[56].Value,
              "number",
            ),
            "Q2-22": numberFormat(
              balanceSheetData.data.Outputs[57].Value,
              "number",
            ),
            "Q1-22": numberFormat(
              balanceSheetData.data.Outputs[58].Value,
              "number",
            ),
            "Q4-21": numberFormat(
              balanceSheetData.data.Outputs[59].Value,
              "number",
            ),
          },
          {
            Accounts: "Total Liabilities",
            "Q4-22": numberFormat(
              balanceSheetData.data.Outputs[60].Value,
              "money",
            ),
            "Q3-22": numberFormat(
              balanceSheetData.data.Outputs[61].Value,
              "money",
            ),
            "Q2-22": numberFormat(
              balanceSheetData.data.Outputs[62].Value,
              "money",
            ),
            "Q1-22": numberFormat(
              balanceSheetData.data.Outputs[63].Value,
              "money",
            ),
            "Q4-21": numberFormat(
              balanceSheetData.data.Outputs[64].Value,
              "money",
            ),
          },
          {
            Accounts: "Equity: ",
            "Q4-22": " ",
            "Q3-22": " ",
            "Q2-22": " ",
            "Q1-22": " ",
            "Q4-21": " ",
          },
          {
            Accounts: "Non-controlling Interest",
            "Q4-22": numberFormat(
              balanceSheetData.data.Outputs[65].Value,
              "money",
            ),
            "Q3-22": numberFormat(
              balanceSheetData.data.Outputs[66].Value,
              "money",
            ),
            "Q2-22": numberFormat(
              balanceSheetData.data.Outputs[67].Value,
              "money",
            ),
            "Q1-22": numberFormat(
              balanceSheetData.data.Outputs[68].Value,
              "money",
            ),
            "Q4-21": numberFormat(
              balanceSheetData.data.Outputs[69].Value,
              "money",
            ),
          },
          {
            Accounts: "Retained Earnings",
            "Q4-22": numberFormat(
              balanceSheetData.data.Outputs[70].Value,
              "number",
            ),
            "Q3-22": numberFormat(
              balanceSheetData.data.Outputs[71].Value,
              "number",
            ),
            "Q2-22": numberFormat(
              balanceSheetData.data.Outputs[72].Value,
              "number",
            ),
            "Q1-22": numberFormat(
              balanceSheetData.data.Outputs[73].Value,
              "number",
            ),
            "Q4-21": numberFormat(
              balanceSheetData.data.Outputs[74].Value,
              "number",
            ),
          },
          {
            Accounts: "Additional Paid-in Capital",
            "Q4-22": numberFormat(
              balanceSheetData.data.Outputs[75].Value,
              "number",
            ),
            "Q3-22": numberFormat(
              balanceSheetData.data.Outputs[76].Value,
              "number",
            ),
            "Q2-22": numberFormat(
              balanceSheetData.data.Outputs[77].Value,
              "number",
            ),
            "Q1-22": numberFormat(
              balanceSheetData.data.Outputs[78].Value,
              "number",
            ),
            "Q4-21": numberFormat(
              balanceSheetData.data.Outputs[79].Value,
              "number",
            ),
          },
          {
            Accounts: "Other",
            "Q4-22": numberFormat(
              balanceSheetData.data.Outputs[80].Value,
              "number",
            ),
            "Q3-22": numberFormat(
              balanceSheetData.data.Outputs[81].Value,
              "number",
            ),
            "Q2-22": numberFormat(
              balanceSheetData.data.Outputs[82].Value,
              "number",
            ),
            "Q1-22": numberFormat(
              balanceSheetData.data.Outputs[83].Value,
              "number",
            ),
            "Q4-21": numberFormat(
              balanceSheetData.data.Outputs[84].Value,
              "number",
            ),
          },
          {
            Accounts: "Total Equity",
            "Q4-22": numberFormat(
              balanceSheetData.data.Outputs[85].Value,
              "money",
            ),
            "Q3-22": numberFormat(
              balanceSheetData.data.Outputs[86].Value,
              "money",
            ),
            "Q2-22": numberFormat(
              balanceSheetData.data.Outputs[87].Value,
              "money",
            ),
            "Q1-22": numberFormat(
              balanceSheetData.data.Outputs[88].Value,
              "money",
            ),
            "Q4-21": numberFormat(
              balanceSheetData.data.Outputs[89].Value,
              "money",
            ),
          },
          {
            Accounts: "Total liabilities and equity",
            "Q4-22": numberFormat(
              balanceSheetData.data.Outputs[90].Value,
              "money",
            ),
            "Q3-22": numberFormat(
              balanceSheetData.data.Outputs[91].Value,
              "money",
            ),
            "Q2-22": numberFormat(
              balanceSheetData.data.Outputs[92].Value,
              "money",
            ),
            "Q1-22": numberFormat(
              balanceSheetData.data.Outputs[93].Value,
              "money",
            ),
            "Q4-21": numberFormat(
              balanceSheetData.data.Outputs[94].Value,
              "money",
            ),
          },
        ],
        dataCapStructure: [
          {
            Accounts: "Nominal Figures: ",
            "Q4-22": " ",
            "Q3-22": " ",
            "Q2-22": " ",
            "Q1-22": " ",
            "Q4-21": " ",
          },
          {
            Accounts: "Gross Debt",
            "Q4-22": numberFormat(
              balanceSheetData.data.Outputs[95].Value,
              "money",
            ),
            "Q3-22": numberFormat(
              balanceSheetData.data.Outputs[96].Value,
              "money",
            ),
            "Q2-22": numberFormat(
              balanceSheetData.data.Outputs[97].Value,
              "money",
            ),
            "Q1-22": numberFormat(
              balanceSheetData.data.Outputs[98].Value,
              "money",
            ),
            "Q4-21": numberFormat(
              balanceSheetData.data.Outputs[99].Value,
              "money",
            ),
          },
          {
            Accounts: "Net Debt",
            "Q4-22": numberFormat(
              balanceSheetData.data.Outputs[100].Value,
              "number",
            ),
            "Q3-22": numberFormat(
              balanceSheetData.data.Outputs[101].Value,
              "number",
            ),
            "Q2-22": numberFormat(
              balanceSheetData.data.Outputs[102].Value,
              "number",
            ),
            "Q1-22": numberFormat(
              balanceSheetData.data.Outputs[103].Value,
              "number",
            ),
            "Q4-21": numberFormat(
              balanceSheetData.data.Outputs[104].Value,
              "number",
            ),
          },
          {
            Accounts: "Tangible Assets",
            "Q4-22": numberFormat(
              balanceSheetData.data.Outputs[105].Value,
              "number",
            ),
            "Q3-22": numberFormat(
              balanceSheetData.data.Outputs[106].Value,
              "number",
            ),
            "Q2-22": numberFormat(
              balanceSheetData.data.Outputs[107].Value,
              "number",
            ),
            "Q1-22": numberFormat(
              balanceSheetData.data.Outputs[108].Value,
              "number",
            ),
            "Q4-21": numberFormat(
              balanceSheetData.data.Outputs[109].Value,
              "number",
            ),
          },
          {
            Accounts: "Tangible Common Equity",
            "Q4-22": numberFormat(
              balanceSheetData.data.Outputs[110].Value,
              "number",
            ),
            "Q3-22": numberFormat(
              balanceSheetData.data.Outputs[111].Value,
              "number",
            ),
            "Q2-22": numberFormat(
              balanceSheetData.data.Outputs[112].Value,
              "number",
            ),
            "Q1-22": numberFormat(
              balanceSheetData.data.Outputs[113].Value,
              "number",
            ),
            "Q4-21": numberFormat(
              balanceSheetData.data.Outputs[114].Value,
              "number",
            ),
          },
          {
            Accounts: "Leverage Ratios: ",
            "Q4-22": " ",
            "Q3-22": " ",
            "Q2-22": " ",
            "Q1-22": " ",
            "Q4-21": " ",
          },
          {
            Accounts: "TCE/TA",
            "Q4-22": numberFormat(
              balanceSheetData.data.Outputs[115].Value,
              "percentage",
            ),
            "Q3-22": numberFormat(
              balanceSheetData.data.Outputs[116].Value,
              "percentage",
            ),
            "Q2-22": numberFormat(
              balanceSheetData.data.Outputs[117].Value,
              "percentage",
            ),
            "Q1-22": numberFormat(
              balanceSheetData.data.Outputs[118].Value,
              "percentage",
            ),
            "Q4-21": numberFormat(
              balanceSheetData.data.Outputs[119].Value,
              "percentage",
            ),
          },
          {
            Accounts: "Debt/TCE",
            "Q4-22": numberFormat(
              balanceSheetData.data.Outputs[120].Value,
              "percentage",
            ),
            "Q3-22": numberFormat(
              balanceSheetData.data.Outputs[121].Value,
              "percentage",
            ),
            "Q2-22": numberFormat(
              balanceSheetData.data.Outputs[122].Value,
              "percentage",
            ),
            "Q1-22": numberFormat(
              balanceSheetData.data.Outputs[123].Value,
              "percentage",
            ),
            "Q4-21": numberFormat(
              balanceSheetData.data.Outputs[124].Value,
              "percentage",
            ),
          },
          {
            Accounts: "MSR Debt/MSR",
            "Q4-22": numberFormat(
              balanceSheetData.data.Outputs[125].Value,
              "percentage",
            ),
            "Q3-22": numberFormat(
              balanceSheetData.data.Outputs[126].Value,
              "percentage",
            ),
            "Q2-22": numberFormat(
              balanceSheetData.data.Outputs[127].Value,
              "percentage",
            ),
            "Q1-22": numberFormat(
              balanceSheetData.data.Outputs[128].Value,
              "percentage",
            ),
            "Q4-21": numberFormat(
              balanceSheetData.data.Outputs[129].Value,
              "percentage",
            ),
          },
          {
            Accounts: "Warehouse Debt/LHFS",
            "Q4-22": numberFormat(
              balanceSheetData.data.Outputs[130].Value,
              "percentage",
            ),
            "Q3-22": numberFormat(
              balanceSheetData.data.Outputs[131].Value,
              "percentage",
            ),
            "Q2-22": numberFormat(
              balanceSheetData.data.Outputs[132].Value,
              "percentage",
            ),
            "Q1-22": numberFormat(
              balanceSheetData.data.Outputs[133].Value,
              "percentage",
            ),
            "Q4-21": numberFormat(
              balanceSheetData.data.Outputs[134].Value,
              "percentage",
            ),
          },
          {
            Accounts: "Return Metrics: ",
            "Q4-22": " ",
            "Q3-22": " ",
            "Q2-22": " ",
            "Q1-22": " ",
            "Q4-21": " ",
          },
          {
            Accounts: "Profit Margin",
            "Q4-22": numberFormat(
              balanceSheetData.data.Outputs[135].Value,
              "percentage",
            ),
            "Q3-22": numberFormat(
              balanceSheetData.data.Outputs[136].Value,
              "percentage",
            ),
            "Q2-22": numberFormat(
              balanceSheetData.data.Outputs[137].Value,
              "percentage",
            ),
            "Q1-22": numberFormat(
              balanceSheetData.data.Outputs[138].Value,
              "percentage",
            ),
            "Q4-21": numberFormat(
              balanceSheetData.data.Outputs[139].Value,
              "percentage",
            ),
          },
          {
            Accounts: "Assets Turnover",
            "Q4-22": numberFormat(
              balanceSheetData.data.Outputs[140].Value,
              "percentage",
            ),
            "Q3-22": numberFormat(
              balanceSheetData.data.Outputs[141].Value,
              "percentage",
            ),
            "Q2-22": numberFormat(
              balanceSheetData.data.Outputs[142].Value,
              "percentage",
            ),
            "Q1-22": numberFormat(
              balanceSheetData.data.Outputs[143].Value,
              "percentage",
            ),
            "Q4-21": numberFormat(
              balanceSheetData.data.Outputs[144].Value,
              "percentage",
            ),
          },
          {
            Accounts: "Financial Leverage",
            "Q4-22": numberFormat(
              balanceSheetData.data.Outputs[145].Value,
              "ratio",
            ),
            "Q3-22": numberFormat(
              balanceSheetData.data.Outputs[146].Value,
              "ratio",
            ),
            "Q2-22": numberFormat(
              balanceSheetData.data.Outputs[147].Value,
              "ratio",
            ),
            "Q1-22": numberFormat(
              balanceSheetData.data.Outputs[148].Value,
              "ratio",
            ),
            "Q4-21": numberFormat(
              balanceSheetData.data.Outputs[149].Value,
              "ratio",
            ),
          },
          {
            Accounts: "Annualized ROE",
            "Q4-22": numberFormat(
              balanceSheetData.data.Outputs[150].Value,
              "percentage",
            ),
            "Q3-22": numberFormat(
              balanceSheetData.data.Outputs[151].Value,
              "percentage",
            ),
            "Q2-22": numberFormat(
              balanceSheetData.data.Outputs[152].Value,
              "percentage",
            ),
            "Q1-22": numberFormat(
              balanceSheetData.data.Outputs[153].Value,
              "percentage",
            ),
            "Q4-21": numberFormat(
              balanceSheetData.data.Outputs[154].Value,
              "percentage",
            ),
          },
          {
            Accounts: "Per Share Metrics: ",
            "Q4-22": " ",
            "Q3-22": " ",
            "Q2-22": " ",
            "Q1-22": " ",
            "Q4-21": " ",
          },
          {
            Accounts: "GAAP EPS",
            "Q4-22": numberFormat(
              balanceSheetData.data.Outputs[155].Value,
              "tinymoney",
            ),
            "Q3-22": numberFormat(
              balanceSheetData.data.Outputs[156].Value,
              "tinymoney",
            ),
            "Q2-22": numberFormat(
              balanceSheetData.data.Outputs[157].Value,
              "tinymoney",
            ),
            "Q1-22": numberFormat(
              balanceSheetData.data.Outputs[158].Value,
              "tinymoney",
            ),
            "Q4-21": numberFormat(
              balanceSheetData.data.Outputs[159].Value,
              "tinymoney",
            ),
          },
          {
            Accounts: "Adjusted EPS",
            "Q4-22": numberFormat(
              balanceSheetData.data.Outputs[160].Value,
              "tinynumber",
            ),
            "Q3-22": numberFormat(
              balanceSheetData.data.Outputs[161].Value,
              "tinynumber",
            ),
            "Q2-22": numberFormat(
              balanceSheetData.data.Outputs[162].Value,
              "tinynumber",
            ),
            "Q1-22": numberFormat(
              balanceSheetData.data.Outputs[163].Value,
              "tinynumber",
            ),
            "Q4-21": numberFormat(
              balanceSheetData.data.Outputs[164].Value,
              "tinynumber",
            ),
          },
        ],
        loadedData: true,
        errorFlag: false,
      });
    } else if (balanceSheetData.isError) {
      setState((prevState) => ({
        ...prevState,
        errorFlag: true,
        errorCode: "500",
        errorMessage: tryAgainLaterMessage,
        errorType: "InternalAPI",
      }));
    }
  }, [balanceSheetData]);

  const onGridReady = (params: ColumnResizedEvent) => {
    params.api.sizeColumnsToFit();
  };

  return (
    <div className="sheet-master">
      {!state.loadedData && (
        <div className="module-loading">
          <FontAwesomeIcon
            icon={faSpinner}
            size="2x"
            spin={true}
            style={{ margin: "0 auto" }}
          />
        </div>
      )}
      {state.errorFlag && (
        <Error
          code={state.errorCode}
          type={state.errorType}
          message={state.errorMessage}
        />
      )}
      {state.loadedData && !state.errorFlag && (
        <div className="sheet-tables-div">
          <div className="sheet-table-container">
            <div className="sheet-table-title">Balance Sheet</div>
            <div className="sheet-table-subtitle">(in Millions)</div>
            <div className="sheet-table">
              <AgGridReact
                domLayout={"autoHeight"}
                defaultColDef={{
                  cellClass: "text",
                  headerClass: "column",
                }}
                onGridReady={onGridReady}
                rowClassRules={{
                  highlight: function (params: any) {
                    let differentColors: string[] = [
                      "Total Assets",
                      "Total liabilities and equity",
                    ];
                    return differentColors.includes(params.data.Accounts);
                  },
                  separator: function (params: any) {
                    let differentColors: string[] = [
                      "Assets: ",
                      "Liabilities: ",
                      "Equity: ",
                    ];
                    return differentColors.includes(params.data.Accounts);
                  },
                  totals: function (params: any) {
                    let differentColors: string[] = [
                      "Total Liabilities",
                      "Total Equity",
                    ];
                    return differentColors.includes(params.data.Accounts);
                  },
                }}
                rowData={state.dataBalanceSheet}
                columnDefs={state.columns}
              ></AgGridReact>
            </div>
          </div>
          <div className="sheet-table-container">
            <div className="sheet-table-title">
              Capital Structure & Leverage
            </div>
            <div className="sheet-table-subtitle">(in Millions)</div>
            <div className="sheet-table">
              <AgGridReact
                domLayout={"autoHeight"}
                defaultColDef={{
                  cellClass: "text",
                  headerClass: "column",
                  resizable: true,
                }}
                onGridReady={onGridReady}
                rowClassRules={{
                  separator: function (params: any) {
                    let differentColors: string[] = [
                      "Nominal Figures: ",
                      "Leverage Ratios: ",
                      "Return Metrics: ",
                      "Per Share Metrics: ",
                    ];
                    return differentColors.includes(params.data.Accounts);
                  },
                  "simple-highlight": function (params: any) {
                    let differentColors: string[] = [
                      "Tangible Common Equity",
                      "TCE/TA",
                      "Annualized ROE",
                    ];
                    return differentColors.includes(params.data.Accounts);
                  },
                }}
                rowData={state.dataCapStructure}
                columnDefs={state.columns}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
